import { t } from '@transifex/native';
import { routes } from './index';

const basicCampaignMenu = Object.freeze({
  menuItems: [
    {
      name: 'Campaign',
      path: routes.BASIC_AD.CREATE_CAMPAIGN,
      editPath: routes.BASIC_AD.EDIT_CAMPAIGN,
      options: [
        {
          iconName: 'create-ad',
          title: t('Create new ad set'),
          action: 'create',
        },
      ],
      submenuItems: [
        {
          name: t('Name'),
          fragment: 'campaign-name',
          path: routes.BASIC_AD.CREATE_CAMPAIGN,
          editPath: routes.BASIC_AD.EDIT_CAMPAIGN,
        },
        {
          name: t('Objective'),
          fragment: 'objective',
          path: routes.BASIC_AD.CREATE_CAMPAIGN,
          editPath: routes.BASIC_AD.EDIT_CAMPAIGN,
        },
        {
          name: t('Charity'),
          fragment: 'charity',
          path: routes.BASIC_AD.CREATE_CAMPAIGN,
          editPath: routes.BASIC_AD.EDIT_CAMPAIGN,
        },
      ],
    },
    {
      name: 'Ad Set',
      path: routes.BASIC_AD.SPONSORSHIP_SET_CREATE,
      editPath: routes.BASIC_AD.SPONSORSHIP_SET_EDIT,
      options: [
        {
          iconName: 'create-ad',
          title: t('Create new ad'),
          action: 'create',
        },
        {
          iconName: 'duplicate-ad',
          title: t('Duplicate ad set'),
          action: 'duplicate',
        },
        {
          iconName: 'delete-ad',
          title: t('Delete ad set'),
          action: 'delete',
        },
      ],
      submenuItems: [
        {
          name: t('Name'),
          fragment: 'adset-name',
          path: routes.BASIC_AD.SPONSORSHIP_SET_CREATE,
          editPath: routes.BASIC_AD.SPONSORSHIP_SET_EDIT,
        },
        {
          name: t('Audience'),
          fragment: 'audience',
          path: routes.BASIC_AD.SPONSORSHIP_SET_CREATE,
          editPath: routes.BASIC_AD.SPONSORSHIP_SET_EDIT,
        },
        {
          name: '8Pixel',
          fragment: '8pixel',

          path: routes.BASIC_AD.SPONSORSHIP_SET_CREATE,
          editPath: routes.BASIC_AD.SPONSORSHIP_SET_EDIT,
        },
        {
          name: t('Timing'),
          fragment: 'timing',
          path: routes.BASIC_AD.SPONSORSHIP_SET_CREATE,
          editPath: routes.BASIC_AD.SPONSORSHIP_SET_EDIT,
        },
        {
          name: t('Budget'),
          fragment: 'budget',
          path: routes.BASIC_AD.SPONSORSHIP_SET_CREATE,
          editPath: routes.BASIC_AD.SPONSORSHIP_SET_EDIT,
        },
      ],
    },
    {
      name: 'Ad',
      path: routes.BASIC_AD.SPONSORSHIP_CREATE,
      editPath: routes.BASIC_AD.SPONSORSHIP_EDIT,
      options: [
        {
          iconName: 'duplicate-ad',
          title: t('Duplicate ad'),
          action: 'duplicate',
        },
        {
          iconName: 'delete-ad',
          title: t('Delete ad'),
          action: 'delete',
        },
      ],
      submenuItems: [
        {
          name: 'Ad Name',
          fragment: 'ad-name',
          path: routes.BASIC_AD.SPONSORSHIP_CREATE,
          editPath: routes.BASIC_AD.SPONSORSHIP_EDIT,
        },
        {
          name: t('Creative'),
          fragment: 'creative',
          path: routes.BASIC_AD.SPONSORSHIP_CREATE,
          editPath: routes.BASIC_AD.SPONSORSHIP_EDIT,
        },
        {
          name: t('Call to action'),
          fragment: 'action',
          path: routes.BASIC_AD.SPONSORSHIP_CREATE,
          editPath: routes.BASIC_AD.SPONSORSHIP_EDIT,
        },
      ],
    },
    {
      name: t('Summary'),
      path: routes.BASIC_AD.CAMPAIGN_SUMMARY,
      editPath: routes.BASIC_AD.CAMPAIGN_SUMMARY,
      submenuItems: [],
    },
  ],
});

export default basicCampaignMenu;
