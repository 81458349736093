import { createReducer, initialAsyncState } from 'eight.js.store-common';
import {
  fetchBasicCampaignNamesAsync,
  fetchBasicSponsorshipSetNamesAsync,
  fetchCampaignNamesAsync,
  fetchSponsorshipSetNamesAsync,
} from '../brand';
import {
  activateSponsorshipSetAsync,
  activateSponsorshipAsync,
  activateCampaignAsync,
  clearReceivedNotifications,
  deactivateCampaignAsync,
  deactivateSponsorshipSetAsync,
  deactivateSponsorshipAsync,
  dismissNotificationAsync,
  fetchCampaignsAsync,
  fetchSponsorshipSetsAsync,
  fetchSponsorshipsAsync,
  fetchNotificationsAsync,
  removeNotificationFromArray,
  setCampaignIsActive,
  setSponsorshipSetIsActive,
  setSponsorshipIsActive,
  setManageIsFetching,
  setDataArray,
  setCampaignDeliveryStatus,
  setSponsorshipSetDeliveryStatus,
  setSponsorshipDeliveryStatus,
  setCampaignIsArchive,
  setIsArchive,
  setCampaignIsUnarchive,
  setIsSearch,
  setSponsorshipSetsAreActive,
  setSponsorshipsAreActive,
  setSponsorshipsDeliveryStatus,
  setSponsorshipSetsDeliveryStatus,
  setCampaignsAreFetching,
  setAdSetsAreFetching,
  setAdsAreFetching,
} from './actions';

const initialState = {
  ...initialAsyncState,
  receivedCampaigns: {
    totalCampaigns: 0,
    pages: 0,
    data: [],
  },
  receivedSponsorshipSets: {
    totalSponsorshipSets: 0,
    pages: 0,
    data: [],
  },
  receivedSponsorships: {
    totalSponsorships: 0,
    pages: 0,
    data: [],
  },
  receivedNotifications: [],
  selectedNotification: {},
  campaignNames: [],
  adSetNames: [],
  adNames: [],
  itemsPerPage: { name: '20 results per page', label: '20 results per page', value: 20 },
  internalFetching: false,
  isArchive: false,
  isSearch: false,
  error: '',
  campaignsAreFetching: false,
  adSetsAreFetching: false,
  adsAreFetching: false,
};

export const manageBasicAdsReducer = createReducer('@@manageBasicAds', initialState, {
  [activateCampaignAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [fetchBasicCampaignNamesAsync.success]: ({ state, action }) => {
    const clear = action.payload.clear;
    const response = action.payload.response;
    const formattedNames = response.data.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
    state.campaignNames = clear ? formattedNames : [...state.campaignNames, ...formattedNames];
  },
  [fetchBasicSponsorshipSetNamesAsync.success]: ({ state, action }) => {
    const clear = action.payload.clear;
    const response = action.payload.response;
    const formattedNames = response.data.map((item) => {
      return {
        label: item.name,
        value: item.id,
        campaignId: item.campaignId,
      };
    });
    state.adSetNames = clear ? formattedNames : [...state.adSetNames, ...formattedNames];
  },

  [activateSponsorshipSetAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [activateSponsorshipAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [clearReceivedNotifications]: ({ state }) => {
    state.receivedNotifications = [];
  },

  [deactivateSponsorshipAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [deactivateCampaignAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [dismissNotificationAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [deactivateSponsorshipSetAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [fetchCampaignsAsync.success]: ({ state, action }) => {
    state.receivedCampaigns = action.payload;
  },

  [fetchCampaignsAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [fetchSponsorshipSetsAsync.success]: ({ state, action }) => {
    state.receivedSponsorshipSets = action.payload;
  },

  [fetchNotificationsAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [fetchNotificationsAsync.success]: ({ state, action }) => {
    state.receivedNotifications = action.payload;
  },

  [fetchSponsorshipSetsAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [fetchSponsorshipsAsync.success]: ({ state, action }) => {
    state.receivedSponsorships = action.payload;
  },

  [fetchSponsorshipsAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [removeNotificationFromArray]: ({ state, action }) => {
    const { notificationId } = action.payload;
    state.receivedNotifications = state.receivedNotifications.filter(
      (notification) => notification.id !== notificationId
    );
  },

  [setCampaignIsActive]: ({ state, action }) => {
    const { id, isActive } = action.payload;
    const index = state.receivedCampaigns.data.findIndex((campaign) => campaign.id === id);

    state.receivedCampaigns.data[index] = {
      ...state.receivedCampaigns.data[index],
      isActive,
    };
  },

  [setCampaignIsArchive]: ({ state, action }) => {
    const { id } = action.payload;

    state.receivedCampaigns.data = state.receivedCampaigns.data.filter(
      (campaign) => campaign.id !== id
    );
  },

  [setCampaignIsUnarchive]: ({ state, action }) => {
    const { id } = action.payload;

    state.receivedCampaigns.data = state.receivedCampaigns.data.filter(
      (campaign) => campaign.id !== id
    );
  },

  [setCampaignDeliveryStatus]: ({ state, action }) => {
    const { id, deliveryStatus } = action.payload;
    const index = state.receivedCampaigns.data.findIndex((campaign) => campaign.id === id);

    state.receivedCampaigns.data[index] = {
      ...state.receivedCampaigns.data[index],
      deliveryStatus,
    };
  },

  [setDataArray]: ({ state, action }) => {
    const { name, value } = action.payload;
    state[name] = value;
  },

  [setIsArchive]: ({ state, action }) => {
    state.isArchive = action.payload;
  },

  [setIsSearch]: ({ state, action }) => {
    state.isSearch = action.payload;
  },

  [setSponsorshipSetIsActive]: ({ state, action }) => {
    const { sponsorshipSetId, isActive } = action.payload;
    const index = state.receivedSponsorshipSets.data.findIndex(
      (adSet) => adSet.id === sponsorshipSetId
    );

    state.receivedSponsorshipSets.data[index] = {
      ...state.receivedSponsorshipSets.data[index],
      isActive,
    };
  },

  [setSponsorshipSetsAreActive]: ({ state, action }) => {
    const { sponsorshipSetIds, isActive } = action.payload;

    const sponsorshipSetIndexes = sponsorshipSetIds.map((id) => {
      return state.receivedSponsorshipSets.data.findIndex((adSet) => adSet.id === id);
    });

    sponsorshipSetIndexes.forEach((index) => {
      if (index >= 0) state.receivedSponsorshipSets.data[index].isActive = isActive;
    });
  },

  [setSponsorshipSetDeliveryStatus]: ({ state, action }) => {
    const { sponsorshipSetId, deliveryStatus } = action.payload;
    const index = state.receivedSponsorshipSets.data.findIndex(
      (adSet) => adSet.sponsorshipSetId === sponsorshipSetId
    );

    state.receivedSponsorshipSets.data[index] = {
      ...state.receivedSponsorshipSets.data[index],
      deliveryStatus,
    };
  },

  [setSponsorshipSetsDeliveryStatus]: ({ state, action }) => {
    const { sponsorshipSetIds, deliveryStatus } = action.payload;

    const sponsorshipSetIndexes = sponsorshipSetIds.map((id) => {
      return state.receivedSponsorshipSets.data.findIndex((adSet) => adSet.sponsorshipSetId === id);
    });

    sponsorshipSetIndexes.forEach((index) => {
      if (index >= 0) {
        if (
          deliveryStatus === 'inactive' &&
          state.receivedSponsorshipSets.data[index].deliveryStatus === 'active'
        )
          state.receivedSponsorshipSets.data[index].deliveryStatus = deliveryStatus;
      }
    });
  },

  [setSponsorshipDeliveryStatus]: ({ state, action }) => {
    const { sponsorshipId, deliveryStatus } = action.payload;
    const index = state.receivedSponsorships.data.findIndex(
      (ad) => ad.sponsorshipId === sponsorshipId
    );

    state.receivedSponsorships.data[index] = {
      ...state.receivedSponsorships.data[index],
      deliveryStatus,
    };
  },

  [setSponsorshipsDeliveryStatus]: ({ state, action }) => {
    const { sponsorshipIds, deliveryStatus } = action.payload;

    const sponsorshipIndexes = sponsorshipIds.map((id) => {
      return state.receivedSponsorships.data.findIndex((ad) => ad.sponsorshipId === id);
    });

    sponsorshipIndexes.forEach((index) => {
      if (index >= 0) {
        if (
          deliveryStatus === 'inactive' &&
          state.receivedSponsorships.data[index].deliveryStatus === 'active'
        )
          state.receivedSponsorships.data[index].deliveryStatus = deliveryStatus;
      }
    });
  },

  [setSponsorshipIsActive]: ({ state, action }) => {
    const { sponsorshipId, isActive } = action.payload;
    const index = state.receivedSponsorships.data.findIndex((ad) => ad.id === sponsorshipId);

    state.receivedSponsorships.data[index] = {
      ...state.receivedSponsorships.data[index],
      isActive,
    };
  },

  [setSponsorshipsAreActive]: ({ state, action }) => {
    const { sponsorshipIds, isActive } = action.payload;

    const sponsorshipIndexes = sponsorshipIds.map((id) => {
      return state.receivedSponsorships.data.findIndex((adSet) => adSet.id === id);
    });

    sponsorshipIndexes.forEach((index) => {
      if (index >= 0) state.receivedSponsorships.data[index].isActive = isActive;
    });
  },

  [setManageIsFetching]: ({ state, action }) => {
    state.internalFetching = action.payload;
  },

  [setCampaignsAreFetching]: ({ state, action }) => {
    state.campaignsAreFetching = action.payload;
  },

  [setAdSetsAreFetching]: ({ state, action }) => {
    state.adSetsAreFetching = action.payload;
  },

  [setAdsAreFetching]: ({ state, action }) => {
    state.adsAreFetching = action.payload;
  },
});
