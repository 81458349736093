import { createAction, createAsyncAction } from 'eight.js.store-common';

// ASYNC

export const activateBasicAdSponsorshipFromCreationAsync = createAsyncAction(
  '@@basicAdSponsorship/ACTIVATE_SPONSORSHIP_FROM_CREATION'
);

export const deactivateBasicAdSponsorshipFromCreationAsync = createAsyncAction(
  '@@basicAdSponsorship/DEACTIVATE_SPONSORSHIP_FROM_CREATION'
);
export const checkBasicAdUploadStatusAsync = createAsyncAction(
  '@@basicAdSponsorship/CHECK_UPLOAD_STATUS'
);

export const fetchBasicAdUploadStatusAsync = createAsyncAction(
  '@@basicAdSponsorship/FETCH_UPLOAD_STATUS'
);

export const createBasicAdSponsorshipAsync = createAsyncAction(
  '@@basicAdSponsorship/CREATE_SPONSORSHIP'
);

export const updateBasicAdSponsorshipAsync = createAsyncAction(
  '@@basicAdSponsorship/UPDATE_SPONSORSHIP'
);

export const uploadBasicAdVideoAsync = createAsyncAction('@@basicAdSponsorship/UPLOAD_VIDEO');

export const uploadBasicAdCoverAsync = createAsyncAction('@@basicAdSponsorship/UPLOAD_COVER');

// SYNC

export const clearBasicAdSponsorship = createAction('@@basicAdSponsorship/CLEAR_SPONSORSHIP');

export const resetBasicAdProgress = createAction('@@basicAdSponsorship/RESET_PROGRESS');

export const toggleBasicAdActivateSponsorship = createAction(
  '@@basicAdSponsorship/TOGGLE_SPONSORSHIP_ACTIVE'
);

export const uploadBasicAdCoverStart = createAction('@@basicAdSponsorship/UPLOAD_COVER_START');

export const uploadBasicAdVideoStart = createAction('@@basicAdSponsorship/UPLOAD_VIDEO_START');

export const uploadBasicAdMedia = createAction('@@basicAdSponsorship/UPDATE_MEDIA');

export const watchBasicAdUploadStop = createAction('@@basicAdSponsorship/WATCH_UPLOAD_STOP');

export const setBasicAdUploadPercentage = createAction(
  '@@basicAdSponsorship/UPDATE_UPLOAD_PERCENTAGE'
);

export const setBasicAdActiveAd = createAction('@@basicAdSponsorship/SET_ACTIVE_AD');

export const setBasicAdSponsorshipName = createAction('@@basicAdSponsorship/SET_SPONSORSHIP_NAME');

export const setBasicAdSponsorshipData = createAction('@@basicAdSponsorship/SET_SPONSORSHIP_DATA');

export const setBasicAdAdData = createAction('@@basicAdSponsorship/SET_AD_DATA');

export const setBasicAdDataArray = createAction('@@basicAdSponsorship/SET_AD_DATA_ARRAY');

export const setBasicAdLocalQuestionsTags = createAction(
  '@@basicAdSponsorship/SET_LOCAL_QUESTIONS_TAGS'
);

export const setBasicAdUploadingVideo = createAction('@@basicAdSponsorship/SET_UPLOADING_VIDEO');

export const setBasicAdInternalFetching = createAction(
  '@@basicAdSponsorship/SET_INTERNAL_FETCHING'
);

export const setBasicAdUpdatingSponsorship = createAction(
  '@@basicAdSponsorship/SET_UPDATING_SPONSORSHIP'
);

export const setBasicAdSponsorshipIsReadOnly = createAction('@@basicAdSponsorship/SET_READ_ONLY');
