import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useRouter } from '../../../../../hooks/useRouter';

import { yupResolver } from '@hookform/resolvers/yup';

import { Layout } from '../../../../../components/Layout';
import { CampaignAndBrand } from '../../../../../components/PagesContent/CreateContent/CampaignAndBrand';
import { ScrollToTopWrapper } from '../../../../../components/wrappers/ScrollToTopWrapper';

import { basicAdConstants, fragments, routes } from '../../../../../constants';

import {
  activateBasicAdFromCreationAsync,
  basicAdCreateSelector,
  basicAdFetchingSelector,
  basicAdIdSelector,
  createBasicAdAsync,
  deactivateBasicAdFromCreationAsync,
  toggleActivateBasicAd,
  updateBasicAdAsync,
} from '../../../../../store/basicAd/campaign';

import {
  modalsSelector,
  savingToastSelector,
  setStickyMenuItem,
  toggleModalByName,
} from '../../../../../store/ui';

import { DevTool } from '@hookform/devtools';
import { useT } from '@transifex/react';
import { useForm } from 'react-hook-form';
import { generatePath } from 'react-router';
import { useUnmount } from 'react-use';
import { Modal } from '../../../../../components/Modal';
import { NewCharity } from '../../../../../components/ModalsContent/NewCharity';
import useBasicAdActiveForm from '../../../../../hooks/useBasicAdActiveForm/useBasicAdActiveForm';
import { useDirtyFields } from '../../../../../hooks/useDirtyFields';
import { useInfiniteScroll } from '../../../../../hooks/useInfiniteScroll';
import {
  basicAdAdSetsSelector,
  basicAdCampaignDataFetchingSelector,
  setBasicAdCampaignIsDirty,
} from '../../../../../store/basicAdData';
import {
  brandInfoSelector,
  fetchUniqueBasicCampaignNamesAsync,
  previousUniqueBasicCampaignNamesSelector,
} from '../../../../../store/brand';
import { getDefaultCharityByCountryCode } from '../../../../../utils/defaultCampaignData';
import preventSubmitOnEnter from '../../../../../utils/preventSubmitOnEnter';
import { CampaignPlaceholder } from '../../../../Placeholders/CampaignPlaceholder';
import { validationSchema } from './validationSchema';

const CampaignForm = () => {
  const dispatch = useDispatch();
  const { push } = useRouter();
  const t = useT();
  const { query } = useRouter();

  const { brandName, logoUri, countryCode } = useSelector(brandInfoSelector);
  const basicAdDraft = useSelector(basicAdCreateSelector);
  const basicAdId = useSelector(basicAdIdSelector);
  const fetching = useSelector(basicAdCampaignDataFetchingSelector);
  const modals = useSelector(modalsSelector);
  const previousCampaignNames = useSelector(previousUniqueBasicCampaignNamesSelector);
  const basicAdFetching = useSelector(basicAdFetchingSelector);
  const allAdSets = useSelector(basicAdAdSetsSelector);
  const saving = useSelector(savingToastSelector).isOpen;
  const basicAdIsLocked = !basicAdDraft.isDraft;
  const [campaignId] = useState(query.cid !== 'new' ? query.cid : null);

  useBasicAdActiveForm({ type: 'campaign', allAdSets });

  useEffect(() => () => dispatch(setStickyMenuItem(null)), [dispatch]);

  const [processingBrandLogo, setProccessingBrandLogo] = useState('');
  const [currentBrandLogoUri, setCurrentBrandLogoUri] = useState('');
  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    watch,
    trigger,
    formState: { errors, isDirty, dirtyFields },
  } = useForm({
    defaultValues: {
      name: basicAdDraft.name,
      campaignBrandName: basicAdDraft?.campaignBrandName || brandName,
      campaignBrandLogoUri: basicAdDraft?.campaignBrandLogoUri || logoUri,
      category: basicAdDraft?.category || basicAdConstants.objectives[0].value,
      campaignBrandLogoFile: basicAdDraft?.campaignBrandLogoFile,
      charityCategory: basicAdDraft?.charityCategory ?? undefined,
      selectedCharity: basicAdDraft?.selectedCharity ?? getDefaultCharityByCountryCode(countryCode),
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });

  const { shouldSave, dirtyFieldsList } = useDirtyFields({
    storedDirtyFields: basicAdDraft?.dirtyFields,
    fieldNames: basicAdConstants.fieldNames,
    isDirty,
    formDirtyFields: dirtyFields,
  });

  useUnmount(() => {
    shouldSave &&
      dispatch(
        setBasicAdCampaignIsDirty({
          isDirty: true,
          form: getValues(),
          dirtyFields: { ...dirtyFields, ...basicAdDraft.dirtyFields },
        })
      );
  });

  useEffect(() => {
    reset(
      {
        name: basicAdDraft.name,
        campaignBrandName: basicAdDraft?.campaignBrandName || brandName,
        campaignBrandLogoUri: basicAdDraft?.campaignBrandLogoUri || logoUri,
        category: basicAdDraft?.category || basicAdConstants.objectives[0].value,
        campaignBrandLogoFile: basicAdDraft?.campaignBrandLogoFile,
        charityCategory: basicAdDraft?.charityCategory ?? undefined,
        selectedCharity:
          basicAdDraft?.selectedCharity ?? getDefaultCharityByCountryCode(countryCode),
      },
      { keepDirtyValues: true, keepDirty: true }
    );

    if (!dirtyFields?.campaignBrandLogoUri)
      setCurrentBrandLogoUri(basicAdDraft?.campaignBrandLogoUri || logoUri);
  }, [reset, basicAdDraft, dirtyFields.campaignBrandLogoUri, brandName, logoUri, countryCode]);

  const handleSubmitForm = useCallback(
    (form) => {
      if (campaignId) {
        const payload = { ...form, campaignId };
        dispatch(updateBasicAdAsync.request(payload));
      } else if (shouldSave || !basicAdId) {
        dispatch(createBasicAdAsync.request(form));
      } else {
        push(
          generatePath(routes.BASIC_AD.SPONSORSHIP_SET_EDIT, {
            cid: basicAdId,
          }) + fragments.AD_SET_NAME
        );
      }
    },
    [campaignId, shouldSave, basicAdId, dispatch, push]
  );

  const handleCloseCharityModal = useCallback(() => {
    dispatch(toggleModalByName({ name: 'charity', value: false }));
  }, [dispatch]);

  const handlebasicAdActivation = useCallback(
    (e) => {
      const checked = e.target.checked;
      dispatch(toggleActivateBasicAd(checked));
      dispatch(
        checked
          ? activateBasicAdFromCreationAsync.request({ campaignId: basicAdDraft.id || query.cid })
          : deactivateBasicAdFromCreationAsync.request({ campaignId: basicAdDraft.id || query.cid })
      );
    },
    [basicAdDraft.id, dispatch, query.cid]
  );

  const {
    moreToFetch: moreNamesToFetch,
    fetchMore: fetchMoreNames,
    setKeyword: setNamesKeyword,
  } = useInfiniteScroll(fetchUniqueBasicCampaignNamesAsync.request, previousCampaignNames);

  return (
    <Layout>
      <ScrollToTopWrapper>
        {fetching ? (
          <CampaignPlaceholder />
        ) : (
          <form onKeyDown={preventSubmitOnEnter} onSubmit={handleSubmit(handleSubmitForm)}>
            <DevTool control={control} />
            <CampaignAndBrand
              fetchMoreCampaignNames={fetchMoreNames}
              moreCampaignNamesToFetch={moreNamesToFetch}
              setNamesKeyword={setNamesKeyword}
              handleSubmitForm={handleSubmitForm}
              campaignDraft={basicAdDraft}
              campaignConstants={basicAdConstants}
              modals={modals}
              previousCampaignNames={previousCampaignNames}
              toggleModalByName={toggleModalByName}
              setValue={setValue}
              watch={watch}
              register={register}
              control={control}
              errors={errors}
              trigger={trigger}
              currentBrandLogoUri={currentBrandLogoUri}
              processingBrandLogo={processingBrandLogo}
              setCurrentBrandLogoUri={setCurrentBrandLogoUri}
              setProccessingBrandLogo={setProccessingBrandLogo}
              saving={saving}
              shouldSave={shouldSave || !basicAdId}
              handleCampaignActivation={handlebasicAdActivation}
              campaignIsLocked={basicAdIsLocked}
              dirtyFieldsList={dirtyFieldsList}
            />
          </form>
        )}
      </ScrollToTopWrapper>
      {modals.charity.isOpen && (
        <Modal title={t('Add a Preferred Charity')} closeModal={handleCloseCharityModal} withSubmit>
          <NewCharity campaignDraft={basicAdDraft} campaignFetching={basicAdFetching} />
        </Modal>
      )}
    </Layout>
  );
};

export default CampaignForm;
