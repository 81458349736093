import React, { useState } from 'react';
import { SvgIcon } from '../../../../../../../components/common/SvgIcon';
import { AnimatedMenu } from '../../../../../../../components/Menus/AnimatedMenu';

import s from './NestedSummary.module.scss';

function NestedSummary({ name, iconName, children }) {
  const [showSubMenu, setShowSubmenu] = useState(true);
  return (
    <div className={s['container']}>
      <button
        className={s['dropdown-title']}
        onClick={() => setShowSubmenu((prevState) => !prevState)}
        type="button"
      >
        <div className={showSubMenu ? s['open'] : s['closed']}>
          <SvgIcon name="expand" />
        </div>
        <div className={iconName === 'adset' ? s['adset-icon'] : s['ad-icon']}>
          <SvgIcon name={iconName} />
        </div>
        <span>{name || 'New Ad Set'}</span>
      </button>
      <AnimatedMenu show={showSubMenu}>{children}</AnimatedMenu>
    </div>
  );
}

export default NestedSummary;
