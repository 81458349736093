import { createSelector } from 'reselect';

const basicAdDataStateSelector = (state) => state.basicAdData;

export const basicAdAdSetsSelector = createSelector(
  basicAdDataStateSelector,
  (state) => state.campaign.adSets
);

export const basicAdSummaryAdSetsSelector = createSelector(
  basicAdDataStateSelector,
  (state) => state.campaignSummary.adSets
);

export const basicAdCampaignSelector = createSelector(
  basicAdDataStateSelector,
  (state) => state.campaign
);

export const basicAdCampaignSummarySelector = createSelector(
  basicAdDataStateSelector,
  (state) => state.campaignSummary
);

export const basicAdCampaignIdSelector = createSelector(
  basicAdDataStateSelector,
  (state) => state.campaign.id
);

export const basicAdCampaignDataFetchingSelector = createSelector(
  basicAdDataStateSelector,
  (state) => state.fetching
);

export const basicAdAdSetSelector = createSelector(
  [basicAdDataStateSelector, (state, adSetId) => adSetId],
  (state, adSetId) => state.campaign.adSets.filter((adSet) => adSet.id === adSetId)[0]
);

export const basicAdAdSetByAdIdSelector = createSelector(
  [basicAdDataStateSelector, (state, adId) => adId],
  (state, adId) => state.campaign.adSets.find((adSet) => adSet.ads.find((ad) => ad.id === adId))
);

export const basicAdUnpublishedChangesSelector = createSelector(
  basicAdDataStateSelector,
  (state) => state.campaignDifferences
);
