import React from 'react';
import { useSelector } from 'react-redux';
import { basicAdsPricingSelector, brandInfoSelector } from '../../../../../../../../store/brand';
import s from './CpmTable.module.scss';

function CpmTable({ mediaType }) {
  const prices = useSelector(basicAdsPricingSelector);
  const currencySymbol = useSelector(brandInfoSelector).country.currencySymbol;
  const filteredPrices = prices?.filter((price) => price.mediaType === mediaType);

  return (
    <div className={s['table-container']}>
      {filteredPrices?.map((price, index) => (
        <div key={index} className={s['table-row']}>
          <div className={s['table-item']}>
            <strong>CPM:</strong> {currencySymbol}
            {price.cpm}
          </div>
          <div className={s['table-item']}>
            <strong>User Reward:</strong> {currencySymbol}
            {price.userReward}
          </div>
          <div className={s['table-item']}>
            <strong>Charity:</strong> {currencySymbol}
            {price.charity}
          </div>
          <div className={s['table-item']}>
            <strong>Climate:</strong> {currencySymbol}
            {price.climate}
          </div>
          <div className={s['table-item']}>
            <strong>WeAre8 Fee:</strong> {currencySymbol}
            {price.weAre8Fee}
          </div>
        </div>
      ))}
    </div>
  );
}

export default CpmTable;
