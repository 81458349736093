import * as yup from 'yup';

export const fourMB = 4194304;

export const isValidDoubleClickTrackingScript = (value) => {
  const formattedValue = value.toLowerCase().trim();

  //check if it starts and ends with script tags
  const validScriptStart = formattedValue.startsWith(
    `<script language='javascript1.1' src="https://ad.doubleclick.net/ddm/trackimpj/`
  );
  const validScriptEnd = formattedValue.endsWith('</script>');
  if (!validScriptStart || !validScriptEnd) {
    return false;
  }

  // check if src contains specific parameters
  const includesDc_trc_aid = formattedValue.includes('dc_trk_aid=');
  if (!includesDc_trc_aid) return false;

  const includesDc_trc_cid = formattedValue.includes('dc_trk_cid=');
  if (!includesDc_trc_cid) return false;

  const includesDc_timestamp = formattedValue.includes('ord=[timestamp];');
  if (!includesDc_timestamp) return false;

  const includesDc_lat = formattedValue.includes('dc_lat=');
  if (!includesDc_lat) return false;

  const includesDc_rdid = formattedValue.includes('dc_rdid=');
  if (!includesDc_rdid) return false;

  const includesTag_for_child_directed_treatment = formattedValue.includes(
    'tag_for_child_directed_treatment='
  );
  if (!includesTag_for_child_directed_treatment) return false;

  return true;
};

export const isValidImageTag = (value) => {
  const imgTagPattern = /^<img\s+[^>]*src\s*=\s*["'][^"']+["'][^>]*>$/i;
  if (!imgTagPattern.test(value)) {
    return false;
  }

  // Use DOMParser to parse the string and ensure it's a valid <img> element
  const parser = new DOMParser();
  const doc = parser.parseFromString(value, 'text/html');
  const imgElement = doc.querySelector('img');

  // Ensure that the parsed element is an <img> tag and has a valid src attribute
  if (!imgElement || !imgElement.src) {
    return false;
  }

  // Additional checks can be added here if needed (e.g., checking for alt attribute, etc.)
  return true;
};

// Example function to determine media type (pseudo-implementation).
export async function getMediaType(mediaUrl) {
  // If mediaUrl is a blob, fetch it to determine the MIME type.
  if (mediaUrl.startsWith('blob:')) {
    try {
      const response = await fetch(mediaUrl);
      const blob = await response.blob();
      const mimeType = blob.type; // e.g., "video/mp4", "image/jpeg"

      if (mimeType.startsWith('video/')) {
        return 'video';
      } else if (mimeType.startsWith('image/')) {
        return 'image';
      }
    } catch (error) {
      console.error('Error fetching blob media:', error);
      return null; // Return null if the blob cannot be fetched or identified.
    }
  }

  // Fallback for non-blob URLs (e.g., traditional file URLs with extensions).
  if (mediaUrl.endsWith('.mp4') || mediaUrl.endsWith('.mov')) {
    return 'video';
  } else if (mediaUrl.endsWith('.jpg') || mediaUrl.endsWith('.png')) {
    return 'image';
  }

  return null; // Unknown media type.
}

// Example function to fetch video duration (pseudo-implementation).
export async function getVideoDuration(videoUrl) {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    video.src = videoUrl;
    video.onloadedmetadata = () => {
      resolve(video.duration);
    };
    video.onerror = () => {
      resolve(0); // Return 0 if duration cannot be determined.
    };
  });
}
