import React from 'react';
import { useSelector } from 'react-redux';
import { basicAdsPricingSelector } from '../../../../../../store/brand';
import { budgetParser } from '../../../../../../utils/budget';
import BudgetAllocation from '../BudgetAllocation/BudgetAllocation';
import DeliveryInfo from '../DeliveryInfo';

const BasicDeliveryInfoAndAllocation = ({
  budget,
  isBudgetValid,
  brandCountryCode,
  selectedCharityName,
}) => {
  const prices = useSelector(basicAdsPricingSelector);

  const getBudgetData = (price) => {
    const finalBudget = +budgetParser(budget);

    const { cpm, userReward, weAre8Fee, charity } = price;

    let guarantied, payments, donations, distributions, total;

    if (isBudgetValid) {
      guarantied = Math.round(finalBudget / (cpm / 1000));
      payments = Math.round(guarantied * (userReward / 1000));
      donations = Math.round(guarantied * (charity / 1000));
      distributions = Math.round(guarantied * (weAre8Fee / 1000));
      total = finalBudget;
    }

    return {
      guarantied,
      payments,
      donations,
      distributions,
      total,
    };
  };

  return (
    <div style={{ display: 'flex', gap: '40px', alignItems: 'center', flexWrap: 'wrap' }}>
      {prices?.length ? (
        prices?.map((price) => (
          <div style={{ maxWidth: '450px' }}>
            <h4 style={{ marginBottom: '-40px', marginTop: '50px' }}>
              Info for {price.mediaType} pricing
            </h4>
            <DeliveryInfo
              key={price.mediaType}
              budget={budget}
              pricePerMessage={price.cpm / 1000}
            />
            <BudgetAllocation
              hasValidBudget={budget && isBudgetValid}
              brandCountryCode={brandCountryCode}
              budgetData={getBudgetData(price)}
              pricing={{
                userReward: price.userReward / 1000,
                charityDonation: price.charity / 1000,
                weAre8Fee: price.weAre8Fee / 1000,
              }}
              selectedCharityName={selectedCharityName}
            />
          </div>
        ))
      ) : (
        <></>
      )}
    </div>
  );
};

export default BasicDeliveryInfoAndAllocation;
