import React, { useEffect, useMemo, useState } from 'react';
import { Switch, Route } from 'react-router';

import { ProtectedRoute } from '../../components/common/ProtectedRoute';

import { Login } from '../Login';
import { SignUp } from '../SignUp';
import { NotFound } from '../NotFound';
import { Settings } from '../Settings';
import { Reporting } from '../Reporting';
import { Spinner } from '../../components/Spinner';

import { WithErrorModalContainer } from '../wrappers/WithErrorModalContainer';
import { WithSidebarContainer } from '../wrappers/WithSidebarContainer';
import { WithSavingToastContainer } from '../wrappers/WithSavingToastContainer';
import { eightAuth } from '../../store/eightauth';
import { useDispatch, useSelector } from 'react-redux';

import { routes } from '../../constants';
import { adminAccessTokenSelector } from '../../store/auth';
import { WithUploadingToastContainer } from '../wrappers/WithUploadingToastContainer';
import { CampaignSetup } from '../CampaignSetup';
import { TrackingTest } from '../TrackingTest';
import { PixelLanding } from '../PixelLanding';
import { PixelPurchase } from '../PixelPurchase';
import { PixelSubscription } from '../PixelSubscription';
import {
  brandInfoSelector,
  fetchAccountBalanceAsync,
  fetchBrandAsync,
  fetchCountriesAsync,
} from '../../store/brand';
import ResetPasswordEmail from '../../components/PagesContent/ResetPasswordContent/ResetPasswordEmail/ResetPasswordEmail';
import ResetPasswordReset from '../../components/PagesContent/ResetPasswordContent/ResetPasswordReset/ResetPasswordReset';
import ResetPasswordSuccess from '../../components/PagesContent/ResetPasswordContent/ResetPasswordSuccess/ResetPasswordSuccess';
import ResetPasswordEmailSent from '../../components/PagesContent/ResetPasswordContent/ResetPasswordEmailSent/ResetPasswordEmailSent';
import { getFromLocalStorage } from '../../utils/localStorage';
import { WithTopbarContainer } from '../wrappers/WithTopbarContainer';
import { history } from '../../store';
import { PixelLandingTest } from '../PixelLandingTest';
import { PixelPurchaseTest } from '../PixelPurchaseTest';
import { PixelSubscriptionTest } from '../PixelSubscriptionTest';
import BasicAdSetUp from '../BasicAd/BasicAdSetup/BasicAdSetup';
// import { useLanguage } from '../../hooks/useLanguage';

const PrivateRoutes = () => {
  const dispatch = useDispatch();
  const accessToken = useSelector(eightAuth.accessTokenSelector);
  const refreshLoading = useSelector(eightAuth.refreshingSelector);
  const loginLoading = useSelector(eightAuth.loginLoadingSelector);
  const adminAccessToken = useSelector(adminAccessTokenSelector);
  const { id: brandId } = useSelector(brandInfoSelector);
  const [isUnitialized, setIsUnitialized] = useState(true);
  const refreshToken = getFromLocalStorage('refreshToken');

  const authLoading = useMemo(
    () => refreshLoading || loginLoading || isUnitialized,
    [isUnitialized, loginLoading, refreshLoading]
  );
  useEffect(() => {
    setIsUnitialized(false);
    if (refreshToken && !adminAccessToken && !accessToken)
      dispatch(eightAuth.refreshAsync.request());
    else if (authLoading) return;
    else if (accessToken) dispatch(fetchBrandAsync.request());
    else if (!adminAccessToken) history.push(routes.LOGIN);
  }, [
    accessToken,
    adminAccessToken,
    dispatch,
    authLoading,
    refreshToken,
    isUnitialized,
    refreshLoading,
    loginLoading,
  ]);

  useEffect(() => {
    if (accessToken) dispatch(fetchAccountBalanceAsync.request());
  }, [dispatch, accessToken]);

  useEffect(() => {
    if (brandId) dispatch(fetchCountriesAsync.request());
  }, [brandId, dispatch]);

  if (!brandId && !adminAccessToken) {
    return <Spinner />;
  }
  const TopBar = adminAccessToken ? ({ children }) => children : WithTopbarContainer;
  return (
    <>
      <TopBar>
        <Switch>
          <ProtectedRoute
            path={routes.TRACKING_TEST}
            isAuthenticated={accessToken}
            redirectPath={routes.LOGIN}
          >
            <TrackingTest />
          </ProtectedRoute>
          <ProtectedRoute
            path={routes.SETTINGS}
            isAuthenticated={accessToken}
            redirectPath={routes.LOGIN}
          >
            <Settings />
          </ProtectedRoute>
          <ProtectedRoute
            path={routes.CAMPAIGN_EDIT}
            isAuthenticated={accessToken}
            redirectPath={routes.LOGIN}
          >
            <WithSavingToastContainer>
              <WithUploadingToastContainer>
                <CampaignSetup />
              </WithUploadingToastContainer>
            </WithSavingToastContainer>
          </ProtectedRoute>
          <ProtectedRoute
            path={routes.BASIC_AD.EDIT_CAMPAIGN}
            isAuthenticated={accessToken}
            redirectPath={routes.LOGIN}
          >
            <WithSavingToastContainer>
              <WithUploadingToastContainer>
                <BasicAdSetUp />
              </WithUploadingToastContainer>
            </WithSavingToastContainer>
          </ProtectedRoute>
          <ProtectedRoute
            path={routes.ADMIN_REPORTING}
            isAuthenticated={adminAccessToken ?? accessToken}
            redirectPath={routes.LOGIN}
          >
            <Reporting />
          </ProtectedRoute>
          <ProtectedRoute
            path={routes.HOME}
            isAuthenticated={accessToken}
            redirectPath={routes.LOGIN}
          >
            <WithSidebarContainer />
          </ProtectedRoute>
        </Switch>
      </TopBar>
    </>
  );
};

const App = () => {
  // useLanguage();

  return (
    <>
      <WithErrorModalContainer>
        <Switch>
          <Route exact path={routes.LOGIN} component={Login} />
          <Route exact path={routes.SIGN_UP} component={SignUp} />
          <Route exact path={routes.RESET_PASSWORD_EMAIL} component={ResetPasswordEmail} />
          <Route exact path={routes.RESET_PASSWORD_EMAIL_SENT} component={ResetPasswordEmailSent} />
          <Route exact path={routes.RESET_PASSWORD_RESET} component={ResetPasswordReset} />
          <Route exact path={routes.RESET_PASSWORD_SUCCESS} component={ResetPasswordSuccess} />
          <Route exact path={routes.PIXEL_LANDING} component={PixelLanding} />
          <Route exact path={routes.PIXEL_PURCHASE} component={PixelPurchase} />
          <Route exact path={routes.PIXEL_SUBSCRIPTION} component={PixelSubscription} />
          <Route exact path={routes.PIXEL_LANDING_TEST} component={PixelLandingTest} />
          <Route exact path={routes.PIXEL_PURCHASE_TEST} component={PixelPurchaseTest} />
          <Route exact path={routes.PIXEL_SUBSCRIPTION_TEST} component={PixelSubscriptionTest} />
          <PrivateRoutes />
          <Route component={NotFound} />
        </Switch>
      </WithErrorModalContainer>
    </>
  );
};

export default App;
