import { createAsyncAction, createAction } from 'eight.js.store-common';

export const publishBasicAdCampaignAsync = createAsyncAction('@@basicAdSummary/PUBLISH_CAMPAIGN');

export const clearBasicAdSummary = createAction('@@basicAdSummary/CLEAR_SUMMARY');

export const activateAllBasicAdAsync = createAsyncAction('@@basicAdSummary/ACTIVATE_ALL');

export const setAllBasicAdModulesActivated = createAction(
  '@@basicAdSummary/SET_ALL_MODULES_ACTIVATED'
);

export const activateBasicAdCampaignFromBilling = createAction(
  '@@basicAdSummary/ACTIVATE_CAMPAIGN_FROM_BILLING'
);

export const updateBasicAdBrandInfoFromCampaignSummaryAsync = createAsyncAction(
  '@@basicAdSummary/UPDATE_BRAND_INFO_FROM_CAMPAIGN_SUMMARY'
);

export const setBasicAdIsPublishing = createAction('@@basicAdSummary/SET_IS_PUBLISHING');
