import { createReducer, initialAsyncState } from 'eight.js.store-common';

import {
  activateBasicAdSponsorshipSetFromCreationAsync,
  clearBasicAdSponsorshipSet,
  deactivateBasicAdSponsorshipSetFromCreationAsync,
  setBasicAdDataArray,
  setBasicAdSponsorshipSetIsReadOnly,
  setBasicAdSponsorshipSetInternalFetching,
  toggleBasicAdActivateSponsorshipSet,
  updateBasicAdSponsorshipSetAsync,
  setBasicAdSponsorshipSetData,
  setBasicAdCustomAudiencesTags,
  setBasicAdActiveAdSet,
  setBasicAdSponsorshipSetName,
  fetchBasicAdAdSetPricingOptionsAsync,
  calculateDailyBudgetAsync,
  setDailyBudget,
} from './actions';
import sponsorshipSetConstants from '../../../constants/basicAd/sponsorshipSet-data';

const initialState = {
  ...initialAsyncState,
  sponsorshipSetCreate: sponsorshipSetConstants.initialState,
  sponsorshipSetEdit: {
    id: '',
  },
  dailyBudget: '-',
  error: '',
  fetching: false,
};

export const basicAdSponsorshipSetReducer = createReducer('@@basicAdSponsorshipSet', initialState, {
  [setBasicAdActiveAdSet]: ({ state, action }) => {
    // Remove dirtyFields and isDirty fields from object
    const { dirtyFields, isDirty, index, ...sponsorshipSet } = state.sponsorshipSetCreate;
    state.sponsorshipSetCreate = { ...sponsorshipSet, ...action.payload };
  },

  [setBasicAdCustomAudiencesTags]: ({ state, action }) => {
    state.sponsorshipSetCreate.customAudiencesTags = action.payload;
  },
  [setBasicAdSponsorshipSetData]: ({ state, action }) => {
    const { name, value } = action.payload;
    state.sponsorshipSetCreate[name] = value;
  },
  [activateBasicAdSponsorshipSetFromCreationAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [clearBasicAdSponsorshipSet]: ({ state }) => {
    state.sponsorshipSetCreate = initialState.sponsorshipSetCreate;
    state.sponsorshipSetEdit = initialState.sponsorshipSetEdit;
  },

  [deactivateBasicAdSponsorshipSetFromCreationAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },
  [setBasicAdDataArray]: ({ state, action }) => {
    const { name, value } = action.payload;
    state.sponsorshipSetCreate = {
      ...state.sponsorshipSetCreate,
      [name]: value,
    };
  },

  [calculateDailyBudgetAsync.success]: ({ state, action }) => {
    state.dailyBudget = action.payload;
  },

  [setBasicAdSponsorshipSetInternalFetching]: ({ state, action }) => {
    state.sponsorshipSetCreate.internalFetching = action.payload;
  },

  [updateBasicAdSponsorshipSetAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },
  [setBasicAdSponsorshipSetIsReadOnly]: ({ state, action }) => {
    state.sponsorshipSetCreate.isReadOnly = action.payload;
  },
  [toggleBasicAdActivateSponsorshipSet]: ({ state, action }) => {
    state.sponsorshipSetCreate.isActive = action.payload;
  },
  [updateBasicAdSponsorshipSetAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },
  [setBasicAdSponsorshipSetName]: ({ state, action }) => {
    state.sponsorshipSetCreate.name = action.payload;
  },
  [fetchBasicAdAdSetPricingOptionsAsync.success]: ({ state, action }) => {
    state.sponsorshipSetCreate.videoAdPricingData = action.payload.pricingList;
  },
  [setDailyBudget]: ({ state, action }) => {
    state.dailyBudget = action.payload;
  },
});
