import * as yup from 'yup';
import { t } from '@transifex/native';

const fourMB = 4194304;

export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .min(3, t('Minimum length should be 3 chars'))
    .max(100, t('Maximum length should not exceed 100 chars'))
    .required(t('Campaign name is a required field')),
  campaignBrandName: yup
    .string()
    .min(3, t('Minimum length should be 3 chars'))
    .max(100, t('Maximum length should not exceed 100 chars'))
    .required(t('Brand name is a required field')),
  campaignBrandLogoUri: yup
    .string()
    .when('campaignBrandLogoFile', (campaignBrandLogoFile, schema) => {
      if (!campaignBrandLogoFile?.size) return schema;

      return schema.test('is-more-4MB', t('Cropped file should not be more than 4MB'), () => {
        return campaignBrandLogoFile.size < fourMB;
      });
    }),

  selectedCharity: yup
    .object()
    .nullable()
    .required(t('Charity is a required field'))
    .shape({
      name: yup.string().required(t('Charity is a required field')).nullable(),
    }),
});
