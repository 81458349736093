import { createSelector } from 'reselect';
import { processCharityCategory } from '../../utils/receivedData';

const brandStateSelector = (state) => state.brand;

export const isBrandErrorSelector = createSelector(brandStateSelector, (state) => state.error);

export const brandTransactionsSelector = createSelector(
  brandStateSelector,
  (state) => state.brandTransactions
);

export const brandIsLoadingSelector = createSelector(
  brandStateSelector,
  (state) => state.brandIsLoading
);

export const brandBillDetailsSelector = createSelector(
  brandStateSelector,
  (state) => state.brandBillDetails
);

export const paymentMethodsSelector = createSelector(
  brandStateSelector,
  (state) => state.brandInfo.paymentMethods
);

export const itemsPerPageSelector = createSelector(
  brandStateSelector,
  (state) => state.itemsPerPage
);

export const brandBillsSelector = createSelector(brandStateSelector, (state) => state.brandBills);

export const brandInfoSelector = createSelector(brandStateSelector, (state) => state.brandInfo);

export const stripeChargeErrorSelector = createSelector(
  brandInfoSelector,
  (state) => state.stripeChargeErrors
);

export const brandIsFetchingSelector = createSelector(
  brandStateSelector,
  (state) => state.fetching
);

export const charitiesCategorySelector = createSelector(brandInfoSelector, (state) =>
  processCharityCategory(state.fetchedCharities)
);

export const charitiesSelector = createSelector(
  brandInfoSelector,
  (state) => state.fetchedCharities
);

export const interestsSelector = createSelector(
  brandInfoSelector,
  (state) => state.fetchedInterests
);

export const incentivesSelector = createSelector(
  brandInfoSelector,
  (state) => state.videoAdPricingData
);

export const defaultCountrySelector = createSelector(
  brandInfoSelector,
  (state) =>
    state.fetchedCountries.filter((country) => country.countryCode === state.countryCode)[0]
);

export const isBrandInfoSavedSelector = createSelector(
  brandStateSelector,
  (state) => state.isBrandInfoSaved
);

export const vatAndFeeSelector = createSelector(brandStateSelector, (state) => state.vatAndFee);

export const lastStripeChargeSelector = createSelector(
  brandStateSelector,
  (state) => state.lastStripeCharge
);

export const invoicePartnerRequestSelector = createSelector(
  brandStateSelector,
  (state) => state.invoicePartnerRequest
);

export const fetchedTagsSelector = createSelector(
  brandStateSelector,
  (state) => state.brandInfo.fetchedTags
);

export const fetchedPassionsSelector = createSelector(
  brandStateSelector,
  (state) => state.brandInfo.fetchedPassions
);

export const fetchedTimezonesSelector = createSelector(
  brandStateSelector,
  (state) => state.brandInfo.fetchedTimezones
);

export const fetchedTimezoneSelector = createSelector(
  brandStateSelector,
  (state) => state.brandInfo.brandTimezone
);

export const fetchedPixelStatsSelector = createSelector(
  brandStateSelector,
  (state) => state.brandInfo.fetchedPixelStats
);

export const locationsSelector = createSelector(
  brandStateSelector,
  (state) => state.brandInfo.fetchedLocations
);

export const previousCampaignNamesSelector = createSelector(
  brandStateSelector,
  (state) => state.brandInfo.previousCampaignNames
);

export const previousBasicCampaignNamesSelector = createSelector(
  brandStateSelector,
  (state) => state.brandInfo.previousBasicCampaignNames
);
export const previousSponsorshipSetsNamesSelector = createSelector(
  brandStateSelector,
  (state) => state.brandInfo.previousSponsorshipSetsNames
);
export const previousBasicSponsorshipSetsNamesSelector = createSelector(
  brandStateSelector,
  (state) => state.brandInfo.previousBasicSponsorshipSetsNames
);

export const previousSponsorshipNamesSelector = createSelector(
  brandStateSelector,
  (state) => state.brandInfo.previousSponsorshipNames
);

export const previousUniqueCampaignNamesSelector = createSelector(
  brandStateSelector,
  (state) => state.brandInfo.previousUniqueCampaignNames
);
export const previousUniqueBasicCampaignNamesSelector = createSelector(
  brandStateSelector,
  (state) => state.brandInfo.previousUniqueBasicCampaignNames
);

export const previousUniqueSponsorshipSetsNamesSelector = createSelector(
  brandStateSelector,
  (state) => state.brandInfo.previousUniqueSponsorshipSetsNames
);
export const previousUniqueBasicSponsorshipSetsNamesSelector = createSelector(
  brandStateSelector,
  (state) => state.brandInfo.previousUniqueBasicSponsorshipSetsNames
);

export const previousUniqueSponsorshipNamesSelector = createSelector(
  brandStateSelector,
  (state) => state.brandInfo.previousUniqueSponsorshipNames
);
export const previousUniqueBasicSponsorshipNamesSelector = createSelector(
  brandStateSelector,
  (state) => state.brandInfo.previousUniqueBasicSponsorshipNames
);

export const pixelTestEventsSelector = createSelector(
  brandStateSelector,
  (state) => state.pixelTestEvents
);

export const isPixelTestInProgressSelector = createSelector(
  brandStateSelector,
  (state) => state.isPixelTestInProgress
);

export const basicAdsPricingSelector = createSelector(
  brandStateSelector,
  (state) => state.brandInfo.basicAdsPricing
);
