import { routes } from '../constants';

const mainMenu = Object.freeze({
  items: [
    {
      name: 'Home',
      subtitle: '',
      path: routes.HOME,
      icon: 'home',
    },
    {
      name: 'Create Premium Ad',
      subtitle: 'Start a new premium campaign',
      path: routes.CAMPAIGN_CREATE,
      icon: 'create-campaign',
    },
    {
      name: 'Create Basic Ad',
      subtitle: 'Start new basic ad campaign',
      path: routes.BASIC_AD.CREATE_CAMPAIGN,
      icon: 'create-campaign',
    },
    {
      name: 'Manage Premium Ads',
      subtitle: 'Manage your drafted and live campaigns',
      path: routes.MANAGE_PREMIUM_ADS,
      icon: 'manage-campaign',
    },
    {
      name: 'Manage Basic Ads',
      subtitle: 'Manage your drafted and live campaigns',
      path: routes.BASIC_AD.MANAGE,
      icon: 'manage-campaign',
    },
    {
      name: 'Business Settings',
      subtitle: 'Manage your account and payments',
      path: routes.SETTINGS,
      icon: 'settings',
    },
  ],
});

export default mainMenu;
