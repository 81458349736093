import { createReducer, initialAsyncState } from 'eight.js.store-common';

import {
  clearBasicAdSummary,
  publishBasicAdCampaignAsync,
  setAllBasicAdModulesActivated,
  setBasicAdIsPublishing,
} from './actions';

const initialState = {
  ...initialAsyncState,
  summaryState: {
    allModulesActivated: false,
  },
  fetching: false,
  error: '',
};

export const basicAdSummaryReducer = createReducer('@@basicAdSummary', initialState, {
  [publishBasicAdCampaignAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [clearBasicAdSummary]: ({ state }) => {
    state.summaryState = initialState.summaryState;
  },

  [setAllBasicAdModulesActivated]: ({ state, action }) => {
    state.summaryState.allModulesActivated = action.payload;
  },

  [setBasicAdIsPublishing]: ({ state, action }) => {
    state.fetching = action.payload;
  },
});
