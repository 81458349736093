import { createReducer } from 'eight.js.store-common';
import {
  basicAdCampaignDataFetching,
  clearBasicAdState,
  createNewBasicAdAd,
  createNewBasicAdAdSet,
  deleteBasicAdAd,
  deleteBasicAdAdSet,
  duplicateBasicAdAd,
  duplicateBasicAdAdSet,
  fetchBasicAdCampaignAsync,
  setBasicAdAdIsDirty,
  setBasicAdAdSetIsDirty,
  setBasicAdCampaignIsDirty,
  setBasicAdCampaignIsPublished,
  setBasicAdFetchedNameAndLogo,
  setUnpublishedBasicAdDifferences,
} from './actions';

import { basicAdConstants } from '../../constants';
import sponsorshipConstants from '../../constants/basicAd/sponsorship-data';
import sponsorshipSetConstants from '../../constants/basicAd/sponsorshipSet-data';
import { getAdAllDirtyFields, getAdSetAllDirtyFields } from '../../utils/form/formHelpers';
import {
  activateBasicAdFromCreationAsync,
  createBasicAdAsync,
  deactivateBasicAdFromCreationAsync,
  updateBasicAdAsync,
} from '../basicAd/campaign';
import {
  activateBasicAdSponsorshipFromCreationAsync,
  createBasicAdSponsorshipAsync,
  deactivateBasicAdSponsorshipFromCreationAsync,
  updateBasicAdSponsorshipAsync,
  uploadBasicAdVideoAsync,
} from '../basicAd/sponsorship';
import {
  activateBasicAdSponsorshipSetFromCreationAsync,
  createBasicAdSponsorshipSetAsync,
  deactivateBasicAdSponsorshipSetFromCreationAsync,
  updateBasicAdSponsorshipSetAsync,
} from '../basicAd/sponsorshipSet';
const initialState = {
  fetching: true,
  error: '',
  campaign: basicAdConstants.initialState,
  campaignDifferences: [],
  campaignSummary: { adSets: [] },
};

export const basicAdDataReducer = createReducer('@@basicAdData', initialState, {
  [clearBasicAdState]: ({ state }) => {
    state.campaign = {
      ...basicAdConstants.initialState,
      adSets: [
        {
          ...sponsorshipSetConstants.initialState,
          ads: [{ ...sponsorshipConstants.initialState }],
        },
      ],
    };
    state.campaignSummary = { adSets: [] };
    state.fetching = true;
  },
  [basicAdCampaignDataFetching]: ({ state, action }) => {
    state.fetching = action.payload;
  },
  [createNewBasicAdAdSet]: ({ state, action }) => {
    state.campaign.adSets = [
      ...state.campaign.adSets,
      {
        ...sponsorshipSetConstants.initialState,
        id: action.payload.newId,
        ads: [{ ...sponsorshipConstants.initialState }],
      },
    ];
  },
  [duplicateBasicAdAdSet]: ({ state, action }) => {
    let adSetToDuplicate = {};

    if (action.payload.adSetId === 'new')
      adSetToDuplicate = {
        ...state.campaign.adSets[action.payload.adSetIndex],
      };
    else
      adSetToDuplicate = {
        ...state.campaign.adSets.find((set) => set.id === action.payload.adSetId),
      };

    adSetToDuplicate.ads = adSetToDuplicate.ads.map((ad) => {
      return {
        ...ad,
        isDirty: true,
        isDraft: true,
        dirtyFields: getAdAllDirtyFields(),
        isCreated: false,
        ctaCover: '',
        adVideo: '',
        videoUploadStatus: '',
        isVideoUploaded: false,
        moderationStatus: 'draft',
        isActive: false,
        deliveryStatus: 'draft',
      };
    });

    state.campaign.adSets = [
      ...state.campaign.adSets,
      {
        ...adSetToDuplicate,
        id: action.payload.newId,
        name: `Copy of ${adSetToDuplicate.name}`,
        isCreated: false,
        isDirty: true,
        isDraft: true,
        dirtyFields: getAdSetAllDirtyFields(),
        moderationStatus: 'draft',
        index: action.payload.adSetIndex,
        isActive: false,
        deliveryStatus: 'draft',
        samiPricingId: '',
        ads: adSetToDuplicate?.ads?.map((adToDuplicate, index) => ({
          ...adToDuplicate,
          ...adToDuplicate,
          id: action.payload.newId,
          name: `Copy of ${adToDuplicate.name}`,
          isDirty: true,
          dirtyFields: getAdAllDirtyFields(),
          isCreated: false,
          isDraft: true,
          index,
          ctaCover: '',
          adVideo: '',
          videoWidth: '',
          videoHeight: '',
          videoUploadStatus: '',
          isVideoUploaded: false,
          moderationStatus: 'draft',
          isActive: false,
          deliveryStatus: 'draft',
        })),
      },
    ];
  },
  [deleteBasicAdAdSet]: ({ state, action }) => {
    if (state.campaign.adSets.length > 1) {
      if (action.payload.adSetId !== 'new') {
        state.campaign.adSets = state.campaign.adSets.filter(
          (set) => set.id !== action.payload.adSetId
        );
        state.campaignSummary.adSets = state.campaignSummary.adSets.filter(
          (set) => set.id !== action.payload.adSetId
        );
      } else {
        state.campaign.adSets = state.campaign.adSets.filter(
          (set, index) => index !== action.payload.adSetIndex
        );
        state.campaignSummary.adSets = state.campaignSummary.adSets.filter(
          (set, index) => index !== action.payload.adSetIndex
        );
      }
    }
  },
  [createNewBasicAdAd]: ({ state, action }) => {
    const setIndex = state.campaign.adSets.findIndex((set) => set.id === action.payload.adSetId);
    state.campaign.adSets[setIndex].ads = [
      ...state.campaign.adSets[setIndex].ads,
      { ...sponsorshipConstants.initialState, id: action.payload.newId },
    ];
  },
  [duplicateBasicAdAd]: ({ state, action }) => {
    const selectedAdSetIndex = state.campaign.adSets.findIndex(
      (set) => set.id === action.payload.adSetId
    );
    const adToDuplicate = state.campaign.adSets[selectedAdSetIndex].ads.find(
      (ad) => ad.id === action.payload.adId
    );

    state.campaign.adSets[selectedAdSetIndex].ads = [
      ...state.campaign.adSets[selectedAdSetIndex].ads,
      {
        ...adToDuplicate,
        id: action.payload.newId,
        name: `Copy of ${adToDuplicate.name}`,
        isDirty: true,
        dirtyFields: getAdAllDirtyFields(),
        isCreated: false,
        isDraft: true,
        index: action.payload.adIndex,
        ctaCover: '',
        adVideo: '',
        videoWidth: '',
        videoHeight: '',
        videoUploadStatus: '',
        isVideoUploaded: false,
        moderationStatus: 'draft',
        isActive: false,
        deliveryStatus: 'draft',
      },
    ];
  },
  [deleteBasicAdAd]: ({ state, action }) => {
    const adSetIndex = state.campaign.adSets.findIndex(
      (adSet) => adSet.id === action.payload.adSetId
    );
    if (state.campaign.adSets[adSetIndex].ads.length > 1) {
      if (action.payload.adId !== 'new') {
        state.campaign.adSets[adSetIndex].ads = state.campaign.adSets[adSetIndex].ads.filter(
          (ad) => ad.id !== action.payload.adId
        );
        state.campaignSummary.adSets[adSetIndex].ads = state.campaignSummary.adSets[
          adSetIndex
        ].ads.filter((ad) => ad.id !== action.payload.adId);
      } else {
        state.campaign.adSets[adSetIndex].ads = state.campaign.adSets[adSetIndex].ads.filter(
          (ad, index) => index !== action.payload.adIndex
        );
        state.campaignSummary.adSets[adSetIndex].ads = state.campaignSummary.adSets[
          adSetIndex
        ].ads.filter((ad, index) => index !== action.payload.adIndex);
      }
    }
  },
  [fetchBasicAdCampaignAsync.success]: ({ state, action }) => {
    state.campaign = action.payload;
    state.campaignSummary = action.payload;
  },
  [setUnpublishedBasicAdDifferences]: ({ state, action }) => {
    const campaignDifferences = action.payload;

    const modifiedSummaryAdSets = state.campaignSummary.adSets.map((adSet) => {
      const adSetDifferences = campaignDifferences
        .find((diff) => diff.id === adSet.id)
        ?.differences.map((item) => item.modifiedField);
      const modifiedAds = adSet.ads.map((ad) => {
        const adDifferences = campaignDifferences
          .find((diff) => diff.id === ad.id)
          ?.differences.map((item) => item.modifiedField);
        return {
          ...ad,
          differences: adDifferences,
        };
      });
      return {
        ...adSet,
        differences: adSetDifferences,
        ads: modifiedAds,
      };
    });

    const modifiedAdSets = state.campaign.adSets.map((adSet) => {
      const adSetDifferences = campaignDifferences
        .find((diff) => diff.id === adSet.id)
        ?.differences.map((item) => ({
          modifiedField: item.modifiedField,
          publishedValue: item.publishedValue,
        }));
      const modifiedAds = adSet.ads.map((ad) => {
        const adDifferences = campaignDifferences
          .find((diff) => diff.id === ad.id)
          ?.differences.map((item) => ({
            modifiedField: item.modifiedField,
            publishedValue: item.publishedValue,
          }));
        return {
          ...ad,
          differences: adDifferences,
        };
      });
      return {
        ...adSet,
        differences: adSetDifferences,
        ads: modifiedAds,
      };
    });

    state.campaignDifferences = action.payload;
    state.campaignSummary = { ...state.campaignSummary, adSets: modifiedSummaryAdSets };
    state.campaign = { ...state.campaign, adSets: modifiedAdSets };
  },
  [createBasicAdAsync.success]: ({ state, action }) => {
    Object.keys(action.payload).map((key) => (state.campaign[key] = action.payload[key]));
    state.campaign.isCreated = true;
    state.campaign.isActive = true;

    Object.keys(action.payload).map((key) => (state.campaignSummary[key] = action.payload[key]));
    state.campaignSummary.isCreated = true;
    state.campaignSummary.isActive = true;
  },
  [updateBasicAdAsync.success]: ({ state, action }) => {
    Object.keys(action.payload).map((key) => (state.campaign[key] = action.payload[key]));
    state.campaign.isCreated = true;
    state.campaign.isActive = true;

    Object.keys(action.payload).map((key) => (state.campaignSummary[key] = action.payload[key]));
    state.campaignSummary.isCreated = true;
    state.campaignSummary.isActive = true;
  },

  [createBasicAdSponsorshipSetAsync.success]: ({ state, action }) => {
    const adSetIndex = action.payload.adSetIndex;
    Object.keys(action.payload.form).map(
      (key) => (state.campaign.adSets[adSetIndex][key] = action.payload.form[key])
    );
    state.campaign.adSets[adSetIndex].id = action.payload.sponsorshipSetId;
    state.campaign.adSets[adSetIndex].isCreated = true;
    state.campaign.adSets[adSetIndex].isActive = true;
    state.campaign.adSets[adSetIndex].isDirty = false;
    state.campaign.adSets[adSetIndex].dirtyFields = [];

    state.campaignSummary.adSets = [
      ...state.campaignSummary.adSets,
      state.campaign.adSets[adSetIndex],
    ];
  },

  [updateBasicAdSponsorshipSetAsync.success]: ({ state, action }) => {
    const adSetIndex = state.campaign.adSets.findIndex(
      (adSet) => adSet.id === action.payload.sponsorshipSetId
    );

    Object.keys(action.payload.form).map(
      (key) => (state.campaign.adSets[adSetIndex][key] = action.payload.form[key])
    );
    state.campaign.adSets[adSetIndex].isCreated = true;
    state.campaign.adSets[adSetIndex].isActive = true;
    state.campaign.adSets[adSetIndex].isDirty = false;
    state.campaign.adSets[adSetIndex].dirtyFields = [];

    Object.keys(action.payload.form).map(
      (key) => (state.campaignSummary.adSets[adSetIndex][key] = action.payload.form[key])
    );
    state.campaignSummary.adSets[adSetIndex].isCreated = true;
    state.campaignSummary.adSets[adSetIndex].isActive = true;
    state.campaignSummary.adSets[adSetIndex].isDirty = false;
    state.campaignSummary.adSets[adSetIndex].dirtyFields = [];
  },

  [createBasicAdSponsorshipAsync.success]: ({ state, action }) => {
    const adSetIndex = state.campaign.adSets.findIndex(
      (adSet) => adSet.id === action.payload.sponsorshipSetId
    );
    const adIndex = action.payload.adIndex;

    Object.keys(action.payload.form).map(
      (key) => (state.campaign.adSets[adSetIndex].ads[adIndex][key] = action.payload.form[key])
    );

    state.campaign.adSets[adSetIndex].ads[adIndex].id = action.payload.sponsorshipId;
    state.campaign.adSets[adSetIndex].ads[adIndex].isCreated = true;
    state.campaign.adSets[adSetIndex].ads[adIndex].isActive = true;
    state.campaign.adSets[adSetIndex].ads[adIndex].isDirty = false;
    state.campaign.adSets[adSetIndex].ads[adIndex].dirtyFields = [];

    state.campaignSummary.adSets[adSetIndex].ads[adIndex] = {
      ...state.campaign.adSets[adSetIndex].ads[adIndex],
    };
  },

  [updateBasicAdSponsorshipAsync.success]: ({ state, action }) => {
    const adSetIndex = state.campaign.adSets.findIndex(
      (adSet) => adSet.id === action.payload.sponsorshipSetId
    );
    const adIndex = state.campaign.adSets[adSetIndex].ads.findIndex(
      (ad) => ad.id === action.payload.sponsorshipId
    );
    Object.keys(action.payload.form).map(
      (key) => (state.campaign.adSets[adSetIndex].ads[adIndex][key] = action.payload.form[key])
    );
    state.campaign.adSets[adSetIndex].ads[adIndex].isCreated = true;
    state.campaign.adSets[adSetIndex].ads[adIndex].isActive = true;
    state.campaign.adSets[adSetIndex].ads[adIndex].isDirty = false;
    state.campaign.adSets[adSetIndex].ads[adIndex].dirtyFields = [];

    state.campaignSummary.adSets[adSetIndex].ads[adIndex] = {
      ...state.campaign.adSets[adSetIndex].ads[adIndex],
    };
  },

  [setBasicAdFetchedNameAndLogo]: ({ state, action }) => {
    const { campaignBrandName, campaignBrandLogoUri } = action.payload;
    state.campaign.campaignBrandName = campaignBrandName;
    state.campaign.campaignBrandLogoUri = campaignBrandLogoUri;
  },

  [setBasicAdCampaignIsDirty]: ({ state, action }) => {
    Object.keys(action.payload.form).map((key) => (state.campaign[key] = action.payload.form[key]));
    state.campaign.isDirty = action.payload.isDirty;
    state.campaign.dirtyFields = action.payload.dirtyFields;
  },

  [setBasicAdAdSetIsDirty]: ({ state, action }) => {
    const adSetIndex = state.campaign.adSets.findIndex(
      (adSet) => adSet.id === action.payload.adSetId
    );

    if (adSetIndex !== -1) {
      Object.keys(action.payload.form).forEach(
        (key) => (state.campaign.adSets[adSetIndex][key] = action.payload.form[key])
      );
      state.campaign.adSets[adSetIndex].isDirty = action.payload.isDirty;
      state.campaign.adSets[adSetIndex].dirtyFields = action.payload.dirtyFields;
    } else {
      const newAdSetIndex = state.campaign.adSets.findIndex((adSet) => adSet.id === 'new');
      if (newAdSetIndex !== -1) {
        Object.keys(action.payload.form).forEach(
          (key) => (state.campaign.adSets[newAdSetIndex][key] = action.payload.form[key])
        );
        state.campaign.adSets[newAdSetIndex].isDirty = action.payload.isDirty;
        state.campaign.adSets[newAdSetIndex].dirtyFields = action.payload.dirtyFields;
      }
    }
  },

  [setBasicAdAdIsDirty]: ({ state, action }) => {
    const adSetIndex = state.campaign.adSets.findIndex(
      (adSet) => adSet.id === action.payload.adSetId
    );
    if (adSetIndex !== -1) {
      const adIndex = state.campaign.adSets[adSetIndex].ads.findIndex(
        (ad) => ad.id === action.payload.adId
      );

      if (adIndex !== -1) {
        Object.keys(action.payload.form).map(
          (key) => (state.campaign.adSets[adSetIndex].ads[adIndex][key] = action.payload.form[key])
        );
        state.campaign.adSets[adSetIndex].ads[adIndex].isDirty = action.payload.isDirty;
        state.campaign.adSets[adSetIndex].ads[adIndex].dirtyFields = action.payload.dirtyFields;
      }
    }
  },

  [activateBasicAdFromCreationAsync.success]: ({ state }) => {
    state.campaign.isActive = true;
  },

  [deactivateBasicAdFromCreationAsync.success]: ({ state }) => {
    state.campaign.isActive = false;
    state.campaign.adSets = state.campaign.adSets.map((adSet) => {
      const formattedAds = adSet.ads.map((ad) => {
        return { ...ad, isActive: false };
      });
      adSet.isActive = false;
      return {
        ...adSet,
        ads: formattedAds,
      };
    });
  },

  [activateBasicAdSponsorshipSetFromCreationAsync.success]: ({ state, action }) => {
    const adSetId = action.payload;
    const adSetIndex = state.campaign.adSets.findIndex((adSet) => adSet.id === adSetId);

    state.campaign.isActive = true;
    state.campaign.adSets[adSetIndex].isActive = true;
  },

  [deactivateBasicAdSponsorshipSetFromCreationAsync.success]: ({ state, action }) => {
    const adSetId = action.payload;
    const adSetIndex = state.campaign.adSets.findIndex((adSet) => adSet.id === adSetId);
    state.campaign.adSets[adSetIndex].isActive = false;

    state.campaign.adSets[adSetIndex].ads = state.campaign.adSets[adSetIndex].ads.map((ad) => {
      return {
        ...ad,
        isActive: false,
      };
    });
  },

  [activateBasicAdSponsorshipFromCreationAsync.success]: ({ state, action }) => {
    const adSetId = action.payload.adSetId;
    const adId = action.payload.adId;

    const adSetIndex = state.campaign.adSets.findIndex((adSet) => adSet.id === adSetId);
    const adIndex = state.campaign.adSets[adSetIndex].ads.findIndex((ad) => ad.id === adId);

    state.campaign.isActive = true;
    state.campaign.adSets[adSetIndex].isActive = true;
    state.campaign.adSets[adSetIndex].ads[adIndex].isActive = true;
  },

  [deactivateBasicAdSponsorshipFromCreationAsync.success]: ({ state, action }) => {
    const adSetId = action.payload.adSetId;
    const adId = action.payload.adId;

    const adSetIndex = state.campaign.adSets.findIndex((adSet) => adSet.id === adSetId);
    const adIndex = state.campaign.adSets[adSetIndex].ads.findIndex((ad) => ad.id === adId);

    state.campaign.adSets[adSetIndex].ads[adIndex].isActive = false;
  },

  [uploadBasicAdVideoAsync.success]: ({ state, action }) => {
    const adSetId = action.payload.adSetId;
    const adId = action.payload.adId;
    const adSetIndex = state.campaign.adSets.findIndex((adSet) => adSet.id === adSetId);
    let adIndex = state.campaign.adSets[adSetIndex].ads.findIndex((ad) => ad.id === adId);

    adIndex = adIndex >= 0 ? adIndex : state.campaign.adSets[adSetIndex].ads.length - 1;

    state.campaign.adSets[adSetIndex].ads[adIndex].isVideoUploaded = true;
  },

  [setBasicAdCampaignIsPublished]: ({ state }) => {
    state.campaign = {
      ...state.campaign,
      isDraft: false,
      deliveryStatus: 'scheduled',
      adSets: state.campaign.adSets.map((adSet) => ({
        ...adSet,
        isDraft: !adSet.isCreated || (adSet.ads.length === 1 && !adSet.ads[0].isCreated),
        ads: adSet.ads.map((ad) => ({
          ...ad,
          isDraft: !ad.isCreated,
        })),
      })),
    };

    state.campaignSummary = {
      ...state.campaignSummary,
      isDraft: false,
      adSets: state.campaignSummary.adSets.map((adSet) => ({
        ...adSet,
        isDraft: !adSet.isCreated || (adSet.ads.length === 1 && !adSet.ads[0].isCreated),
        ads: adSet.ads.map((ad) => ({
          ...ad,
          isDraft: !ad.isCreated,
        })),
      })),
    };
  },
});
