import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { T, useT } from '@transifex/react';

import { Button } from '../../../common/Button';
import { FileSpecifications } from '../../../common/FileSpecifications';
import { UploadZone } from '../../../common/UploadZone';
import { SectionSubtitle } from '../../../common/Titles/SectionSubtitle';
import { SectionTitle } from '../../../common/Titles/SectionTitle';

import { FormGroup } from '../../../FormElements/FormGroup';
import { TextInput } from '../../../FormElements/TextInput';
import { RadioWithTile } from '../../../FormElements/RadioWithTile';

import { ModalCrop } from '../../../ModalCrop';

import { base64StringToFile, extractImageFileExtension } from '../../../../utils/media';

import { useScroll } from '../../../../hooks/useScroll';

import s from './CampaignAndBrand.module.scss';

import { constructTestId } from '../../../../utils/test-ids/';
import { CREATE_CAMPAIGN_PAGE } from '../../../../constants/test-ids/create-campaing-sections/create-campaign-sections';
import { SuggestionsTextInput } from '../../../FormElements/SuggestionsTextInput';
import { Link } from 'react-router-dom';
import { routes } from '../../../../constants';
import { CustomSelect } from '../../../FormElements/Selects/CustomSelect';
import { brandInfoSelector, charitiesCategorySelector } from '../../../../store/brand';
import { Controller, useWatch } from 'react-hook-form';
import { setCampaignName } from '../../../../store/campaign/campaign';
import { Switch } from '../../../FormElements/Switch';
import { SvgIcon } from '../../../common/SvgIcon';
import useIdentifyCampaignType from '../../../../hooks/useIdentifyCampaignType/useIdentifyCampaignType';
import { setBasicAdName } from '../../../../store/basicAd/campaign';

const SECTION = CREATE_CAMPAIGN_PAGE.CAMPAIGN_AND_BRAND;

const CampaignAndBrand = ({
  campaignDraft,
  campaignConstants,
  modals,
  previousCampaignNames,
  toggleModalByName,
  setValue,
  watch,
  register,
  control,
  errors,
  trigger,
  currentBrandLogoUri,
  processingBrandLogo,
  setCurrentBrandLogoUri,
  setProccessingBrandLogo,
  saving,
  shouldSave,
  handleCampaignActivation,
  campaignIsLocked,
  dirtyFieldsList,
  fetchMoreCampaignNames,
  moreCampaignNamesToFetch,
  setNamesKeyword,
}) => {
  const t = useT();
  const dispatch = useDispatch();

  const areasOfImpact = useSelector(charitiesCategorySelector);

  const campaignNameRef = useRef(null);
  const objectiveRef = useRef(null);
  const charityRef = useRef(null);

  const watchCampaignName = useWatch({ control, name: 'name' });
  const watchBrandName = useWatch({ control, name: 'campaignBrandName' });
  const watchCategory = useWatch({ control, name: 'category' });
  const watchCharityCategory = useWatch({ control, name: 'charityCategory' });
  const watchCharity = useWatch({ control, name: 'selectedCharity' });
  const brandInfo = useSelector(brandInfoSelector);

  useScroll([campaignNameRef], 0.5);
  useScroll([objectiveRef], 0.8, '-300px');
  useScroll([charityRef], 0.8);

  useEffect(() => {
    if (watchCharity?.id) {
      const charityCategory = areasOfImpact?.find((charityCategory) => {
        const selectedCharity = charityCategory?.data.find(
          (charity) => charity.id === watchCharity.id
        );
        if (selectedCharity) setValue('selectedCharity', selectedCharity);
        return selectedCharity;
      });

      if (charityCategory) setValue('charityCategory', charityCategory);
    }
  }, [dispatch, areasOfImpact, setValue, watch, watchCharity?.id, watchCharityCategory?.value]);

  const cropperRef = useRef(null);

  const handleCloseCropModal = useCallback(() => {
    dispatch(toggleModalByName({ name: 'crop', value: false }));
  }, [dispatch, toggleModalByName]);

  const handleOpenCropModal = useCallback(() => {
    dispatch(toggleModalByName({ name: 'crop', value: true }));
  }, [dispatch, toggleModalByName]);

  const handleCropImage = useCallback(() => {
    const cropper = cropperRef?.current.cropper;

    const cropperData = cropper.getCroppedCanvas().toDataURL();
    const fileExtension = extractImageFileExtension(cropperData);
    const fileName = `campaignLogo${campaignDraft.name}-cropped.${fileExtension}`;
    const croppedFile = base64StringToFile(cropperData, fileName);
    const croppedUrl = URL.createObjectURL(croppedFile);

    setValue('campaignBrandLogoFile', croppedFile);
    setValue('campaignBrandLogoUri', croppedUrl);
    trigger('campaignBrandLogoUri');
    trigger('campaignBrandLogoFile');

    setCurrentBrandLogoUri(croppedUrl);

    handleCloseCropModal();
  }, [campaignDraft.name, setValue, trigger, setCurrentBrandLogoUri, handleCloseCropModal]);

  const handleChangeCategory = useCallback(
    (e) => {
      const { value } = e.target;

      const category = campaignConstants.objectives.filter(
        (objective) => objective.value === value
      )[0];

      setValue('category', category);
    },
    [campaignConstants.objectives, setValue]
  );

  const handleChangeBrandLogo = useCallback(
    (acceptedFiles) => {
      const campaignBrandLogoUri = URL.createObjectURL(acceptedFiles[0]);

      setProccessingBrandLogo(campaignBrandLogoUri);

      handleOpenCropModal();
    },
    [handleOpenCropModal, setProccessingBrandLogo]
  );
  const handleSelectCharityCategory = useCallback(() => {
    if (watchCategory) {
      setValue('selectedCharity', '');
    }
  }, [watchCategory, setValue]);

  const handleCharityModal = useCallback(() => {
    dispatch(toggleModalByName({ name: 'charity', value: true }));
  }, [dispatch, toggleModalByName]);

  const { isBasicAd } = useIdentifyCampaignType();

  return (
    <div className={s['campaign']}>
      <div>
        {campaignDraft.isDirty && (
          <div className={s['campaign__unsaved']}>
            <div className={s['campaign__unsaved-message']}>
              <SvgIcon name="important" />
              <span>{t('You have unsaved changes')}</span>
            </div>
            <ul>{dirtyFieldsList}</ul>
          </div>
        )}
        <div className={s['campaign__title']}>
          <SectionTitle>
            <h3>{t('Campaign')}</h3>
            <Switch
              disabled={!campaignDraft.isCreated}
              menuSwitch
              onChange={handleCampaignActivation}
              name="campaignStatus"
              id={campaignDraft.id}
              checked={campaignDraft.isActive}
            />
          </SectionTitle>
        </div>
        <SectionSubtitle>
          {t('Define your objective and set a name for your campaign')}
        </SectionSubtitle>
      </div>
      <div className={s['campaign__name']} ref={campaignNameRef} id="campaign-name">
        <FormGroup
          title={t('Campaign Name')}
          subtitle={t('Enter a name to identify this campaign')}
        >
          <SuggestionsTextInput
            type="text"
            id="campaign-name"
            name="name"
            label={t('CAMPAIGN NAME')}
            data-testid={constructTestId(SECTION, 'campaign-name-input')}
            register={register}
            onChange={(name, value) => {
              setValue(name, value);
              setNamesKeyword(value);
            }}
            reduxAction={(value) =>
              dispatch(isBasicAd ? setBasicAdName(value) : setCampaignName(value))
            }
            error={errors.name}
            disabled={campaignIsLocked}
            data={previousCampaignNames}
            fetchMoreData={fetchMoreCampaignNames}
            hasMoreData={moreCampaignNamesToFetch}
            watchValue={watchCampaignName}
            trigger={trigger}
          />
        </FormGroup>
        <FormGroup title={t('Brand Name')} subtitle={t('Enter your brand name')}>
          <TextInput
            type="text"
            id="brand-name"
            name="campaignBrandName"
            label={t('BRAND NAME')}
            data-testid={constructTestId(SECTION, 'brand-name-input')}
            register={register}
            error={errors.campaignBrandName}
            disabled={campaignIsLocked}
            watchValue={watchBrandName || brandInfo.brandName}
          />
        </FormGroup>
      </div>
      <div className={s['campaign__brand-logo']}>
        <FormGroup title={t('Brand Logo')} subtitle={t('Upload or edit your brand logo')}>
          <div ref={objectiveRef} id="objective" className={s['upload-container']}>
            <Controller
              control={control}
              name="campaignBrandLogoUri"
              render={({ field: { ref } }) => {
                return (
                  <UploadZone
                    inputRef={ref}
                    data-testid={constructTestId(SECTION, 'upload-brand-image')}
                    styleType="circle"
                    name="campaignBrandLogoUri"
                    control={control}
                    type="image"
                    error={errors.campaignBrandLogoUri}
                    handleChange={handleChangeBrandLogo}
                    contentType="image/jpeg, image/png"
                    fileUrl={currentBrandLogoUri}
                    disabled={campaignIsLocked}
                  />
                );
              }}
            />

            <input
              type="file"
              hidden
              name="campaignBrandLogoFile"
              {...register('campaignBrandLogoFile')}
            ></input>
            <FileSpecifications fileSpecs={campaignConstants.brandLogoFileSpecs} />
          </div>
        </FormGroup>
      </div>

      <div className={s['campaign__objective']}>
        <FormGroup
          title={t('Objective')}
          subtitle={t(
            'Select your primary objective from the options below to optimise your campaign creation flow'
          )}
        >
          <div className={s['campaign__objective-inner']}>
            {campaignConstants.objectives.map((o) => (
              <RadioWithTile
                data-testid={constructTestId(SECTION, `objective-${o.title}`)}
                name="category"
                type="radio"
                onChange={handleChangeCategory}
                icon={o.icon}
                key={o.title}
                title={t(o.title)}
                checked={watchCategory}
                value={t(o.value)}
                subtitle={t(o.subtitle)}
                disabled={campaignIsLocked}
                register={register}
              />
            ))}
          </div>
        </FormGroup>
      </div>
      {(watchCategory === 'conversions' || watchCategory === 'traffic') && (
        <FormGroup
          title="8Pixel"
          subtitle={
            <T
              _str={
                watchCategory === 'traffic'
                  ? 'You can use the 8Pixel to track visits to your website. You will find more information at a later step. Read more about the pixel in the {link}'
                  : 'You can use the 8Pixel to track visits and specific user actions on your website. You will find more information at a later step. Read more about the pixel in the {link}'
              }
              link={
                <Link
                  to={routes.SETTINGS_PIXEL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={s['pixel-doc-link']}
                >
                  <T _str="documentation page" />.
                </Link>
              }
            />
          }
        ></FormGroup>
      )}
      <div ref={charityRef} id="charity">
        <div className={s['charity']}>
          <FormGroup
            title={t('Select Charity')}
            subtitle={t(
              'We deliver results against your full media budget, but 5% of your campaign budget is donated to the charity of your choice. This creates a very positive halo effect.'
            )}
            required
          >
            <div className={s['charity__inn']}>
              <div className={s['charity__inn-section']}>
                <div className={s['title']}>{t('CHOOSE AREA OF IMPACT')}</div>
                <CustomSelect
                  inputId={constructTestId(SECTION, 'area-of-impact-select')}
                  control={control}
                  name="charityCategory"
                  watchValue={watchCharityCategory}
                  handleSelect={handleSelectCharityCategory}
                  options={areasOfImpact}
                  placeholder={t('choose area of impact')}
                  disabled={campaignIsLocked}
                />
              </div>
              <div className={s['charity__inn-section']}>
                <div className={s['title']}>{t('CHOOSE CHARITY')}</div>
                <CustomSelect
                  inputId={constructTestId(SECTION, 'charity-select')}
                  control={control}
                  name="selectedCharity"
                  watchValue={watchCharity}
                  options={watchCharityCategory?.data}
                  placeholder={t('choose charity')}
                  error={errors.selectedCharity}
                  charitiesSelect
                  disabled={campaignIsLocked}
                />

                <div className={s['add']}>
                  {t("Looking for a specific charity and can't find it? You can add your own")}
                  <span
                    className={s['popup']}
                    onClick={handleCharityModal}
                    data-testid={constructTestId(SECTION, 'add-new-charity')}
                  >
                    {t('here')}
                  </span>
                  .
                </div>
              </div>
            </div>
          </FormGroup>
        </div>
      </div>
      <div className={s['campaign__buttons']}>
        <Button
          data-testid={constructTestId(SECTION, 'continue-button')}
          type="submit"
          name={t('Save and Continue')}
          styling="primary"
          disabled={saving || !shouldSave}
        />
      </div>
      {modals.crop.isOpen && (
        <ModalCrop
          imageUri={processingBrandLogo}
          cropperRef={cropperRef}
          actionCallback={handleCropImage}
          closeCallback={handleCloseCropModal}
        />
      )}
    </div>
  );
};

export default CampaignAndBrand;
