import React, { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useWatch } from 'react-hook-form';
import { isNaN } from 'lodash';
import DatePicker from 'react-datepicker';
import { addDays, isPast } from 'date-fns';
import { T, useT } from '@transifex/react';

import { useScroll } from '../../../../hooks/useScroll';

import { RegularCampaign } from './RegularCampaign';

import { SvgIcon } from '../../../common/SvgIcon';

import { FormGroup } from '../../../FormElements/FormGroup';

import { Modal } from '../../../Modal';
import { NewBudgetAllocation } from '../../../ModalsContent/NewBudgetAllocation';

import { sponsorshipSetConstants } from '../../../../constants';

import { budgetParser } from '../../../../utils/budget';
import { setInputDateFormat } from '../../../../utils/receivedData';

import s from './TimingAndBudget.module.scss';

import { constructTestId } from '../../../../utils/test-ids/';
import { CREATE_CAMPAIGN_PAGE } from '../../../../constants/test-ids/create-campaing-sections/create-campaign-sections';
import { toggleModalByName } from '../../../../store/ui';
import {
  defaultEndDate,
  defaultStartDate,
  formatToBrandsTimezone,
  removeTimezoneOffset,
} from '../../../../utils/date/dateHelpers';
import { useRef } from 'react';

const SECTION = CREATE_CAMPAIGN_PAGE.TIMING_AND_BUDGET;

const TimingAndBudget = ({
  campaignId,
  brandInfo,
  modals,
  sponsorshipSetDraft,
  videoAdPricingCheckboxes,
  control,
  errors,
  register,
  setValue,
  trigger,
  timingRef,
  campaignCreate,
  adSetIsLocked,
  clearErrors,
}) => {
  const t = useT();
  const dispatch = useDispatch();

  const watchBudget = useWatch({ control, name: 'budget' });
  const watchStartDate = useWatch({ control, name: 'startDate' }).toString();
  const watchEndDate = useWatch({ control, name: 'endDate' }).toString();
  const watchIncentive = useWatch({ control, name: 'incentive' });

  const publishedStartDate = sponsorshipSetDraft.differences?.find(
    (diff) => diff.modifiedField === 'ScheduleFrom'
  )?.publishedValue;

  const initialStartDate = useRef(null);
  initialStartDate.current = publishedStartDate ?? sponsorshipSetDraft.startDate;
  const initialEndDate = useRef(null);
  initialEndDate.current = sponsorshipSetDraft.endDate;

  const { countryCode } = brandInfo;

  useScroll([timingRef], 0.85);

  const handleSetDateStart = (date, onChange, trigger) => {
    const value = date
      ? new Date(date).toISOString().toString()
      : defaultStartDate().toISOString().toString();

    onChange(value);

    trigger(['startDate', 'endDate']);
  };

  const handleSetDateEnd = (date, onChange, trigger) => {
    const value = date
      ? new Date(date).toISOString().toString()
      : defaultEndDate().toISOString().toString();

    onChange(value);
    trigger(['startDate', 'endDate']);
  };

  const handleSubmitNewBudgetAllocation = useCallback(
    (form) => {
      const { charityDonation, netCashReward, weAre8Fee } = form;
      setValue(
        'incentive',
        {
          charityDonation: +charityDonation,
          netCashReward: +netCashReward,
          pricePerMessage: +weAre8Fee + +netCashReward + +charityDonation,
          weAre8Fee: +weAre8Fee,
        },
        { shouldDirty: true }
      );
      dispatch(toggleModalByName({ name: 'budgetAllocation', value: false }));
    },
    [dispatch, setValue]
  );

  const handleCancelNewBudgetAllocation = useCallback(() => {
    dispatch(toggleModalByName({ name: 'budgetAllocation', value: false }));
  }, [dispatch]);

  const handleBudgetAllocationModal = useCallback(() => {
    dispatch(toggleModalByName({ name: 'budgetAllocation', value: true }));
  }, [dispatch]);

  const isBudgetValid = useMemo(() => {
    const budget = +budgetParser(watchBudget);
    return typeof budget === 'number' && !isNaN(budget);
  }, [watchBudget]);

  const formattedMinDate = useMemo(() => removeTimezoneOffset(new Date()), []);

  const filterPassedTime = (time) => {
    const currentDate = formatToBrandsTimezone(new Date(), brandInfo.timezoneId);
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <div className={s['sponsor-set']}>
      <div className={s['sponsor-set__inner']}>
        <div className={s['timing']} ref={timingRef} id="timing">
          <FormGroup title={t('Campaign Timing')} required>
            <div className={s['timing__subtitle']}>
              <T
                _str="Select the start and end dates for your campaign. {campaignText}"
                campaignText={<strong>{t('Campaigns must run for a minimum of 2 days.')}</strong>}
              />
            </div>
            <div className={s['timing__area']}>
              <div className={s['timing__area-input']}>
                <Controller
                  control={control}
                  name="startDate"
                  defaultValue={sponsorshipSetDraft.startDate}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <div className={s['timing__date-from-wrap']}>
                        <DatePicker
                          placeholderText={t('Select start date')}
                          selected={value && new Date(value)}
                          onChange={(date) => handleSetDateStart(date, onChange, trigger)}
                          className={
                            errors.startDate
                              ? 'react-datepicker__custom-input-error'
                              : 'react-datepicker__custom-input'
                          }
                          closeOnScroll={true}
                          timeIntervals={15}
                          showTimeSelect
                          showYearDropdown
                          dateFormat={setInputDateFormat(countryCode)}
                          minDate={new Date(formattedMinDate)}
                          selectsStart
                          filterTime={filterPassedTime}
                          startDate={new Date(value)}
                          endDate={watchEndDate && new Date(watchEndDate)}
                          disabled={
                            isPast(new Date(initialStartDate.current)) &&
                            !sponsorshipSetDraft.isDraft
                          }
                          customInput={
                            <input
                              data-testid={constructTestId(SECTION, 'start-date')}
                              type="text"
                            />
                          }
                        />
                        <SvgIcon name="date-picker" />
                        {errors.startDate && (
                          <p className={s['timing__error']}>{errors.startDate.message}</p>
                        )}
                      </div>
                    );
                  }}
                />
              </div>
              <div className={s['timing__area-range']}>{t('to')}</div>
              <div className={s['timing__area-input']}>
                <Controller
                  control={control}
                  name="endDate"
                  defaultValue={sponsorshipSetDraft.endDate}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <div className={s['timing__date-to-wrap']}>
                        <DatePicker
                          placeholderText={t('Select end date')}
                          selected={value && new Date(value)}
                          onChange={(date) => handleSetDateEnd(date, onChange, trigger)}
                          className={
                            errors.endDate
                              ? 'react-datepicker__custom-input-error'
                              : 'react-datepicker__custom-input'
                          }
                          selectsEnd
                          startDate={watchStartDate && new Date(watchStartDate)}
                          endDate={new Date(value)}
                          filterTime={(time) =>
                            filterPassedTime(
                              !sponsorshipSetDraft.isDraft ? new Date(initialEndDate.current) : time
                            )
                          }
                          minDate={
                            !sponsorshipSetDraft.isDraft
                              ? new Date(initialEndDate.current)
                              : watchStartDate &&
                                addDays(
                                  new Date(watchStartDate),
                                  sponsorshipSetConstants.minimumCampaignDurationInDays
                                )
                          }
                          dateFormat={setInputDateFormat(countryCode)}
                          timeIntervals={15}
                          closeOnScroll={true}
                          showTimeSelect
                          showYearDropdown
                          maxDate={addDays(new Date(), 200)}
                          customInput={
                            <input data-testid={constructTestId(SECTION, 'end-date')} type="text" />
                          }
                        />
                        <SvgIcon name="date-picker" />
                        {errors.endDate && (
                          <p className={s['timing__error']}>{errors.endDate.message}</p>
                        )}
                      </div>
                    );
                  }}
                />
              </div>
            </div>
          </FormGroup>
        </div>

        <RegularCampaign
          campaignId={campaignId}
          brandInfo={brandInfo}
          sponsorshipSetDraft={sponsorshipSetDraft}
          control={control}
          isBudgetValid={isBudgetValid}
          handleBudgetAllocationModal={handleBudgetAllocationModal}
          errors={errors}
          register={register}
          videoAdPricingCheckboxes={videoAdPricingCheckboxes}
          setValue={setValue}
          watchBudget={watchBudget}
          watchIncentive={watchIncentive}
          watchEndDate={watchEndDate}
          watchStartDate={watchStartDate}
          charityName={campaignCreate?.selectedCharity?.name}
          adSetIsLocked={adSetIsLocked}
        />
        {brandInfo.role === 'WeAre8' && modals.budgetAllocation.isOpen && (
          <Modal
            title={t('Edit Fund Distribution')}
            styling="primary"
            closeModal={handleCancelNewBudgetAllocation}
            withSubmit
          >
            <NewBudgetAllocation
              brandInfo={brandInfo}
              watchIncentive={watchIncentive}
              handleSubmitNewBudgetAllocation={handleSubmitNewBudgetAllocation}
              customButtonText={t('Save distribution amounts')}
              data-testid={SECTION}
            />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default TimingAndBudget;
