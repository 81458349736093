import { T, useT } from '@transifex/react';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { routes, sponsorshipSetConstants } from '../../../../../constants';
import { campaignSelector } from '../../../../../store/data';
import {
  amountToCurrency,
  calculateDailyBudget,
  stringToNumber,
} from '../../../../../utils/budget';
import { calculateCampaignDuration } from '../../../../../utils/date/dateHelpers';
import { setBrandDateFormat } from '../../../../../utils/receivedData';
import { SvgIcon } from '../../../../common/SvgIcon';
import { NestedSummary } from '../NestedSummary';

import { isChanged } from '../../../../../utils/getSummaryChanges';
import s from '../Summary.module.scss';

function AdSetSummary({ adSet, brandInfo, children }) {
  const t = useT();
  const {
    locations,
    interests,
    passions,
    includeTags,
    excludeTags,
    deviceType,
    gender,
    age,
    budget,
    startDate,
    endDate,
    isUnite,
    isEightPixelEnabled,
    pixelConversionEvent,
    differences,
    frequencyCap,
  } = adSet;
  const campaign = useSelector(campaignSelector);

  const campaignDuration = useMemo(
    () => calculateCampaignDuration(startDate, endDate),
    [startDate, endDate]
  );

  const modifiedInterests = useMemo(() => {
    return interests?.map((interest) => interest.label).join(', ');
  }, [interests]);

  const modifiedPassions = useMemo(() => {
    return passions?.map((passions) => passions.label).join(', ');
  }, [passions]);

  const modifiedIncludedTags = useMemo(() => {
    return includeTags?.map((interest) => interest.label).join(', ');
  }, [includeTags]);

  const modifiedExcludedTags = useMemo(() => {
    return excludeTags?.map((interest) => interest.label).join(', ');
  }, [excludeTags]);

  const modifiedAges = useMemo(() => {
    if (age?.includes('all_age')) return t('All');
    else {
      const agesToDisplay = age
        ?.map((item) => {
          item = item.replaceAll(',', '-');
          // Add + symbol to '65' option
          if (item === '65') item = item.concat('+');
          return item;
        })
        ?.sort((a, b) => {
          // Ascending order
          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
          return 0;
        })
        // Seperate by comma
        .join(', ');
      return agesToDisplay;
    }
  }, [age, t]);

  const modifiedGender = useMemo(() => {
    let genderData = sponsorshipSetConstants.genderData;

    genderData = genderData.filter((item) => gender?.includes(item.value));

    genderData = genderData.map((item) => item.label);

    return genderData.includes('All')
      ? genderData.filter((gender) => gender === 'All')
      : genderData.filter((gender) => gender !== 'All').join(', ');
  }, [gender]);

  const modifiedLocations = useMemo(() => {
    return locations?.map((location) => location.label).join(', ');
  }, [locations]);

  const modifiedStartDate = useMemo(() => {
    return startDate ? setBrandDateFormat(startDate, brandInfo.countryCode, true) : '-';
  }, [startDate, brandInfo.countryCode]);

  const modifiedEndDate = useMemo(() => {
    return endDate ? setBrandDateFormat(endDate, brandInfo.countryCode, true) : '-';
  }, [endDate, brandInfo.countryCode]);

  const modifiedDeviceType = useMemo(() => {
    return deviceType?.name ?? deviceType;
  }, [deviceType]);

  const hasAtLeasOneSavedAd = useMemo(() => {
    return campaign.adSets[0].ads.length >= 1 && campaign.adSets[0].ads.some((ad) => ad.isCreated);
  }, [campaign.adSets]);

  return (
    <>
      {hasAtLeasOneSavedAd && (
        <NestedSummary name={adSet.name} iconName="adset">
          <div className={s['adSet']}>
            <div className={s['summary__section']}>
              <div className={s['summary__section-title']}>{t('Targeting Summary')}</div>
              <div className={s['summary__section-content']}>
                <div className={s['summary__section-content-flex']}>
                  {isChanged(differences, 'AgeRanges') && (
                    <SvgIcon style={{ margin: 'auto' }} name="warning" />
                  )}
                  <span className={s['accented']}>{t('Age')}: </span>
                  {`${modifiedAges}`}
                </div>
                {locations?.length > 0 && (
                  <div className={s['summary__section-content-flex']}>
                    {isChanged(differences, 'IncludeLocations') && (
                      <SvgIcon style={{ margin: 'auto' }} name="warning" />
                    )}
                    <span className={s['accented']}>{t('Location(s)')}: </span>
                    {modifiedLocations || ''}
                  </div>
                )}
                {interests?.length > 0 && (
                  <div className={s['summary__section-content-flex']}>
                    {isChanged(differences, 'Interests') && (
                      <SvgIcon style={{ margin: 'auto' }} name="warning" />
                    )}
                    <span className={s['accented']}>{t('Interest(s)')}: </span>
                    <span className="capitalized">{modifiedInterests || ''}</span>
                  </div>
                )}
                {passions?.length > 0 && (
                  <div className={s['summary__section-content-flex']}>
                    {isChanged(differences, 'Passions') && (
                      <SvgIcon style={{ margin: 'auto' }} name="warning" />
                    )}
                    <span className={s['accented']}>{t('Passion(s)')}: </span>
                    <span className="capitalized">{modifiedPassions || ''}</span>
                  </div>
                )}
                {includeTags?.length > 0 && (
                  <div className={s['summary__section-content-flex']}>
                    {isChanged(differences, 'IncludeTags') && (
                      <SvgIcon style={{ margin: 'auto' }} name="warning" />
                    )}
                    <span className={s['accented']}>{t('Audience Tags Included')}: </span>
                    {modifiedIncludedTags || ''}
                  </div>
                )}
                {excludeTags?.length > 0 && (
                  <div className={s['summary__section-content-flex']}>
                    {isChanged(differences, 'ExcludeTags') && (
                      <SvgIcon style={{ margin: 'auto' }} name="warning" />
                    )}
                    <span className={s['accented']}>{t('Audience Tags Excluded')}: </span>
                    {modifiedExcludedTags || ''}
                  </div>
                )}
                <div className={s['summary__section-content-flex']}>
                  {isChanged(differences, 'Genders') && (
                    <SvgIcon style={{ margin: 'auto' }} name="warning" />
                  )}
                  <span className={s['accented']}>{t('Gender')}: </span>
                  {t(modifiedGender)}
                </div>
                {modifiedDeviceType && (
                  <div className={s['summary__section-content-flex']}>
                    {isChanged(differences, 'AudienceDeviceType') && (
                      <SvgIcon style={{ margin: 'auto' }} name="warning" />
                    )}
                    <span className={s['accented']}>{t('OS')}: </span>
                    {t(modifiedDeviceType)}
                  </div>
                )}
                {frequencyCap && (
                  <div className={s['summary__section-content-flex']}>
                    <span className={s['accented']}>{t('Frequency Cap')}: </span>
                    {frequencyCap}
                  </div>
                )}
              </div>
            </div>

            <div className={s['summary__section']}>
              <div className={s['summary__section-title']}>{t('Schedule')}</div>
              <div className={s['summary__section-content']}>
                <div className={s['summary__section-content-flex']}>
                  {isChanged(differences, 'ScheduleFrom') && (
                    <SvgIcon style={{ margin: 'auto' }} name="warning" />
                  )}
                  <span className={s['accented']}>{t('Start Date')}: </span>
                  <span style={{ flex: 1 }}>{modifiedStartDate}</span>
                </div>
                <div className={s['summary__section-content-flex']}>
                  {isChanged(differences, 'ScheduleTo') && <SvgIcon name="warning" />}
                  <span className={s['accented']}>{t('End Date')}: </span>
                  <span style={{ flex: 1 }}>{modifiedEndDate}</span>
                </div>
              </div>
            </div>
            {!isUnite && (
              <div className={s['summary__section']}>
                <div className={s['summary__section-title']}>{t('Budget')}</div>
                <div className={s['summary__section-content']}>
                  <div className={s['summary__section-content-flex']}>
                    {isChanged(differences, 'BudgetTotal') && (
                      <SvgIcon style={{ margin: 'auto' }} name="warning" />
                    )}
                    {t('{modifiedBudget} Total', {
                      modifiedBudget: amountToCurrency(stringToNumber(budget)),
                    })}
                  </div>
                  <div className={s['summary__section-content-block']}>
                    {t('{modifiedDailyBudget} Daily', {
                      modifiedDailyBudget: amountToCurrency(
                        calculateDailyBudget(campaignDuration, stringToNumber(budget))
                      ),
                    })}
                  </div>
                </div>
              </div>
            )}
            {isEightPixelEnabled &&
              (campaign.category.value === 'conversions' ||
                campaign.category.value === 'traffic') && (
                <div className={s['summary__section']}>
                  <div className={s['summary__section-title']}>8Pixel</div>
                  <div className={s['summary__section-content']}>
                    <div className={s['summary__section-content-block']}>
                      {campaign.category.value === 'conversions' &&
                      pixelConversionEvent !== 'unknown' ? (
                        <T
                          _str="The {pixel} is enabled and tracking {eventName} events for this campaign please make sure you set it up properly. Read more on the {link} for detailed instructions."
                          pixel="8Pixel"
                          link={
                            <Link
                              to={routes.SETTINGS_PIXEL}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={s['pixel-doc-link']}
                            >
                              <T _str="documentation" />
                            </Link>
                          }
                          eventName={
                            <span className={s['conversion-event-name']}>
                              {pixelConversionEvent}
                            </span>
                          }
                        />
                      ) : (
                        <T
                          _str="The 8Pixel is enabled for this campaign please make sure you set it up properly. Read more on the {link} for detailed instructions."
                          link={
                            <Link
                              to={routes.SETTINGS_PIXEL}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={s['pixel-doc-link']}
                            >
                              <T _str="documentation" />
                            </Link>
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
          </div>
          {children}
        </NestedSummary>
      )}
    </>
  );
}

export default AdSetSummary;
