import React, { useCallback, useMemo, useRef } from 'react';

import { useT } from '@transifex/react';
import { FormGroup } from '../../../../FormElements/FormGroup';
import { TextInput } from '../../../../FormElements/TextInput';

import { budgetFormatter, budgetParser, calculateDailyBudget } from '../../../../../utils/budget';

import s from './RegularCampaign.module.scss';

import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'react-use';
import { CREATE_CAMPAIGN_PAGE } from '../../../../../constants/test-ids/create-campaing-sections/create-campaign-sections';
import useIdentifyCampaignType from '../../../../../hooks/useIdentifyCampaignType/useIdentifyCampaignType';
import { useScroll } from '../../../../../hooks/useScroll';
import {
  calculateDailyBudgetAsync,
  dailyBudgetSelector,
  setDailyBudget,
} from '../../../../../store/basicAd/sponsorshipSet';
import { calculateCampaignDuration } from '../../../../../utils/date/dateHelpers';
import { constructTestId } from '../../../../../utils/test-ids/';
import Label from '../../../../FormElements/Label/Label';
import BasicCpm from './BasicCpm/BasicCpm';
import BasicDeliveryInfoAndAllocation from './BasicDeliveryInfoAndAllocation';
import DeliveryInfo from './DeliveryInfo';
import PremiumCpm from './PremiumCpm/PremiumCpm';
import PremiumDeliveryInfoAndAllocation from './PremiumDeliveryInfoAndAllocation';

const SECTION = CREATE_CAMPAIGN_PAGE.TIMING_AND_BUDGET;

const RegularCampaign = ({
  campaignId,
  brandInfo,
  sponsorshipSetDraft,
  control,
  errors,
  register,
  isBudgetValid,
  handleBudgetAllocationModal,
  videoAdPricingCheckboxes,
  setStickyMenuItem,
  setValue,
  watchBudget,
  watchStartDate,
  watchEndDate,
  watchIncentive,
  charityName,
  adSetIsLocked,
}) => {
  const dispatch = useDispatch();
  const basicAdSetDailyBudget = useSelector(dailyBudgetSelector);
  const budgetRef = useRef();
  const { isBasicAd } = useIdentifyCampaignType();

  useScroll([budgetRef], 0.85, setStickyMenuItem);

  const t = useT();
  const handleChangeIncentive = useCallback(
    (e, onChange) => {
      const { value } = e.target;

      const selectedIncentive = videoAdPricingCheckboxes.filter((option) => {
        return option.videoMaxTimeInSeconds.toString() === value;
      })[0];

      onChange(selectedIncentive);
    },
    [videoAdPricingCheckboxes]
  );

  const campaignDuration = useMemo(
    () => calculateCampaignDuration(watchStartDate, watchEndDate),
    [watchStartDate, watchEndDate]
  );

  useDebounce(
    () => {
      if (watchBudget && watchStartDate && watchEndDate && isBasicAd) {
        dispatch(
          calculateDailyBudgetAsync.request({
            budget: watchBudget,
            startDate: new Date(watchStartDate).toISOString(),
            endDate: new Date(watchEndDate).toISOString(),
            campaignId,
          })
        );
      } else dispatch(setDailyBudget('-'));
    },
    600,
    [watchBudget, watchEndDate, watchStartDate, isBasicAd]
  );

  const dailyBudget = useMemo(() => {
    return budgetFormatter(
      calculateDailyBudget(campaignDuration, watchBudget ? budgetParser(watchBudget.toString()) : 0)
    );
  }, [campaignDuration, watchBudget]);

  const selectedCustomIncentive = useMemo(
    () =>
      !videoAdPricingCheckboxes.find(
        (option) => option.videoMaxTimeInSeconds === watchIncentive?.videoMaxTimeInSeconds
      ),
    [watchIncentive, videoAdPricingCheckboxes]
  );

  return (
    <>
      <div>
        <div className={s['budget']} ref={budgetRef} id="budget">
          <FormGroup
            title={t('Total Budget')}
            subtitle={t('Define how much you would like to spend in this campaign.')}
            required
          >
            <TextInput
              data-testid={constructTestId(SECTION, 'total-budget-input')}
              type="text"
              id="budget"
              name="budget"
              label={`${brandInfo.country.currencyCode} ${brandInfo.country.currencySymbol}`}
              // formValue={budget && budgetFormatter(budget)}
              onChange={(value) => {
                setValue('budget', budgetFormatter(budgetParser(value)));
              }}
              watchValue={watchBudget}
              register={register}
              error={errors.budget}
            />
          </FormGroup>
        </div>
        <div className={s['budget']}>
          <FormGroup
            title={t('Daily Budget')}
            subtitle={t(
              'This is automatically calculated from your total budget and campaign start & end dates.'
            )}
          >
            <Label
              data-testid={constructTestId(SECTION, 'daily-budget-input')}
              type="text"
              id="dailyBudget"
              name="dailyBudget"
              value={
                isBasicAd
                  ? basicAdSetDailyBudget
                  : sponsorshipSetDraft.dailyBudget ||
                    sponsorshipSetDraft.budgetDaily ||
                    dailyBudget ||
                    '-'
              }
              label={`${brandInfo.country.currencyCode} ${brandInfo.country.currencySymbol}`}
              error={errors.dailyBudget}
            />
          </FormGroup>
        </div>
        <div className={s['video']}>
          {isBasicAd ? (
            <BasicCpm />
          ) : (
            <PremiumCpm
              control={control}
              videoAdPricingCheckboxes={videoAdPricingCheckboxes}
              adSetIsLocked={adSetIsLocked}
              handleChangeIncentive={handleChangeIncentive}
            />
          )}
        </div>
      </div>

      {isBasicAd ? (
        <BasicDeliveryInfoAndAllocation
          budget={isBudgetValid ? budgetFormatter(watchBudget) : '0'}
          isBudgetValid={isBudgetValid}
          brandCountryCode={brandInfo.countryCode}
          selectedCharityName={sponsorshipSetDraft.selectedCharity.name || charityName}
        />
      ) : (
        <PremiumDeliveryInfoAndAllocation
          budget={isBudgetValid ? budgetFormatter(watchBudget) : '0'}
          isBudgetValid={isBudgetValid}
          brandCountryCode={brandInfo.countryCode}
          incentive={watchIncentive}
          selectedCharityName={sponsorshipSetDraft.selectedCharity.name || charityName}
        />
      )}
    </>
  );
};

export default RegularCampaign;
