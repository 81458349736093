import { createAction, createAsyncAction } from 'eight.js.store-common';

// ASYNC

export const activateBasicAdFromCreationAsync = createAsyncAction(
  '@@basicAd/ACTIVATE_CAMPAIGN_FROM_CREATION'
);

export const deactivateBasicAdFromCreationAsync = createAsyncAction(
  '@@basicAd/DEACTIVATE_CAMPAIGN_FROM_CREATION'
);

export const archiveBasicAdAsync = createAsyncAction('@@basicAd/ARCHIVE_CAMPAIGN');

export const unarchiveBasicAdAsync = createAsyncAction('@@basicAd/UNARCHIVE_CAMPAIGN');

export const createBasicAdAsync = createAsyncAction('@@basicAd/CREATE_CAMPAIGN');

export const updateBasicAdAsync = createAsyncAction('@@basicAd/UPDATE_CAMPAIGN');

export const duplicateBasicAdAsync = createAsyncAction('@@basicAd/DUPLICATE_CAMPAIGN');

export const submitBasicAdNewCharityAsync = createAsyncAction(
  '@@basicAdSponsorshipSet/SUBMIT_NEW_CHARITY'
);

// SYNC

export const clearBasicAdDraft = createAction('@@basicAd/CLEAR_CAMPAIGN_DRAFT');

export const toggleActivateBasicAd = createAction('@@basicAd/TOGGLE_CAMPAIGN_ACTIVE');

export const setBasicAdData = createAction('@@basicAd/SET_CAMPAIGN_DATA');

export const setBasicAdFetching = createAction('@@basicAd/SET_FETCHING');

export const setBasicAdIsReadOnly = createAction('@@basicAd/SET_READ_ONLY');

export const setBasicAdEditId = createAction('@@basicAd/SET_CAMPAIGN_EDIT_ID');

export const setActiveBasicAd = createAction('@@basicAd/SET_ACTIVE_CAMPAIGN');

export const setBasicAdName = createAction('@@basicAd/SET_CAMPAIGN_NAME');
