import { createSelector } from 'reselect';

const basicAdSponsorshipSetStateSelector = (state) => state.basicAdSponsorshipSet;

export const basicAdSponsorshipSetCreateSelector = createSelector(
  basicAdSponsorshipSetStateSelector,
  (state) => state.sponsorshipSetCreate
);

export const basicAdSponsorshipSetEditSelector = createSelector(
  basicAdSponsorshipSetStateSelector,
  (state) => state.sponsorshipSetEdit
);

export const basicAdSponsorshipSetEditIdSelector = createSelector(
  basicAdSponsorshipSetEditSelector,
  (state) => state.id
);

export const dailyBudgetSelector = createSelector(
  basicAdSponsorshipSetStateSelector,
  (state) => state.dailyBudget
);
