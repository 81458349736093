import React from 'react';

import s from './BudgetAllocation.module.scss';
import {
  budgetFormatter,
  carbonOffsetInTones,
  isEntryNaN,
  numberToCurrency,
  percentageOfBudget,
  stringToNumber,
} from '../../../../../../utils/budget';
import { T, useT } from '@transifex/react';
import { CARBON_OFFSET_IMPACT_PERCENTAGE } from '../../../../../../constants/values';
import { InfoCard } from '../../../../../../components/common/InfoCard';

const BudgetAllocation = ({
  hasValidBudget,
  budgetData,
  pricing,
  selectedCharityName,
  brandCountryCode,
}) => {
  const t = useT();
  return (
    <div className={s['allocation']}>
      <InfoCard>
        <div className={s['allocation__title']}>{t('Budget Allocation:')}</div>
        <div className={s['allocation__content']}>
          <span className={s['bold']}>{t('8 Citizen Payments:')}</span>
          {hasValidBudget
            ? ` 
                    ${budgetFormatter(budgetData?.guarantied)} x ${
                isEntryNaN(pricing.userReward) ? pricing.userReward : ''
              } = ${numberToCurrency(budgetData.payments)}
                     ${t('direct payments to 8Citizens.')}`
            : ''}
        </div>
        <div className={s['allocation__content']}>
          <span className={s['bold']}>{t('Charity Donations')}:</span>
          {hasValidBudget
            ? ` 
                    ${budgetFormatter(budgetData?.guarantied)} x ${
                isEntryNaN() ? +pricing?.charityDonation : '-'
              } = ${numberToCurrency(budgetData?.donations)}
                     ${t('donated to your selected charity')} - ${selectedCharityName}.`
            : ''}
        </div>
        <div className={s['allocation__content']}>
          <span className={s['bold']}>{t('Distribution Costs: ')}</span>
          {hasValidBudget ? (
            <>
              {budgetFormatter(budgetData.guarantied)} x{' '}
              {isEntryNaN(pricing.weAre8Fee) ? +pricing?.weAre8Fee : '-'} ={' '}
              {numberToCurrency(budgetData.distributions)} WeAre8 fee
              {t(
                ' (covers mobile partnerships, media partnerships, targeting engine, reporting, user and content moderation, data security, payment infrastructure).'
              )}
              <b>
                {t(' This includes ')}
                {numberToCurrency(
                  percentageOfBudget(
                    CARBON_OFFSET_IMPACT_PERCENTAGE,
                    stringToNumber(budgetData.total)
                  )
                )}
                {t(' contribution to offset ')}
                {carbonOffsetInTones(stringToNumber(budgetData.total), brandCountryCode)}T
                {t(' of carbon via ')}
                <a href="https://ecologi.com/">Ecologi</a>
                {t(
                  " project. Making this campaign carbon negative and reducing your company's carbon footprint!"
                )}
              </b>
            </>
          ) : (
            ''
          )}
        </div>
        <div className={s['allocation__total']}>
          <T
            src="Total : {total}"
            total={hasValidBudget ? numberToCurrency(budgetData.total) : ''}
          />
        </div>
      </InfoCard>
    </div>
  );
};

export default BudgetAllocation;
