import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { brandConstants, routes } from '../../constants';
import {
  getModifiedBillsData,
  processReceivedText,
  setBrandDateFormat,
} from '../../utils/receivedData';
import { saveIntoSessionStorage } from '../../utils/sessionStorage';

import {
  chargePaymentMethodAsync,
  fetchAccountBalanceAsync,
  fetchBasicAdPricingAsync,
  fetchBasicCampaignNamesAsync,
  fetchBasicSponsorshipSetNamesAsync,
  fetchBrandAsync,
  fetchCampaignNamesAsync,
  fetchCharitiesAsync,
  fetchCountriesAsync,
  fetchDefaultBasicAdPriceCardAsync,
  fetchIncentivesAsync,
  fetchInterestsAsync,
  fetchLocationsAsync,
  fetchPassionsAsync,
  fetchPixelStatsAsync,
  fetchSponsorshipNamesAsync,
  fetchSponsorshipSetNamesAsync,
  fetchTagsAsync,
  fetchTestPixelEventsAsync,
  fetchUniqueBasicCampaignNamesAsync,
  fetchUniqueBasicSponsorshipNamesAsync,
  fetchUniqueBasicSponsorshipSetNamesAsync,
  fetchUniqueCampaignNamesAsync,
  fetchUniqueSponsorshipNamesAsync,
  fetchUniqueSponsorshipSetNamesAsync,
  getBacsCheckoutSessionIdAsync,
  getBrandBillsAsync,
  getBrandTransactionsAsync,
  getBrandsLastChargeAsync,
  getCardSetupIntentKeyAsync,
  getPaymentMethodsAsync,
  getVatAndFeeAsync,
  makeMethodPrimaryAsync,
  removePaymentMethodAsync,
  resetPasswordAsync,
  sendPaymentCardAsync,
  sendResetPasswordEmailAsync,
  setBrandCountryInfo,
  setBrandData,
  setBrandInvoicePaid,
  setBrandIsLoading,
  setDefaultVatAndFee,
  setInvoicePartnerRequestAsync,
  setIsPixelTestInProgress,
  setStripeChargeError,
  toggleIsBrandInfoSaved,
  updateAccountInfo,
  updateBrandBills,
  updateBrandInfoFromBillingAsync,
} from './actions';

import { container } from '../../container';

import { t } from '@transifex/native';
import { nanoid } from 'nanoid';
import { formatToBrandsTimezone } from '../../utils/date/dateHelpers';
import { setCustomAudiencesTags } from '../campaign/sponsorshipSet';
import { publishCampaignAsync, updateBrandInfoFromCampaignSummaryAsync } from '../campaign/summary';
import { setModalDisplayMode, setModalText, toggleModalByName, toggleSavingToast } from '../ui';
import {
  brandBillsSelector,
  brandInfoSelector,
  isPixelTestInProgressSelector,
  paymentMethodsSelector,
  vatAndFeeSelector,
} from './selectors';

const brandService = container.get('BrandService');

function* fetchBrand({ payload }) {
  try {
    yield put(setBrandIsLoading(true));
    const response = yield call(brandService.fetchBrand, payload);

    yield put(fetchBrandAsync.success(response));
  } catch (err) {
    yield put(fetchBrandAsync.failure(err));
  } finally {
    yield put(setBrandIsLoading(false));
  }
}

function* fetchBasicAdPricingSaga() {
  try {
    let response = yield call(brandService.getBasicAdPricing);

    yield put(fetchBasicAdPricingAsync.success(response));
  } catch (error) {
    yield put(fetchBasicAdPricingAsync.failure(error.message));
    console.log(error);
  }
}

function* fetchIncentivesSaga() {
  try {
    const { samiPricingId } = yield select(brandInfoSelector);

    let incentivesResponse = yield call(brandService.getPricing, samiPricingId);

    incentivesResponse = incentivesResponse.sort(
      (a, b) => a.videoMaxTimeInSeconds - b.videoMaxTimeInSeconds
    );

    yield put(fetchIncentivesAsync.success(incentivesResponse));
  } catch (error) {
    yield put(fetchIncentivesAsync.failure(error.message));
    console.log(error);
  }
}

function* fetchAccountBalanceSaga() {
  try {
    const response = yield call(brandService.fetchAccountBalance);

    yield put(fetchAccountBalanceAsync.success(response));
  } catch (error) {
    yield put(fetchAccountBalanceAsync.failure(error));
  }
}

function* getCharities() {
  try {
    const { countryCode } = yield select(brandInfoSelector);
    const response = yield call(brandService.fetchCharities, countryCode);
    yield put(fetchCharitiesAsync.success(response));
  } catch (error) {
    yield put(fetchCharitiesAsync.failure(error));
  }
}

function* getInterests() {
  try {
    const response = yield call(brandService.fetchInterests);

    yield put(fetchInterestsAsync.success(response));
  } catch (error) {
    yield put(fetchInterestsAsync.failure(error));
  }
}

function* getPassions() {
  try {
    const response = yield call(brandService.fetchPassions);

    yield put(fetchPassionsAsync.success(response));
  } catch (error) {
    yield put(fetchPassionsAsync.failure(error));
  }
}

function* fetchPixelStatsSaga() {
  try {
    const response = yield call(brandService.getPixelStats);

    yield put(fetchPixelStatsAsync.success(response));
  } catch (error) {
    yield put(fetchPixelStatsAsync.failure(error));
  }
}

function* getTags({ payload }) {
  try {
    const response = yield call(brandService.fetchTags, payload);

    const tags = response.data
      .map((tag) => {
        return { ...tag, id: nanoid() };
      })
      .filter((item) => Object.keys(item).length);

    yield put(fetchTagsAsync.success({ tags, clear: payload.clear }));
    yield put(setCustomAudiencesTags(tags));
  } catch (error) {
    yield put(fetchTagsAsync.failure(error));
  }
}

function* fetchCountries() {
  try {
    const { countryCode } = yield select(brandInfoSelector);

    const response = yield call(brandService.getCountries);

    yield put(fetchCountriesAsync.success(response));

    if (countryCode) {
      const brandCountryInfo = response.find((country) => country.countryCode === countryCode);
      yield put(setBrandCountryInfo(brandCountryInfo));
    }
  } catch (error) {
    yield put(fetchCountriesAsync.failure(error));
  }
}

function* updateBrandAccountInfoSaga({ payload }) {
  try {
    const {
      brandName,
      logoUri,
      logoFile,
      website,
      emailAddress,
      billingEmail,
      legalName,
      brandType,
      addressLineFirst,
      addressLineSecond,
      city,
      postCode,
      brandCountryCode,
      VATFile,
    } = payload.form;

    const { saveBusinessSettings } = payload;

    yield put(toggleSavingToast(true));

    const { country, timeZone } = yield select(brandInfoSelector);

    const address = {
      addressLine1: addressLineFirst,
      addressLine2: addressLineSecond,
      city,
      countryCode: brandCountryCode,
      postalCode: postCode,
    };

    let response;

    if (saveBusinessSettings)
      response = yield call(brandService.updateBusinessDetails, {
        address,
        accountType: brandType,
        billingEmail,
        legalName,
        vatReceiptFile: VATFile,
      });

    if (logoFile?.name) {
      yield call(brandService.uploadBrandMedia, {
        media: logoFile,
        mediaType: logoFile?.type,
        logoUri,
      });
    }
    yield call(brandService.updateBrandAccountInfo, {
      name: brandName,
      website,
      emailAddress,
      timezone: timeZone.name,
      countryCode: country?.countryCode,
    });

    yield put(toggleSavingToast(false));

    yield delay(500);

    toast.success(t('Account Info Saved'));

    let data = {
      brandName,
      website,
      emailAddress,
      timezone: timeZone.name,
      countryCode: country?.countryCode,
    };

    if (logoFile?.name) data = { ...data, logoUri };

    if (saveBusinessSettings) {
      let { brand } = response;

      data = {
        ...data,
        ...brand,
        addressLineFirst: brand?.address?.addressLine1 || '',
        addressLineSecond: brand?.address?.addressLine2 || '',
        city: brand?.address?.city || '',
        postCode: brand?.address?.postalCode || '',
        brandCountryCode: brand?.address?.countryCode || '',
      };
    }

    if (response?.brandDocPreSignedUrlDetail)
      data = {
        ...data,
        vatReceiptUri: response?.brandDocPreSignedUrlDetail.brandDocUri,
      };

    yield put(updateAccountInfo.success(data));
  } catch (error) {
    yield put(toggleSavingToast(false));
    toast.error(t('Unable to save Account Info'));

    toast.error(error?.response?.data?.message);
    yield put(updateAccountInfo.failure(error));
  }
}

function* sendCreditCardSaga({ payload }) {
  try {
    yield call(brandService.sendNewPaymentCard, {
      paymentMethod: payload,
    });

    yield put(sendPaymentCardAsync.success());
  } catch (error) {
    yield put(sendPaymentCardAsync.failure(error));
  }
}

function* getCardSetupIntentKeySaga() {
  try {
    const response = yield call(brandService.getCardSetupIntentKey);

    yield put(getCardSetupIntentKeyAsync.success(response));
  } catch (error) {
    if (error?.response?.data.message || error?.response?.data['error_description']) {
      yield put(
        setModalText({
          name: 'errors',
          text: error?.response?.data['error_description']
            ? processReceivedText(error?.response?.data['error_description'])
            : error.message,
        })
      );
      yield put(toggleModalByName({ name: 'errors', value: true }));
    }

    yield put(getCardSetupIntentKeyAsync.failure());
  }
}

function* getPaymentMethodsSaga({ payload }) {
  try {
    let paymentMethods = yield call(brandService.getPaymentMethods);
    const primaryMethod = paymentMethods.filter((item) => item.isDefault)[0];

    if (!primaryMethod && paymentMethods.length) {
      yield call(brandService.makeMethodPrimary, {
        paymentMethodId: paymentMethods[0].paymentMethodId,
      });
      paymentMethods = yield call(brandService.getPaymentMethods);
    }

    yield put(getPaymentMethodsAsync.success(paymentMethods));
    if (payload?.cardAdded) toast.success(t('Card added successfully'));
  } catch (error) {
    // TODO - logic to show allert Popup if a user hasn't any payment methods
    // commented it, maybe in future we'll need it
    // ***********************************************************************
    // if (error?.response?.data.message || error?.response?.data['error_description']) {
    //   yield put(setModalText({
    //     name: 'errors',
    //     text: error?.response?.data['error_description']
    //       ? processReceivedText(error?.response?.data['error_description'])
    //       : error.message,
    //   }));
    //   yield put(toggleModalByName({ name: 'errors', value: true }));
    // }

    yield put(getPaymentMethodsAsync.failure());
  }
}

function* removePaymentMethodSaga({ payload }) {
  try {
    const paymentMethods = yield select(paymentMethodsSelector);

    yield call(brandService.removePaymentMethod, {
      paymentMethodId: payload.paymentMethodId,
    });

    let newPaymentMethods = paymentMethods.filter(
      (item) => item.paymentMethodId !== payload.paymentMethodId
    );
    const primaryMethod = newPaymentMethods.filter((item) => item.isDefault)[0];

    if (!primaryMethod && newPaymentMethods.length) {
      yield call(brandService.makeMethodPrimary, {
        paymentMethodId: newPaymentMethods[0].paymentMethodId,
      });
    }
    newPaymentMethods = yield call(brandService.getPaymentMethods);

    yield put(getPaymentMethodsAsync.success(newPaymentMethods));
  } catch (error) {
    if (error?.response?.data.message || error?.response?.data['error_description']) {
      yield put(
        setModalText({
          name: 'errors',
          text: error?.response?.data['error_description']
            ? processReceivedText(error?.response?.data['error_description'])
            : error.message,
        })
      );
      yield put(toggleModalByName({ name: 'errors', value: true }));
    }

    yield put(removePaymentMethodAsync.failure());
  }
}

function* makeMethodPrimarySaga({ payload }) {
  try {
    const { paymentMethodId } = payload;

    yield call(brandService.makeMethodPrimary, { paymentMethodId });
    const newPaymentMethods = yield call(brandService.getPaymentMethods);

    yield put(getPaymentMethodsAsync.success(newPaymentMethods));
  } catch (error) {
    if (error?.response?.data.message || error?.response?.data['error_description']) {
      yield put(
        setModalText({
          name: 'errors',
          text: error?.response?.data['error_description']
            ? processReceivedText(error?.response?.data['error_description'])
            : error.message,
        })
      );
      yield put(toggleModalByName({ name: 'errors', value: true }));
    }

    yield put(makeMethodPrimaryAsync.failure());
  }
}

function* chargePaymentMethodSaga({ payload }) {
  try {
    const { returnTo, invoiceId } = payload;
    const vatAndFee = yield select(vatAndFeeSelector);
    const brandBills = yield select(brandBillsSelector);
    const modifiedBrandBills = brandBills.data.map((item) => {
      return {
        ...item,
        billStatus:
          item?.invoiceId && item?.invoiceId === invoiceId ? 'Processing Payment' : item.billStatus,
      };
    });

    const queryParams = {
      bodyParams: {
        stripeFee: +Number(vatAndFee.stripeFee).toFixed(2),
        subTotal: +Number(vatAndFee.subTotal).toFixed(2),
        total: +Number(vatAndFee.total).toFixed(2),
        vatAmount: +Number(vatAndFee.vatAmount).toFixed(2),
      },
      invoiceId,
    };

    yield call(brandService.chargePaymentMethod, queryParams);
    toast.success(t('Payment has been submitted successfully'));
    yield put(updateBrandBills(modifiedBrandBills));
    yield put(setBrandData({ name: 'funds', value: '' }));
    yield put(setBrandInvoicePaid(invoiceId));
    yield put(setDefaultVatAndFee());
    yield put(chargePaymentMethodAsync.success());

    if (returnTo) {
      yield put(push(returnTo));
      const campaignId = returnTo.split('/')[2];
      yield put(publishCampaignAsync.request(campaignId));
    }
  } catch (error) {
    console.log(error);
    if (error?.response && error.response.data) {
      yield put(setStripeChargeError(error.response.data));
    }
    yield put(chargePaymentMethodAsync.failure());
    toast.warn(t('Payment has failed. Please check the details and try again'));
  }
}

function* getBacsCheckoutSessionIdSaga() {
  try {
    const response = yield call(brandService.getBacsCheckoutSessionId);

    const bacsSessionId = response.checkoutSessionId;
    saveIntoSessionStorage('bacsSessionId', bacsSessionId);

    yield put(getBacsCheckoutSessionIdAsync.success(response));
  } catch (error) {
    if (error?.response?.data.message || error?.response?.data['error_description']) {
      yield put(
        setModalText({
          name: 'errors',
          text: error?.response?.data['error_description']
            ? processReceivedText(error?.response?.data['error_description'])
            : error.message,
        })
      );
      yield put(toggleModalByName({ name: 'errors', value: true }));
    }

    yield put(getBacsCheckoutSessionIdAsync.failure());
  }
}

function* getBrandBillsSaga({ payload }) {
  try {
    const queryParams = {
      offset: payload.offset,
      limit: payload.limit,
    };

    const response = yield call(brandService.getBrandBills, queryParams);

    const { timezoneId, countryCode } = yield select(brandInfoSelector);

    const modifiedResponse = {
      ...response,
      data: getModifiedBillsData(response.data, countryCode, timezoneId),
    };

    yield put(getBrandBillsAsync.success(modifiedResponse));
  } catch (error) {
    yield put(getBrandBillsAsync.failure());
  }
}

function* getBrandsLastChargeSaga() {
  try {
    const response = yield call(brandService.getLastStripeCharge);
    let { timezoneId, countryCode } = yield select(brandInfoSelector);

    const modifiedResponseLastCharge = {
      ...response,
      date: setBrandDateFormat(formatToBrandsTimezone(response.date, timezoneId), countryCode),
    };

    yield put(getBrandsLastChargeAsync.success(modifiedResponseLastCharge));
  } catch (error) {
    yield put(getBrandsLastChargeAsync.failure());
  }
}

function* getBrandTransactionsSaga({ payload }) {
  try {
    const queryParams = {
      offset: payload.offset,
      limit: payload.limit,
    };

    const response = yield call(brandService.getBrandTransactions, queryParams);
    let { timezoneId, countryCode } = yield select(brandInfoSelector);

    const modifiedTransactionsData = response.data.map((item) => {
      return {
        ...item,
        date: item.date
          ? setBrandDateFormat(formatToBrandsTimezone(item.date, timezoneId), countryCode)
          : '',
      };
    });

    response.data = modifiedTransactionsData;

    yield put(getBrandTransactionsAsync.success(response));
  } catch (error) {
    yield put(getBrandTransactionsAsync.failure());
  }
}

function* updateBrandInfoFromBillingSaga({ payload }) {
  try {
    const {
      billingEmail,
      legalName,
      brandType,
      addressLineFirst,
      addressLineSecond,
      city,
      postCode,
      brandCountryCode,
    } = payload;

    const { profileInfoTypes } = brandConstants;

    yield call(brandService.updateBrandProfileInfo, {
      bodyParams: {
        brandType,
      },
      profileInfoType: profileInfoTypes.BRAND_TYPE,
    });

    yield call(brandService.updateBrandProfileInfo, {
      bodyParams: {
        legalName,
      },
      profileInfoType: profileInfoTypes.LEGAL_NAME,
    });

    yield call(brandService.updateBrandProfileInfo, {
      bodyParams: {
        billingEmail,
      },
      profileInfoType: profileInfoTypes.BILLING_EMAIL,
    });

    yield call(brandService.updateBrandProfileInfo, {
      bodyParams: {
        addressLine1: addressLineFirst,
        addressLine2: addressLineSecond,
        city,
        countryCode: brandCountryCode,
        postalCode: postCode,
      },
      profileInfoType: profileInfoTypes.ADDRESS,
    });

    if (payload?.isCampainActivation) {
      yield put(updateBrandInfoFromCampaignSummaryAsync.success());
    } else {
      yield put(updateBrandInfoFromBillingAsync.success());
      yield put(toggleModalByName({ name: 'campaignActivation', value: false }));
    }
    yield put(toggleIsBrandInfoSaved(true));
  } catch (error) {
    if (payload?.isCampainActivation) {
      yield put(updateBrandInfoFromCampaignSummaryAsync.failure(error));
    } else {
      yield put(updateBrandInfoFromBillingAsync.failure(error));
    }
  }
}

const formatNumber = (number) => {
  const numberLocale = number.toLocaleString();
  const decimalSeparator = (1.1).toLocaleString().substring(1, 2);
  const [integerPart, ...decimalParts] = numberLocale.split(decimalSeparator);

  if (decimalParts.length === 0) return numberLocale;
  const decimalPart = decimalParts.join(decimalSeparator);
  return decimalPart.length === 1
    ? number.toFixed(2).toLocaleString()
    : `${integerPart}${
        decimalPart.slice(0, 2) !== '00' ? decimalSeparator + decimalPart.slice(0, 2) : ''
      }`;
};

function* getVatAndFeeSaga({ payload }) {
  try {
    const { amount } = payload;

    const response = yield call(brandService.getVatAndFee, { amount: amount.toString() });

    const { stripeFee, subTotal, total, vatAmount, paymentProcessingTime } = response;
    const vatAndFee = {
      stripeFee: formatNumber(stripeFee),
      subTotal: formatNumber(subTotal),
      total: formatNumber(total),
      vatAmount: formatNumber(vatAmount),
      paymentProcessingTime,
    };

    yield put(getVatAndFeeAsync.success(vatAndFee));
  } catch (error) {
    yield put(getVatAndFeeAsync.failure());
    toast.warn(t('Got error while getting VAT and Fee'));
  }
}

function* setInvoicePartnerRequestSaga({ payload }) {
  try {
    const { formData } = payload;
    const newEstimatedMonthlySpend = +formData.estimatedMonthlySpend;
    const bodyParams = {
      ...formData,
      estimatedMonthlySpend: newEstimatedMonthlySpend,
    };
    yield call(brandService.invoicePartnerRequest, bodyParams);
    yield put(setModalDisplayMode({ name: 'requestInvoice', value: false }));
    yield put(setInvoicePartnerRequestAsync.success());
  } catch (error) {
    yield put(setInvoicePartnerRequestAsync.failure());
    toast.warn(t('Got error while sending request for Invoice Partner'));
  }
}

function* sendResetPasswordEmail({ payload }) {
  try {
    const emailAddress = payload;

    yield call(brandService.sendResetPasswordEmail, {
      emailAddress,
    });

    yield put(push(`${routes.RESET_PASSWORD_EMAIL_SENT}?email=${emailAddress}`));

    toast.success(t('Reset password email request sent.'));
  } catch (error) {
    yield put(sendResetPasswordEmailAsync.failure(error));
    toast.error(t('Reset password email request failed.'));
  }
}

function* resetPassword({ payload }) {
  try {
    const { newPassword, key, emailAddress } = payload;

    yield call(brandService.resetPassword, {
      newPassword,
      key,
      emailAddress,
    });

    yield put(push(routes.RESET_PASSWORD_SUCCESS));

    toast.success(t('Your password has been reset successfully.'));
  } catch (error) {
    if (error?.response?.data?.message.includes('expired'))
      toast.error(t('Password reset failed. Your link is invalid or expired.'));
    else toast.error(t('Password reset failed.'));
  }
}

function* fetchLocationsSaga() {
  try {
    const { countryCode } = yield select(brandInfoSelector);

    const response = yield call(brandService.fetchLocations, {
      countryCode,
      search: '*',
    });

    yield put(fetchLocationsAsync.success(response));
  } catch (error) {}
}
function* fetchCampaignNamesSaga({ payload }) {
  try {
    const response = yield call(brandService.getCampaignNames, payload);
    yield put(fetchCampaignNamesAsync.success({ response, clear: payload.clear }));
  } catch (error) {
    yield put(fetchCampaignNamesAsync.failure());
  }
}
function* fetchBasicCampaignNamesSaga({ payload }) {
  try {
    const response = yield call(brandService.getBasicCampaignNames, payload);
    yield put(fetchBasicCampaignNamesAsync.success({ response, clear: payload.clear }));
  } catch (error) {
    yield put(fetchBasicCampaignNamesAsync.failure());
  }
}
function* fetchSponsorshipSetNamesSaga({ payload }) {
  try {
    const response = yield call(brandService.getSponsorshipSetNames, payload);
    yield put(fetchSponsorshipSetNamesAsync.success({ response, clear: payload.clear }));
  } catch (error) {
    yield put(fetchSponsorshipSetNamesAsync.failure());
  }
}
function* fetchBasicSponsorshipSetNamesSaga({ payload }) {
  try {
    const response = yield call(brandService.getBasicSponsorshipSetNames, payload);
    yield put(fetchBasicSponsorshipSetNamesAsync.success({ response, clear: payload.clear }));
  } catch (error) {
    yield put(fetchBasicSponsorshipSetNamesAsync.failure());
  }
}
function* fetchSponsorshipNamesSaga({ payload }) {
  try {
    const response = yield call(brandService.getSponsorshipNames, payload);
    yield put(fetchSponsorshipNamesAsync.success(response));
  } catch (error) {
    yield put(fetchSponsorshipNamesAsync.failure());
  }
}
function* fetchUniqueCampaignNamesSaga({ payload }) {
  try {
    const response = yield call(brandService.getUniqueCampaignNames, payload);
    yield put(fetchUniqueCampaignNamesAsync.success({ response, clear: payload.clear }));
  } catch (error) {
    yield put(fetchUniqueCampaignNamesAsync.failure());
  }
}
function* fetchUniqueBasicCampaignNamesSaga({ payload }) {
  try {
    const response = yield call(brandService.getUniqueBasicCampaignNames, payload);
    yield put(fetchUniqueBasicCampaignNamesAsync.success({ response, clear: payload.clear }));
  } catch (error) {
    yield put(fetchUniqueBasicCampaignNamesAsync.failure());
  }
}
function* fetchUniqueSponsorshipSetNamesSaga({ payload }) {
  try {
    const response = yield call(brandService.getUniqueSponsorshipSetNames, payload);
    yield put(fetchUniqueSponsorshipSetNamesAsync.success({ response, clear: payload.clear }));
  } catch (error) {
    yield put(fetchUniqueSponsorshipSetNamesAsync.failure());
  }
}
function* fetchUniqueBasicSponsorshipSetNamesSaga({ payload }) {
  try {
    const response = yield call(brandService.getUniqueBasicSponsorshipSetNames, payload);
    yield put(fetchUniqueBasicSponsorshipSetNamesAsync.success({ response, clear: payload.clear }));
  } catch (error) {
    yield put(fetchUniqueBasicSponsorshipSetNamesAsync.failure());
  }
}
function* fetchUniqueSponsorshipNamesSaga({ payload }) {
  try {
    const response = yield call(brandService.getUniqueSponsorshipNames, payload);
    yield put(fetchUniqueSponsorshipNamesAsync.success({ response, clear: payload.clear }));
  } catch (error) {
    yield put(fetchUniqueSponsorshipNamesAsync.failure());
  }
}
function* fetchUniqueBasicSponsorshipNamesSaga({ payload }) {
  try {
    const response = yield call(brandService.getUniqueBasicSponsorshipNames, payload);
    yield put(fetchUniqueBasicSponsorshipNamesAsync.success({ response, clear: payload.clear }));
  } catch (error) {
    yield put(fetchUniqueBasicSponsorshipNamesAsync.failure());
  }
}

function* fetchTestPixelEventsSaga({ payload }) {
  try {
    let { minDate, isPolling, ...rest } = payload;
    if (minDate && !isPolling) {
      return yield call(startPollingPixelTestEvents, { ...rest, minDate });
    }
    const response = yield call(brandService.getTestPixelEvents, { ...rest, minDate });
    yield put(fetchTestPixelEventsAsync.success(response.data));
  } catch (error) {
    yield put(fetchTestPixelEventsAsync.failure());
  }
}

function* startPollingPixelTestEvents(payload) {
  let i = 0;
  let isPixelTestInProgress = true;
  while (i < 5) {
    if (isPixelTestInProgress) {
      yield delay(10000); // Poll every 5 seconds
      yield call(fetchTestPixelEventsSaga, { payload: { ...payload, isPolling: true } });
      isPixelTestInProgress = yield select(isPixelTestInProgressSelector);
    }
    i++;
  }
  yield put(setIsPixelTestInProgress(false));
}

export function* brandActionWatcher() {
  yield takeLatest(fetchBrandAsync.request.type, fetchBrand);
  yield takeLatest(fetchAccountBalanceAsync.request.type, fetchAccountBalanceSaga);
  yield takeLatest(fetchCountriesAsync.request.type, fetchCountries);
  yield takeLatest(fetchCharitiesAsync.request.type, getCharities);
  yield takeLatest(fetchTagsAsync.request.type, getTags);
  yield takeLatest(fetchInterestsAsync.request.type, getInterests);
  yield takeLatest(fetchBasicAdPricingAsync.request.type, fetchBasicAdPricingSaga);
  yield takeLatest(fetchIncentivesAsync.request.type, fetchIncentivesSaga);
  yield takeLatest(fetchPassionsAsync.request.type, getPassions);
  yield takeLatest(fetchPixelStatsAsync.request.type, fetchPixelStatsSaga);
  yield takeLatest(updateAccountInfo.request.type, updateBrandAccountInfoSaga);
  yield takeLatest(sendPaymentCardAsync.request.type, sendCreditCardSaga);
  yield takeLatest(sendResetPasswordEmailAsync.request.type, sendResetPasswordEmail);
  yield takeLatest(resetPasswordAsync.request.type, resetPassword);
  yield takeLatest(getCardSetupIntentKeyAsync.request.type, getCardSetupIntentKeySaga);
  yield takeLatest(getBacsCheckoutSessionIdAsync.request.type, getBacsCheckoutSessionIdSaga);
  yield takeLatest(getPaymentMethodsAsync.request.type, getPaymentMethodsSaga);
  yield takeLatest(makeMethodPrimaryAsync.request.type, makeMethodPrimarySaga);
  yield takeLatest(chargePaymentMethodAsync.request.type, chargePaymentMethodSaga);
  yield takeLatest(removePaymentMethodAsync.request.type, removePaymentMethodSaga);
  yield takeLatest(getBrandBillsAsync.request.type, getBrandBillsSaga);
  yield takeLatest(getBrandTransactionsAsync.request.type, getBrandTransactionsSaga);
  yield takeLatest(getBrandsLastChargeAsync.request.type, getBrandsLastChargeSaga);
  yield takeLatest(updateBrandInfoFromBillingAsync.request.type, updateBrandInfoFromBillingSaga);
  yield takeLatest(getVatAndFeeAsync.request.type, getVatAndFeeSaga);
  yield takeLatest(fetchLocationsAsync.request.type, fetchLocationsSaga);
  yield takeLatest(fetchCampaignNamesAsync.request.type, fetchCampaignNamesSaga);
  yield takeLatest(fetchBasicCampaignNamesAsync.request.type, fetchBasicCampaignNamesSaga);
  yield takeLatest(fetchSponsorshipSetNamesAsync.request.type, fetchSponsorshipSetNamesSaga);
  yield takeLatest(
    fetchBasicSponsorshipSetNamesAsync.request.type,
    fetchBasicSponsorshipSetNamesSaga
  );
  yield takeLatest(fetchSponsorshipNamesAsync.request.type, fetchSponsorshipNamesSaga);
  yield takeLatest(fetchUniqueCampaignNamesAsync.request.type, fetchUniqueCampaignNamesSaga);
  yield takeLatest(
    fetchUniqueBasicCampaignNamesAsync.request.type,
    fetchUniqueBasicCampaignNamesSaga
  );
  yield takeLatest(
    fetchUniqueSponsorshipSetNamesAsync.request.type,
    fetchUniqueSponsorshipSetNamesSaga
  );
  yield takeLatest(
    fetchUniqueBasicSponsorshipSetNamesAsync.request.type,
    fetchUniqueBasicSponsorshipSetNamesSaga
  );
  yield takeLatest(fetchUniqueSponsorshipNamesAsync.request.type, fetchUniqueSponsorshipNamesSaga);
  yield takeLatest(
    fetchUniqueBasicSponsorshipNamesAsync.request.type,
    fetchUniqueBasicSponsorshipNamesSaga
  );
  yield takeLatest(setInvoicePartnerRequestAsync.request.type, setInvoicePartnerRequestSaga);
  yield takeLatest(fetchTestPixelEventsAsync.request.type, fetchTestPixelEventsSaga);
}
