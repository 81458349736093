import { push } from 'connected-react-router';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  postProcessAgeData,
  postProcessGenderData,
  postProcessInterests,
  postProcessLocationData,
  postProcessOsData,
  postProcessPassions,
  postProcessSchedule,
} from '../../../utils/submitData/submitDataHelpers';

import { container } from '../../../container';

import { brandInfoSelector } from '../../brand';

import {
  activateBasicAdSponsorshipSetFromCreationAsync,
  calculateDailyBudgetAsync,
  createBasicAdSponsorshipSetAsync,
  deactivateBasicAdSponsorshipSetFromCreationAsync,
  fetchBasicAdAdSetPricingOptionsAsync,
  updateBasicAdSponsorshipSetAsync,
} from './actions';

import { t } from '@transifex/native';
import { generatePath } from 'react-router';
import { toast } from 'react-toastify';
import UnpublishedChangesToastContent from '../../../components/UnpublishChangesToastContent/UnpublishedChangesToastContent';
import { fragments, routes } from '../../../constants';
import { budgetParser } from '../../../utils/budget';
import { processDifferences } from '../../../utils/receivedData';
import { setBasicAdAdSetIsDirty } from '../../basicAdData';
import { adSetsSelector, unpublishedChangesSelector } from '../../data';
import { toggleSavingToast } from '../../ui';

const basicAdSponsorshipSetService = container.get('BasicAdSponsorshipSetService');
const brandService = container.get('BrandService');

function* createSponsorshipSetSaga({ payload }) {
  try {
    const differences = yield select(unpublishedChangesSelector);

    const { form, adSetIndex } = payload;

    let { pixelConversionEvent, isEightPixelEnabled, eightPixelAudiences } = form;

    const { timezoneId } = yield select(brandInfoSelector);

    const ageRanges = postProcessAgeData(form.age);
    const genders = postProcessGenderData(form.gender);
    const locations = postProcessLocationData(form.locations);
    const interests = postProcessInterests(form.interests);
    const passions = postProcessPassions(form.passions);
    const deviceType = postProcessOsData(form.deviceType ?? 'any');

    const { startDate, endDate, budget, frequencyCap } = form;

    const { scheduleFrom, scheduleTo } = postProcessSchedule(startDate, endDate, timezoneId);

    yield put(toggleSavingToast(true));

    const response = yield call(basicAdSponsorshipSetService.createBasicAdSponsorshipSet, {
      basicAdCampaignId: form.basicAdId,
      name: form.name,
      isEightPixelEnabled,
      pixelConversionEvent,
      includeLocations: locations,
      ageRanges,
      genders,
      eightPixelAudiences,
      deviceType,
      startDate: scheduleFrom,
      endDate: scheduleTo,
      totalBudget: budgetParser(budget),
      interests,
      passions,
      frequencyCap: parseInt(frequencyCap),
      timezoneId,
      timezoneInfo: {
        id: '8d2ed595-43be-4a24-9c57-5eda60f68f40',
        utcString: 'Europe/Athens',
        value: 'GTB Standard Time',
        abbr: 'GDT',
        offset: 3,
        isDst: true,
        text: '(UTC+02:00) Athens, Bucharest',
        textUtcString: '(UTC+03:00) Athens, Europe',
      },
    });

    if (response.unpublishedDifferences) {
      const processedNewDifferences = processDifferences([response.unpublishedDifferences]);
      const filteredPrevDifferences = differences.filter((diff) => diff.id !== response.id);
      // yield put(
      //   setUnpublishedBasicAdDifferences([...filteredPrevDifferences, ...processedNewDifferences])
      // );
      toast(<UnpublishedChangesToastContent />, {
        containerId: 'unpublished-changes',
        toastId: 'unpublished-toast',
        closeButton: false,
      });
    } else if (differences.length) {
      // yield put(
      //   setUnpublishedBasicAdDifferences(differences.filter((diff) => diff.id !== response.id))
      // );
    }

    yield put(
      createBasicAdSponsorshipSetAsync.success({ form, sponsorshipSetId: response.id, adSetIndex })
    );

    // yield put(
    //   setBasicAdAdSetIsDirty({
    //     isDirty: false,
    //     form: payload.form,
    //     adSetId: response.id,
    //     dirtyFields: [],
    //   })
    // );

    yield put(toggleSavingToast(false));

    yield put(
      push(
        generatePath(routes.BASIC_AD.SPONSORSHIP_CREATE_MULTI, {
          cid: form.basicAdId,
          ssid: response.id,
          newIndex: 1,
        }) + fragments.AD_NAME
      )
    );
  } catch (error) {
    console.log(error);
    yield put(createBasicAdSponsorshipSetAsync.failure(error));
    if (error.response?.data?.code === 160)
      toast.error(
        t(
          "There was an error with the brand's ratecard. Please check if there have been any changes to it. If there were changes, please create a new campaign."
        ),
        {
          autoClose: false,
          containerId: 'upload-success',
        }
      );
    if (error.response?.data?.code === 107)
      toast.error(t(`Budget must be greater than the Ad Set's amount spent.`), {
        autoClose: false,
        containerId: 'upload-success',
      });
    if (error.response?.data?.code === 12)
      toast.error(t(`Invalid include or exclude audience tag.`), {
        autoClose: false,
        containerId: 'upload-success',
      });
    yield put(toggleSavingToast(false));
  }
}

function* updateSponsorshipSetSaga({ payload }) {
  try {
    const adSets = yield select(adSetsSelector);
    const differences = yield select(unpublishedChangesSelector);

    const adSetIndex = adSets.findIndex((adSet) => adSet.id === payload.adsetId);

    let { pixelConversionEvent, isEightPixelEnabled, eightPixelAudiences } = payload;

    const { timezoneId } = yield select(brandInfoSelector);

    const adSetId = payload.adsetId;
    const ageRanges = postProcessAgeData(payload.age);
    const genders = postProcessGenderData(payload.gender);
    const locations = postProcessLocationData(payload.locations);
    const interests = postProcessInterests(payload.interests);
    const passions = postProcessPassions(payload.passions);
    const deviceType = postProcessOsData(payload.deviceType ?? 'any');

    const { startDate, endDate, budget, incentive, frequencyCap } = payload;

    const { scheduleFrom, scheduleTo } = postProcessSchedule(startDate, endDate, timezoneId);

    yield put(toggleSavingToast(true));
    const response = yield call(basicAdSponsorshipSetService.updateBasicAdSponsorshipSet, {
      basicAdCampaignId: payload.basicAdId,
      adsetId: payload.adsetId,
      name: payload.name,
      isEightPixelEnabled,
      pixelConversionEvent,
      includeLocations: locations,
      ageRanges,
      genders,
      eightPixelAudiences,
      deviceType,
      startDate: scheduleFrom,
      endDate: scheduleTo,
      totalBudget: budgetParser(budget),
      interests,
      passions,
      frequencyCap: parseInt(frequencyCap),
      timezoneId,
      timezoneInfo: {
        id: '8d2ed595-43be-4a24-9c57-5eda60f68f40',
        utcString: 'Europe/Athens',
        value: 'GTB Standard Time',
        abbr: 'GDT',
        offset: 3,
        isDst: true,
        text: '(UTC+02:00) Athens, Bucharest',
        textUtcString: '(UTC+03:00) Athens, Europe',
      },
    });

    if (response.unpublishedDifferences) {
      const processedNewDifferences = processDifferences([response.unpublishedDifferences]);
      const filteredPrevDifferences = differences.filter((diff) => diff.id !== adSetId);
      // yield put(
      //   setUnpublishedDifferences([...filteredPrevDifferences, ...processedNewDifferences])
      // );
      toast(<UnpublishedChangesToastContent />, {
        containerId: 'unpublished-changes',
        toastId: 'unpublished-toast',
        closeButton: false,
      });
    } else if (differences.length) {
      // yield put(setUnpublishedDifferences(differences.filter((diff) => diff.id !== adSetId)));
    }

    yield put(
      updateBasicAdSponsorshipSetAsync.success({
        form: { ...payload },
        sponsorshipSetId: adSetId,
      })
    );

    // yield put(
    //   setBasicAdAdSetIsDirty({
    //     isDirty: false,
    //     form: payload,
    //     adSetId: adSetId,
    //     dirtyFields: [],
    //   })
    // );

    yield put(toggleSavingToast(false));

    yield put(
      push(
        adSets[adSetIndex]?.ads[0]?.id
          ? generatePath(routes.BASIC_AD.SPONSORSHIP_EDIT, {
              cid: payload.basicAdId,
              ssid: payload.adsetId,
              sid: adSets[adSetIndex]?.ads[0]?.id,
            }) + fragments.AD_NAME
          : generatePath(routes.BASIC_AD.SPONSORSHIP_CREATE_MULTI, {
              cid: payload.basicAdId,
              ssid: payload.adsetId,
              newIndex: 1,
            }) + fragments.AD_NAME
      )
    );
  } catch (error) {
    console.log(error);
    const sponsorshipSetId = payload.sponsorshipSetId;
    yield put(updateBasicAdSponsorshipSetAsync.failure(error));
    if (error.response.data.code === 160)
      toast.error(
        t(
          "There was an error with the brand's ratecard. Please check if there have been any changes to it. If there were changes, please create a new campaign."
        ),
        {
          autoClose: false,
          containerId: 'upload-success',
        }
      );
    if (error.response.data.code === 107)
      toast.error(t(`Budget must be greater than the Ad Set's amount spent.`), {
        autoClose: false,
        containerId: 'upload-success',
      });
    if (error.response.data.code === 12)
      toast.error(t(`Invalid include or exclude audience tag.`), {
        autoClose: false,
        containerId: 'upload-success',
      });
    yield put(
      setBasicAdAdSetIsDirty({
        isDirty: true,
        form: payload.form,
        adSetId: sponsorshipSetId,
        dirtyFields: payload.dirtyFields,
      })
    );
    yield put(toggleSavingToast(false));
  }
}

function* activateSponsorshipSetSaga({ payload }) {
  try {
    const { campaignId, adSetId } = payload;

    yield call(basicAdSponsorshipSetService.activateBasicAdSponsorshipSet, {
      campaignId,
      adSetId,
    });
    yield put(activateBasicAdSponsorshipSetFromCreationAsync.success(adSetId));
  } catch (error) {
    yield put(activateBasicAdSponsorshipSetFromCreationAsync.failure(error));
  }
}

function* deactivateSponsorshipSetSaga({ payload }) {
  try {
    const { campaignId, adSetId } = payload;

    yield call(basicAdSponsorshipSetService.deactivateBasicAdSponsorshipSet, {
      campaignId,
      adSetId,
    });
    yield put(deactivateBasicAdSponsorshipSetFromCreationAsync.success(adSetId));
  } catch (error) {
    yield put(deactivateBasicAdSponsorshipSetFromCreationAsync.failure(error));
  }
}

function* fetchAdSetPricingOptionsSaga({ payload }) {
  try {
    let pricingList = yield call(brandService.getPricing, payload?.samiPricingId);

    pricingList = pricingList.sort((a, b) => a.videoMaxTimeInSeconds - b.videoMaxTimeInSeconds);

    yield put(
      fetchBasicAdAdSetPricingOptionsAsync.success({
        pricingList,
      })
    );
  } catch (error) {
    yield put(fetchBasicAdAdSetPricingOptionsAsync.failure(error.message));
    console.log(error);
  }
}

function* calculateDailyBudgetSaga({ payload }) {
  try {
    const dailyBudget = yield call(basicAdSponsorshipSetService.calculateDailyBudget, payload);

    yield put(calculateDailyBudgetAsync.success(dailyBudget));
  } catch (error) {
    yield put(calculateDailyBudgetAsync.failure(error.message));
    console.log(error);
  }
}

export function* basicAdSponsorshipSetActionWatcher() {
  yield takeLatest(createBasicAdSponsorshipSetAsync.request.type, createSponsorshipSetSaga);
  yield takeLatest(updateBasicAdSponsorshipSetAsync.request.type, updateSponsorshipSetSaga);
  yield takeLatest(calculateDailyBudgetAsync.request.type, calculateDailyBudgetSaga);
  yield takeLatest(
    activateBasicAdSponsorshipSetFromCreationAsync.request.type,
    activateSponsorshipSetSaga
  );
  yield takeLatest(
    deactivateBasicAdSponsorshipSetFromCreationAsync.request.type,
    deactivateSponsorshipSetSaga
  );
  yield takeLatest(fetchBasicAdAdSetPricingOptionsAsync.request.type, fetchAdSetPricingOptionsSaga);
}
