import sponsorshipConstants from './sponsorship-data';

const sponsorshipSetConstants = Object.freeze({
  minimumCampaignDurationInDays: 2,
  minimumBudget: 50,
  initialState: {
    ads: [sponsorshipConstants.initialState],
    id: 'new',
    isCreated: false,
    isDirty: false,
    isDraft: true,
    isActive: false,
    isReadOnly: false,
    deliveryStatus: 'draft',
    isEightPixelEnabled: false,
    name: 'New Ad Set',
    age: ['all_age', '13 - 17', '18 - 24', '25 - 34', '35 - 44', '45 - 54', '55 - 64', '65'],
    gender: ['all_gender', 'female', 'male', 'non_binary'],
    startDate: '',
    endDate: '',
    locations: [],
    locationsToRemove: [],
    brandTimezone: {},
    customAudiencesOptions: [],
    customAudiencesSelected: [],
    interests: [],
    passions: [],
    frequencyCap: '',
    customAudiencesTags: [],
    os: { name: 'All', label: 'All', value: 'any' },
    budget: '',
    dailyBudget: '',
    charitiesAreas: [],
    charities: [],
    selectedCharity: { value: '', label: '' },
    retargetAudience: { name: 'Any', label: 'Any', value: 'any' },
    internalFetching: false,
    selectedVideoAdPrice: {},
    newCharityName: '',
    newCharityWebsite: '',
    pixelConversionEvent: 'unknown',
    basicAdsPricingId: '',
    videoAdPricingData: [],
    previousSponsorshipSetsNames: [],
    pricePerMessage: {},
    eightPixelAudiences: [],
  },
  genderData: [
    { name: 'All', value: 'all_gender', label: 'All' },
    { name: 'Male', value: 'male', label: 'Male' },
    { name: 'Female', value: 'female', label: 'Female' },
    { name: 'NonBinary', value: 'non_binary', label: 'Non-Binary' },
  ],
  genderFilteredOptions: [
    { name: 'All', value: 'all', label: 'All' },
    { name: 'Male', value: 'male', label: 'Male' },
    { name: 'Female', value: 'female', label: 'Female' },
    { name: 'NonBinary', value: 'non_binary', label: 'Non-Binary' },
  ],
  defaultGenderData: ['all_gender', 'female', 'male', 'non_binary'],
  ageData: [
    { name: 'All', value: 'all_age', label: 'All age ranges' },
    { name: '13,17', value: '13 - 17', label: '13 - 17' },
    { name: '18,24', value: '18 - 24', label: '18 - 24' },
    { name: '25,34', value: '25 - 34', label: '25 - 34' },
    { name: '35,44', value: '35 - 44', label: '35 - 44' },
    { name: '45,54', value: '45 - 54', label: '45 - 54' },
    { name: '55,64', value: '55 - 64', label: '55 - 64' },
    { name: '65', value: '65', label: '65+' },
  ],
  pixelAudiencesData: [
    { label: 'Took action', value: 'took_action' },
    { label: 'Did not take action', value: 'did_not_take_action' },
  ],
  defaultAgeData: [
    'all_age',
    '13 - 17',
    '18 - 24',
    '25 - 34',
    '35 - 44',
    '45 - 54',
    '55 - 64',
    '65',
  ],
  defaultOsData: { name: 'All', label: 'All', value: 'any' },
  osData: [
    { name: 'All', label: 'All', value: 'any' },
    { name: 'iOS', label: 'iOS', value: 'ios' },
    { name: 'Android', label: 'Android', value: 'android' },
  ],
  osFilteredData: [
    { name: 'All', label: 'All', value: 'all' },
    { name: 'iOS', label: 'iOS', value: 'i_phone' },
    { name: 'Android', label: 'Android', value: 'android_phone' },
  ],
  videoAdPricingCheckboxes: [
    {
      videoLengthName: '15sec',
      label: '5 - 15 sec',
      videoMinTimeInSeconds: 5,
      videoMaxTimeInSeconds: 15,
    },
    {
      videoLengthName: '30sec',
      label: '16 - 30 sec',
      videoMinTimeInSeconds: 16,
      videoMaxTimeInSeconds: 30,
    },
    {
      videoLengthName: '60sec',
      label: '31 - 60 sec',
      videoMinTimeInSeconds: 31,
      videoMaxTimeInSeconds: 60,
    },
    {
      videoLengthName: '90sec',
      label: '61 - 90 sec',
      videoMinTimeInSeconds: 61,
      videoMaxTimeInSeconds: 90,
    },
    {
      videoLengthName: '120sec',
      label: '91 - 120 sec',
      videoMinTimeInSeconds: 91,
      videoMaxTimeInSeconds: 120,
    },
    {
      videoLengthName: '180sec',
      label: '121 - 180 sec',
      videoMinTimeInSeconds: 121,
      videoMaxTimeInSeconds: 180,
    },
  ],
  fieldNames: [
    { value: 'name', label: 'Ad Set Name' },
    { value: 'gender', label: 'Gender' },
    { value: 'age', label: 'Age' },
    { value: 'locations', label: 'Location' },
    { value: 'interests', label: 'Interests' },
    { value: 'includeTags', label: 'Include Custom Audiences' },
    { value: 'excludeTags', label: 'Exclude Custom Audiences' },
    { value: 'isEightPixelEnabled', label: '8Pixel Activation' },
    { value: 'pixelConversionEvent', label: '8Pixel Conversion Event' },
    { value: 'startDate', label: 'Start Date' },
    { value: 'endDate', label: 'End Date' },
    { value: 'budget', label: 'Total budget' },
    { value: 'incentive', label: 'Video Length' },
    { value: 'locationsStatus', label: 'Location Targeting Switch' },
  ],
});

export default sponsorshipSetConstants;
