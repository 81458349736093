import * as yup from 'yup';
import { t } from '@transifex/native';

import { isFuture, isAfter, isEqual, addDays, parseISO } from 'date-fns';

import { DIGITS_AND_COMAS } from '../../../../../utils/validate';
import { numberToCurrency } from '../../../../../utils/budget';
import { formatTimezonedDateToUTC } from '../../../../../utils/date/dateHelpers';
import sponsorshipSetConstants from '../../../../../constants/basicAd/sponsorshipSet-data';

const dailyBudgetValidation = yup.string();

const minCampaignDuration = sponsorshipSetConstants.minimumCampaignDurationInDays;

const nameAndAudienceValidation = {
  name: yup
    .string()
    .min(3, t('Minimum length should be 3 chars'))
    .max(100, t('Maximum length should not exceed 100 chars'))
    .required(t('This field is required')),
  locations: '',
  interests: '',
  includeTags: '',
  deviceType: '',
  age: yup.array().min(1, t('Please select at least one age group')),
  gender: yup.array().min(1, t('Please select at least one gender')),
};

const budgetValidation = yup
  .string()
  .required(t('Budget is a required field'))
  .matches(DIGITS_AND_COMAS, t('The budget should contain numbers only'))
  .when(['$currencyCode', '$countryCode'], (currencyCode, countryCode, schema) =>
    schema.test(
      'if-budget-enough',
      t('Ad set budget should be at least {amount}', {
        amount: numberToCurrency(sponsorshipSetConstants.minimumBudget, true),
      }),
      (value) => value && Number(value.replaceAll(',', '')) >= sponsorshipSetConstants.minimumBudget
    )
  );
export const createModeValidationSchema = yup.object().shape({
  //FIX ME commented out some validation for the date format,
  //maybe in future we'll need it
  ...nameAndAudienceValidation,
  startDate: yup
    .string()
    .required(t('Start Date is a required field'))
    .nullable()
    .when(['$brandUtcString', '$isEdit'], (utcString, isEdit, schema) => {
      return schema.test(
        'is-today-and-more',
        t(`Start Date must be no less than today's date`),
        (value) => {
          if (value) {
            if (isEdit) return true;
            return isFuture(parseISO(formatTimezonedDateToUTC(new Date(value), utcString)));
          }
        }
      );
    }),
  endDate: yup
    .string()
    .required(t('End Date is a required field'))
    .nullable()
    .when(['startDate', '$isEdit'], (startDate, isEdit, schema) =>
      startDate
        ? schema.test(
            `${minCampaignDuration}-days-interval`,
            t('Campaign duration has to be at least {minCampaignDuration} days', {
              minCampaignDuration,
            }),
            (value) => {
              if (value && startDate) {
                return (
                  isAfter(new Date(value), addDays(new Date(startDate), minCampaignDuration)) ||
                  isEqual(new Date(value), addDays(new Date(startDate), minCampaignDuration))
                );
              } else if (isEdit) return true;
              else return !value;
            }
          )
        : schema.test(
            'about-start-date',
            t('Please select a Start Date first'),
            (startDate, value) => {
              return !(value && !startDate);
            }
          )
    ),
  budget: budgetValidation,
  dailyBudget: dailyBudgetValidation,
  locations: yup
    .array()
    .nullable()
    .when('locationsStatus', (locationsStatus, schema) =>
      schema.test(`are-locations-active`, t('Please select at least one location'), (value) => {
        return locationsStatus ? value.length > 0 : true;
      })
    ),
  interests: yup
    .array()
    .nullable()
    .when('interestsStatus', (interestsStatus, schema) =>
      schema.test(`are-interests-active`, t('Please select at least one interest'), (value) => {
        return interestsStatus ? value.length > 0 : true;
      })
    ),
  passions: yup
    .array()
    .nullable()
    .when('passionsStatus', (passionsStatus, schema) =>
      schema.test(`are-passions-active`, t('Please select at least one passion'), (value) => {
        return passionsStatus ? value.length > 0 : true;
      })
    ),
  eightPixelAudiences: yup
    .array()
    .nullable()
    .when('eightPixelAudiencesStatus', (eightPixelAudiencesStatus, schema) =>
      schema.test(`are-audiences-active`, t('Please select at least one audience'), (value) => {
        return eightPixelAudiencesStatus ? value.length > 0 : true;
      })
    ),
});

export const editModeValidationSchema = yup.object().shape({
  //FIX ME commented out some validation for the date format,
  //maybe in future we'll need it
  ...nameAndAudienceValidation,
  // startDate: yup
  //   .string()
  //   .required(t('Start Date is a required field'))
  //   .nullable()
  //   // .test('is-date-format',
  //   //   'Start Date must be in yyyy-mm-dd format',
  //   //   value => {
  //   //     if (!value) {
  //   //       return false;
  //   //     }
  //   //     return isDateFormat(value);
  //   //   }
  //   // ),
  //   .test('is-today-and-more', t(`Start Date must be no less than today's date`), (value) =>
  //     isFuture(removeTimezoneAndLocaleOffset(new Date(value)))
  //   ),
  // endDate: yup
  //   .string()
  //   .required(t('End Date is a required field'))
  //   .nullable()
  //   .when('startDate', (startDate, schema) =>
  //     startDate
  //       ? schema.test(
  //           `${minCampaignDuration}-days-interval`,
  //           t('Campaign duration has to be at least {minCampaignDuration} days', {
  //             minCampaignDuration,
  //           }),
  //           (value) => {
  //             if (value && startDate) {
  //               return (
  //                 isAfter(new Date(value), addDays(new Date(startDate), minCampaignDuration)) ||
  //                 isEqual(new Date(value), addDays(new Date(startDate), minCampaignDuration))
  //               );
  //             } else return !value;
  //           }
  //         )
  //       : schema.test(
  //           'about-start-date',
  //           t('Please select a Start Date first'),
  //           (startDate, value) => {
  //             return !(value && !startDate);
  //           }
  //         )
  //   ),
  budget: budgetValidation,
  dailyBudget: dailyBudgetValidation,
  locations: yup
    .array()
    .nullable()
    .when('locationsStatus', (locationsStatus, schema) =>
      schema.test(`are-locations-active`, t('Please select at least one location'), (value) => {
        return locationsStatus ? value.length > 0 : true;
      })
    ),
  interests: yup
    .array()
    .nullable()
    .when('interestsStatus', (interestsStatus, schema) =>
      schema.test(`are-interests-active`, t('Please select at least one interest'), (value) => {
        return interestsStatus ? value.length > 0 : true;
      })
    ),
});
