import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useT } from '@transifex/react';

import { Button } from '../../../../../../components/common/Button';
import { SectionSubtitle } from '../../../../../../components/common/Titles/SectionSubtitle';
import { SectionTitle } from '../../../../../../components/common/Titles/SectionTitle';

import s from './Summary.module.scss';

import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '../../../../../../components/Modal';
import { campaignConstants, textsConstants } from '../../../../../../constants';
import { CREATE_CAMPAIGN_PAGE } from '../../../../../../constants/test-ids/create-campaing-sections/create-campaign-sections';
import { basicAdAdSetsSelector } from '../../../../../../store/basicAdData';
import { modalsSelector, setModalContent } from '../../../../../../store/ui';
import { getSelectedVideoAdPrice } from '../../../../../../utils/media';
import { constructTestId } from '../../../../../../utils/test-ids';
import { AdSetSummary } from './AdSetSummary';
import { AdSummary } from './AdSummary';

const SECTION = CREATE_CAMPAIGN_PAGE.SUMMARY;

const Summary = ({
  isArchive,
  publishCampaign,
  brandInfo,
  deactivateAll,
  campaign,
  toggleModalByName,
  isNotEnoughFunds,
  setModalDisplayMode,
  isProcessing,
  unpublishedChanges,
}) => {
  const modals = useSelector(modalsSelector);
  const allAdSets = useSelector(basicAdAdSetsSelector);
  const dispatch = useDispatch();
  const [unsavedAdsModal, setUnsavedAdsModal] = useState(false);
  const [disablePublish, setDisablePublish] = useState(false);
  const t = useT();

  const isBrandVerified = useMemo(
    () => brandInfo.accountStatus === 'verified',
    [brandInfo.accountStatus]
  );

  const { handleSubmit } = useForm({
    defaultValues: {},
  });

  const hasBeenApproved = useMemo(() => {
    return allAdSets.every((adSet) =>
      adSet.ads.every(
        (ad) => ad.moderationStatus === 'approved' || ad.moderationStatus === 'in review'
      )
    );
  }, [allAdSets]);

  const {
    id: campaignId,
    name: campaignName,
    category,
    campaignBrandName,
    campaignBrandLogoUri,
    selectedCharity,
  } = campaign;
  const isDifferentVideoLengthThanTheSelectedRange = useMemo(() => {
    if (brandInfo.role !== 'WeAre8') {
      const adSetsThatHaveAtLeastOneVideoOutOfRange = allAdSets.map((adSet) => {
        const adSetPricePerMessage = adSet?.incentive?.pricePerMessage;

        const adsPricingDetails =
          brandInfo.videoAdPricingData?.length > 0
            ? adSet.ads.map((ad) =>
                getSelectedVideoAdPrice(brandInfo.videoAdPricingData, ad?.videoLength)
              )
            : [];

        const atLeastOneVideoOutOfRange = adsPricingDetails.some(
          (adPricingDetails) => adPricingDetails?.pricePerMessage !== adSetPricePerMessage
        );

        return atLeastOneVideoOutOfRange;
      });

      return adSetsThatHaveAtLeastOneVideoOutOfRange.some((value) => value);
    } else return false;
  }, [brandInfo.role, brandInfo.videoAdPricingData, allAdSets]);

  const hasUnsavedAds = allAdSets.find((adSet) =>
    adSet.ads.find((ad) => ad.isDirty || ad.adVideo === '')
  );

  const handlePublish = useCallback(() => {
    if (isNotEnoughFunds) {
      dispatch(toggleModalByName({ name: 'campaignActivation', value: true }));
      return;
    }
    if (hasUnsavedAds) setUnsavedAdsModal(true);
    else {
      dispatch(publishCampaign(campaignId));
      setDisablePublish(true);
    }
  }, [isNotEnoughFunds, hasUnsavedAds, dispatch, publishCampaign, campaignId, toggleModalByName]);

  const disable = useMemo(() => {
    if (campaign.deliveryStatus && campaign.deliveryStatus !== 'draft') return true;
    // if (unpublishedChanges.length > 0) return !unpublishedChanges.length > 0;
    // if (isProcessing || hasBeenApproved) return isProcessing || hasBeenApproved;
    return isProcessing || disablePublish;
  }, [
    campaign.deliveryStatus,
    disablePublish,
    // hasBeenApproved,
    isProcessing,
    // unpublishedChanges.length,
  ]);

  const handlePublishWithUnsavedAds = useCallback(() => {
    dispatch(publishCampaign(campaignId));
    setDisablePublish(true);
    setUnsavedAdsModal(false);
  }, [dispatch, publishCampaign, campaignId]);

  const selectedCategory = useMemo(
    () => campaignConstants.objectives.find((item) => item.value === category),
    [category]
  );

  return (
    <div className={s['summary']}>
      <SectionTitle>
        <h3>{t('Campaign Summary')}</h3>
      </SectionTitle>
      <SectionSubtitle>
        {t(
          'Review your campaign details. Summary displays only your saved changes and ad sets that have at least one saved ad.'
        )}
      </SectionSubtitle>

      <form onSubmit={handleSubmit(handlePublish)}>
        <div className={s['summary__brand-info']}>
          <div className={s['image-section']}>
            <div className={s['image-section__picture']}>
              <img className={s['img']} src={campaignBrandLogoUri} alt="brand logo" />
            </div>
            <div className={s['image-section__name']}>{campaignBrandName || t(`Brand Name`)}</div>
          </div>
        </div>
        <div className={s['summary__campaign-level']}>
          <div className={s['summary__section']}>
            <div className={s['summary__section-title']}>{t('Campaign Name')}</div>
            <div className={s['summary__section-content']}>
              <div className={s['summary__section-content-block']}>
                {campaignName || t(`My Campaign`)}
              </div>
            </div>
          </div>

          <div className={s['summary__section']}>
            <div className={s['summary__section-title']}>{t('Objective')}</div>
            <div className={s['summary__section-content']}>
              <div className={s['summary__section-content-block']}>
                <div className={s['accented']}>{t(selectedCategory?.title)}</div>
                {selectedCategory?.subtitle ? t(selectedCategory?.subtitle) : ''}
              </div>
            </div>
          </div>

          <div className={s['summary__section']}>
            <div className={s['summary__section-title']}>{t('Selected Charity')}</div>
            <div className={s['summary__section-content']}>
              <div className={s['summary__section-content-block']}>
                <div className={s['image-section']}>
                  <div className={s['image-section__picture']}>
                    <img
                      className={s['img']}
                      src={selectedCharity?.logoUri || ''}
                      alt="charity logo"
                    />
                  </div>
                  <div className={s['image-section__name']}>{selectedCharity?.name || ''}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={s['summary__adSets-level']}>
          {campaign.adSets.map((adSet) => (
            <AdSetSummary campaign={campaign} adSet={adSet} brandInfo={brandInfo} key={adSet.id}>
              <div className={s['summary__ads-level']}>
                {adSet.ads.map((ad) => (
                  <AdSummary ad={ad} adSet={adSet} brandInfo={brandInfo} key={ad.id} />
                ))}
              </div>
            </AdSetSummary>
          ))}
        </div>
        {!isArchive && (
          <>
            <div className={s['buttons']}>
              <Button
                data-testid={constructTestId(SECTION, 'publish-btn')}
                customText={t('Publish *')}
                type="submit"
                styling="primary"
                disabled={disable}
              />
            </div>

            <span className={isBrandVerified ? s['notice'] : s['warning']}>
              *{' '}
              {isBrandVerified
                ? t(
                    `By clicking 'Publish,' you are launching your campaign on the selected start date.`
                  )
                : t(`You need to have a verified account to be able to publish campaigns.`)}
            </span>
          </>
        )}
      </form>
      {unsavedAdsModal && (
        <Modal
          title={t(
            'Please note there are ads with unsaved changes. Only updated ads will be published.'
          )}
          closeModal={() => setUnsavedAdsModal(false)}
          actionCallback={handlePublishWithUnsavedAds}
        />
      )}
      {modals.summary.isOpen && (
        <Modal
          title={t(`All sponsorships have been approved`)}
          actionCallback={() => dispatch(toggleModalByName({ name: 'summary', value: false }))}
          withSubmit
        />
      )}
      {modals.success.isOpen && (
        <Modal
          title={t('Success')}
          actionCallback={() => dispatch(toggleModalByName({ name: 'success', value: false }))}
          withoutCancel
          customButtonText={t('Got it')}
        >
          {t(textsConstants.basicAdPublishSuccess)}
        </Modal>
      )}
    </div>
  );
};

export default Summary;
