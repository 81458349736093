import { createAction, createAsyncAction } from 'eight.js.store-common';

// ASYNC

export const activateBasicAdSponsorshipSetFromCreationAsync = createAsyncAction(
  '@@basicAdSponsorshipSet/ACTIVATE_SPONSORSHIP_SET_FROM_CREATION'
);

export const deactivateBasicAdSponsorshipSetFromCreationAsync = createAsyncAction(
  '@@basicAdSponsorshipSet/DEACTIVATE_SPONSORSHIP_SET_FROM_CREATION'
);

export const createBasicAdSponsorshipSetAsync = createAsyncAction(
  '@@basicAdSponsorshipSet/CREATE_SPONSORSHIP_SET'
);

export const updateBasicAdSponsorshipSetAsync = createAsyncAction(
  '@@basicAdSponsorshipSet/UPDATE_SPONSORSHIP_SET'
);

export const fetchBasicAdAdSetPricingOptionsAsync = createAsyncAction(
  '@@basicAdSponsorshipSet/FETCH_AD_SET_PRICING_OPTIONS'
);

// SYNC

export const clearBasicAdSponsorshipSet = createAction(
  '@@basicAdSponsorshipSet/CLEAR_SPONSORSHIP_SET'
);

export const toggleBasicAdActivateSponsorshipSet = createAction(
  '@@basicAdSponsorshipSet/TOGGLE_SPONSORSHIP_SET_ACTIVE'
);

export const setBasicAdSponsorshipSetIsReadOnly = createAction(
  '@@basicAdSponsorshipSet/SET_READ_ONLY'
);

export const setBasicAdDataArray = createAction('@@basicAdSponsorshipSet/SET_DATA_IN_ARRAY');

export const setBasicAdSponsorshipSetInternalFetching = createAction(
  '@@basicAdSponsorshipSet/SET_IS_FETCHING'
);

export const setBasicAdSponsorshipSetData = createAction(
  '@@basicAdSponsorshipSet/SET_SPONSORSHIP_SET_DATA'
);

export const setBasicAdCustomAudiencesTags = createAction(
  '@@basicAdSponsorshipSet/UPDATE_CUSTOM_AUDIENCES_TAGS'
);

export const setBasicAdActiveAdSet = createAction('@@basicAdSponsorshipSet/SET_ACTIVE_AD_SET');

export const setBasicAdSponsorshipSetName = createAction(
  '@@basicAdSponsorshipSet/SET_SPONSORSHIP_SET_NAME'
);

export const calculateDailyBudgetAsync = createAsyncAction(
  '@@basicAdSponsorshipSet/CALCULATE_DAILY_BUDGET'
);

export const setDailyBudget = createAction('@@basicAdSponsorshipSet/SET_DAILY_BUDGET');
