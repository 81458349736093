import { push } from 'connected-react-router';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { container } from '../../../container';

import {
  activateBasicAdFromCreationAsync,
  archiveBasicAdAsync,
  createBasicAdAsync,
  deactivateBasicAdFromCreationAsync,
  duplicateBasicAdAsync,
  setBasicAdEditId,
  setBasicAdFetching,
  submitBasicAdNewCharityAsync,
  unarchiveBasicAdAsync,
  updateBasicAdAsync,
} from './actions';

import { t } from '@transifex/native';
import { generatePath } from 'react-router';
import { toast } from 'react-toastify';
import { routes } from '../../../constants';
import { delay } from '../../../utils/manage';
import { basicAdAdSetsSelector, setBasicAdCampaignIsDirty } from '../../basicAdData';
import { toggleModalByName, toggleSavingToast } from '../../ui';
import { setManageIsFetching } from '../../manage';
import { setCampaignIsArchive, setCampaignIsUnarchive } from '../../manageBasicAds';

const basicAdService = container.get('BasicAdService');

function* createBasicAdSaga({ payload }) {
  try {
    const {
      name,
      category,
      campaignBrandName,
      campaignBrandLogoUri,
      campaignBrandLogoFile,
      selectedCharity,
    } = payload;
    const { id: charityId } = selectedCharity;

    yield put(toggleSavingToast(true));

    const { id } = yield call(basicAdService.createBasicAdCampaign, {
      name,
      campaignBrandName,
      category,
      campaignBrandLogoUri,
      charityId,
      campaignBrandLogoFile,
    });

    yield put(createBasicAdAsync.success({ ...payload, id }));

    yield put(toggleSavingToast(false));
    yield put(setBasicAdEditId(id));

    yield put(
      push(
        generatePath(routes.BASIC_AD.SPONSORSHIP_SET_CREATE_MULTI, {
          cid: id,
          newIndex: 1,
        })
      )
    );

    yield put(setBasicAdCampaignIsDirty({ isDirty: false, form: payload }));
  } catch (error) {
    yield put(createBasicAdAsync.failure(error));
    yield put(setBasicAdCampaignIsDirty({ isDirty: true, form: payload }));
    yield put(toggleSavingToast(false));
  }
}
function* updateBasicAdSaga({ payload }) {
  try {
    const {
      name,
      category,
      campaignBrandName,
      campaignBrandLogoUri,
      campaignBrandLogoFile,
      selectedCharity,
    } = payload;
    const { id: charityId } = selectedCharity;
    yield put(toggleSavingToast(true));

    yield call(basicAdService.updateBasicAdCampaign, {
      campaignId: payload.campaignId,
      name,
      campaignBrandName,
      category,
      campaignBrandLogoUri,
      campaignBrandLogoFile,
      charityId,
    });

    yield put(updateBasicAdAsync.success({ ...payload, cid: payload.campaignId }));

    yield put(toggleSavingToast(false));
    yield put(setBasicAdEditId(payload.campaignId));

    const adSets = yield select(basicAdAdSetsSelector);
    const firstAdSetId = adSets[0].id;
    yield put(
      push(
        firstAdSetId !== 'new'
          ? generatePath(routes.BASIC_AD.SPONSORSHIP_SET_EDIT, {
              cid: payload.campaignId,
              ssid: adSets[0].id,
            })
          : generatePath(routes.BASIC_AD.SPONSORSHIP_SET_CREATE_MULTI, {
              cid: payload.campaignId,
              newIndex: 1,
            })
      )
    );

    yield put(setBasicAdCampaignIsDirty({ isDirty: false, form: payload }));
  } catch (error) {
    yield put(createBasicAdAsync.failure(error));
    yield put(setBasicAdCampaignIsDirty({ isDirty: true, form: payload }));
    yield put(toggleSavingToast(false));
  }
}

function* activateBasicAdSaga({ payload }) {
  const { campaignId } = payload;
  try {
    yield call(basicAdService.activateBasicAdCampaign, {
      campaignId,
    });

    yield put(activateBasicAdFromCreationAsync.success());
  } catch (error) {
    yield put(activateBasicAdFromCreationAsync.failure(error));
  }
}

function* deactivateBasicAdSaga({ payload }) {
  const { campaignId } = payload;

  try {
    yield call(basicAdService.deactivateBasicAdCampaign, {
      campaignId,
    });
    yield put(deactivateBasicAdFromCreationAsync.success());
  } catch (error) {
    yield put(deactivateBasicAdFromCreationAsync.failure(error));
  }
}

function* duplicateBasicAdSaga({ payload }) {
  try {
    yield put(setManageIsFetching(true));
    const response = yield call(basicAdService.duplicateBasicAdCampaign, {
      campaignId: payload,
    });

    toast.info(t('Campaign duplication in process...'), { containerId: 'top-center' });

    let status = '';

    let i = 0;

    while (i < 5) {
      let res = yield call(basicAdService.getBasicAdCampaignDuplicationStatus, {
        duplicateRequestId: response.requestId,
      });

      status = res.status;

      if (status === 'completed' || status === 'failed') break;
      i++;

      yield call(delay, 2000);
    }

    if (status === 'failed')
      toast.warn(t('Campaign duplication process failed.'), { containerId: 'top-center' });
    else {
      if (status === 'completed')
        toast.success(t('Campaign duplication process completed successfully.'), {
          containerId: 'top-center',
        });

      setTimeout(
        () =>
          toast.info(
            t(
              'The new campaign will be available in your campaign list sortly. Please check back later.'
            ),
            {
              containerId: 'top-center',
            }
          ),
        3000
      );
    }

    yield put(duplicateBasicAdAsync.success(response));
    yield put(setManageIsFetching(false));
  } catch (error) {
    console.log(error);
    yield put(duplicateBasicAdAsync.failure(error));
    yield put(setManageIsFetching(false));
    toast.warn(t('Unable to duplicate campaign.'), { containerId: 'top-center' });
  }
}

function* archiveBasicAdAsyncSaga({ payload }) {
  try {
    yield put(toggleModalByName({ name: 'default', value: false }));

    yield call(basicAdService.archiveBasicAdCampaign, { campaignId: payload.id });

    yield put(setCampaignIsArchive({ id: payload.id }));

    yield put(archiveBasicAdAsync.success());

    toast.success(t('Campaign archived successfully'), { containerId: 'top-center' });
  } catch (error) {
    yield put(archiveBasicAdAsync.failure(error));
  }
}

function* unarchiveBasicAdAsyncSaga({ payload }) {
  try {
    yield put(toggleModalByName({ name: 'default', value: false }));
    yield put(setManageIsFetching(true));

    yield call(basicAdService.unarchiveBasicAdCampaign, { campaignId: payload.id });
    yield put(unarchiveBasicAdAsync.success());

    yield put(setCampaignIsUnarchive({ id: payload.id }));

    yield put(setManageIsFetching(false));
    let toastText = t('Campaign unarchived successfully');
    if (payload.activateNext)
      toastText = t(
        'Campaign unarchived successfully, you can now visit your Active / Inactive sets to activate it.'
      );

    toast.success(toastText, { containerId: 'top-center' });
    if (payload.activateNext) {
    }
  } catch (error) {
    yield put(unarchiveBasicAdAsync.failure(error));
    yield put(setManageIsFetching(false));
  }
}

function* submitBasicAdNewCharitySaga({ payload }) {
  try {
    const { newCharityName, newCharityWebsite } = payload;

    yield put(setBasicAdFetching(true));

    yield call(basicAdService.submitBasicAdCampaignNewCharity, {
      newCharityName,
      newCharityWebsite,
    });
    yield put(submitBasicAdNewCharityAsync.success());

    yield put(setBasicAdFetching(false));

    yield put(toggleModalByName({ name: 'charity', value: false }));
  } catch (error) {
    yield put(submitBasicAdNewCharityAsync.failure(error));
  }
}

// function* getDuplicationStatusSaga({ payload }) {
//   try {
//     const response = yield call(campaignService.getDuplicationStatusSaga, {
//       duplicateRequestId: payload,
//     });
//     console.log(getDuplicationStatusSaga);
//     yield put(getDuplicationStatusAsync.success(response));

//     return response;
//   } catch (error) {
//     console.log(error);
//     yield put(getDuplicationStatusAsync.failure(error));
//   }
// }

export function* createBasicAdActionWatcher() {
  yield takeLatest(submitBasicAdNewCharityAsync.request.type, createBasicAdSaga);
  yield takeLatest(createBasicAdAsync.request.type, createBasicAdSaga);
  yield takeLatest(updateBasicAdAsync.request.type, updateBasicAdSaga);
  yield takeLatest(archiveBasicAdAsync.request.type, archiveBasicAdAsyncSaga);
  yield takeLatest(unarchiveBasicAdAsync.request.type, unarchiveBasicAdAsyncSaga);
  yield takeLatest(activateBasicAdFromCreationAsync.request.type, activateBasicAdSaga);
  yield takeLatest(deactivateBasicAdFromCreationAsync.request.type, deactivateBasicAdSaga);
  yield takeLatest(duplicateBasicAdAsync.request.type, duplicateBasicAdSaga);
  yield takeLatest(submitBasicAdNewCharityAsync.request.type, submitBasicAdNewCharitySaga);
}
