import config from '../config';
import { isLocalhost } from './isLocalhost';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';

export const setupErrorLogging = () => {
  if ((config.environment === 'production' || config.environment === 'uat') && !isLocalhost()) {
    Sentry.init({
      dsn: config.sentryDSN,
      environment: config.environment,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 0.05,
    });
  }
};
