import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AdCallToAction } from '../../../../../components/PagesContent/CreateContent/AdCallToAction';
import { AdNameAndCreative } from '../../../../../components/PagesContent/CreateContent/AdNameAndCreative';
import { ProgressCircle } from '../../../../../components/ProgressCircle';
import { ScrollToTopWrapper } from '../../../../../components/wrappers/ScrollToTopWrapper';

import { yupResolver } from '@hookform/resolvers/yup';

import { fragments, routes, sponsorshipConstants } from '../../../../../constants';

import {
  activateBasicAdSponsorshipFromCreationAsync,
  basicAdSponsorshipCreateSelector,
  createBasicAdSponsorshipAsync,
  deactivateBasicAdSponsorshipFromCreationAsync,
  resetBasicAdProgress,
  setBasicAdAdData,
  setBasicAdDataArray,
  setBasicAdSponsorshipData,
  setBasicAdUploadingVideo,
  toggleBasicAdActivateSponsorship,
  updateBasicAdSponsorshipAsync,
  watchBasicAdUploadStop,
} from '../../../../../store/basicAd/sponsorship';

import {
  modalsSelector,
  savingToastSelector,
  setStickyMenuItem,
  stateIsEqualSelector,
  toggleModalByName,
} from '../../../../../store/ui';

import { DevTool } from '@hookform/devtools';
import { useForm } from 'react-hook-form';
import { generatePath } from 'react-router';
import { useUnmount } from 'react-use';
import { Layout } from '../../../../../components/Layout';
import Modal from '../../../../../components/Modal/Modal';
import { StepButtons } from '../../../../../components/StepButtons';
import { defaultQuestions } from '../../../../../constants/sponsorship/sponsorship-data';
import { CREATE_CAMPAIGN_PAGE } from '../../../../../constants/test-ids/create-campaing-sections/create-campaign-sections';
import useBasicAdActiveForm from '../../../../../hooks/useBasicAdActiveForm/useBasicAdActiveForm';
import { useDirtyFields } from '../../../../../hooks/useDirtyFields';
import { useInfiniteScroll } from '../../../../../hooks/useInfiniteScroll';
import { useRouter } from '../../../../../hooks/useRouter';
import { basicAdIdSelector } from '../../../../../store/basicAd/campaign';
import {
  basicAdAdSetByAdIdSelector,
  basicAdAdSetsSelector,
  basicAdCampaignDataFetchingSelector,
  setBasicAdAdIsDirty,
} from '../../../../../store/basicAdData';
import {
  brandInfoSelector,
  fetchUniqueBasicSponsorshipNamesAsync,
  previousUniqueBasicSponsorshipNamesSelector,
} from '../../../../../store/brand';
import { capitalizeFirstLetter } from '../../../../../utils/formatting';
import { getVideoDurationPromise } from '../../../../../utils/media';
import preventSubmitOnEnter from '../../../../../utils/preventSubmitOnEnter';
import { processQuestions } from '../../../../../utils/receivedData';
import { constructTestId } from '../../../../../utils/test-ids';
import SponsorshipPlaceholder from '../../../../Placeholders/SponsorshipPlaceholder/SponsorshipPlaceholder';
import { validationSchema } from './validation/schema';
import { mediaTypeOptions } from '../../../../../constants/basicAd/data';

const SECTION = CREATE_CAMPAIGN_PAGE.AD_NAME_AND_CREATIVE;
const SponsorshipForm = () => {
  const dispatch = useDispatch();
  const { push, query } = useRouter();
  const adNameRef = useRef(null);
  const creativeRef = useRef(null);
  const ctaRef = useRef(null);

  const [adId] = useState(query.sid !== 'new' ? query.sid : null);
  const brand = useSelector(brandInfoSelector);

  const sidRef = useRef(query.sid);

  const { fetchedTags, brandName } = brand;

  const basicAdId = useSelector(basicAdIdSelector);
  const fetching = useSelector(basicAdCampaignDataFetchingSelector);
  const modals = useSelector(modalsSelector);
  const sponsorshipCreate = useSelector(basicAdSponsorshipCreateSelector);
  const stateStatus = useSelector(stateIsEqualSelector);
  const previousSponsorshipNames = useSelector(previousUniqueBasicSponsorshipNamesSelector);
  const saving = useSelector(savingToastSelector).isOpen;
  const allAdSets = useSelector(basicAdAdSetsSelector);
  const [videoNotice, setVideoNotice] = useState('');

  const adIsLocked = !sponsorshipCreate.isDraft;

  const [clickedOnSave, setClickedOnSave] = useState(false);

  const sponsorshipSetCreate = useSelector((state) =>
    basicAdAdSetByAdIdSelector(state, sponsorshipCreate?.id)
  );

  const {
    register,
    unregister,
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    trigger,
    watch,
    formState: { errors, dirtyFields, isDirty },
  } = useForm({
    defaultValues: {
      name: sponsorshipCreate.name,
      adCover: sponsorshipCreate.adCover,
      adVideo: sponsorshipCreate.adVideo,
      mediaType: sponsorshipCreate.mediaType ?? mediaTypeOptions[0],
      adVideoFile: sponsorshipCreate.adVideoFile,
      videoLength: sponsorshipCreate?.videoLength,
      questions: sponsorshipCreate.questions ?? processQuestions(defaultQuestions, brandName),
      ctaCoverFile: sponsorshipCreate.ctaCoverFile,
      ctaCover: sponsorshipCreate.ctaCover,
      ctaLink: sponsorshipCreate.ctaLink,
      ctaDiscount: sponsorshipCreate.ctaDiscount,
      thirdPartyTracking: sponsorshipCreate.thirdPartyTrackingScripts,
      ctaButtonText: 'Check it out',
      trackingUrl: sponsorshipCreate.trackingUrl,
      appToTrack: sponsorshipCreate.appToTrack,
      isVideoUploaded: sponsorshipCreate.isVideoUploaded,
      caption: sponsorshipCreate.caption,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(validationSchema),
    context: { brandRole: brand.role },
  });

  useEffect(() => {
    reset(
      {
        name: sponsorshipCreate.name,
        adCover: sponsorshipCreate.adCover,
        adVideo: sponsorshipCreate.adVideo,
        mediaType: sponsorshipCreate.mediaType ?? mediaTypeOptions[0],
        adVideoFile: sponsorshipCreate.adVideoFile,
        videoLength: sponsorshipCreate?.videoLength,
        questions: sponsorshipCreate.questions ?? processQuestions(defaultQuestions),
        thirdPartyTracking: sponsorshipCreate.thirdPartyTrackingScripts,
        ctaCoverFile: sponsorshipCreate.ctaCoverFile,
        ctaCover: sponsorshipCreate.ctaCover,
        ctaLink: sponsorshipCreate.ctaLink,
        ctaDiscount: sponsorshipCreate.ctaDiscount,
        ctaButtonText: sponsorshipCreate.ctaButtonText,
        trackingUrl: sponsorshipCreate.trackingUrl,
        appToTrack: sponsorshipCreate.appToTrack,
        isVideoUploaded: sponsorshipCreate.isVideoUploaded,
        caption: sponsorshipCreate.caption,
      },
      { keepDirtyValues: true, keepDirty: true }
    );
  }, [
    reset,
    sponsorshipCreate.name,
    sponsorshipCreate.isActive,
    sponsorshipCreate.adVideo,
    sponsorshipCreate.mediaType,
    sponsorshipCreate.adVideoFile,
    sponsorshipCreate?.videoLength,
    sponsorshipCreate.questions,
    sponsorshipCreate.ctaCover,
    sponsorshipCreate.adCover,
    sponsorshipCreate.appToTrack,
    sponsorshipCreate.ctaButtonText,
    sponsorshipCreate.ctaCoverFile,
    sponsorshipCreate.ctaDiscount,
    sponsorshipCreate.ctaLink,
    sponsorshipCreate.trackingUrl,
    sponsorshipCreate.isVideoUploaded,
    sponsorshipCreate.thirdPartyTrackingScripts,
    sponsorshipCreate.caption,
  ]);

  const { shouldSave, dirtyFieldsList } = useDirtyFields({
    storedDirtyFields: sponsorshipCreate?.dirtyFields,
    fieldNames: sponsorshipConstants.fieldNames,
    isDirty,
    formDirtyFields: dirtyFields,
  });

  const localTags = useMemo(() => fetchedTags, [fetchedTags]);

  // This runs when we switch to another entity or unmount (the component does unmount)
  useUnmount(() => {
    if (shouldSave && !clickedOnSave) {
      dispatch(
        setBasicAdAdIsDirty({
          isDirty: true,
          dirtyFields: { ...dirtyFields, ...sponsorshipCreate.dirtyFields },
          form: getValues(),
          adSetId: query.ssid,
          adId: sidRef.current || sponsorshipCreate?.id,
        })
      );
      dispatch(setBasicAdSponsorshipData({ name: 'id', value: '' }));
    }
    dispatch(setBasicAdSponsorshipData({ name: 'dirtyFields', value: {} }));
  });

  const submitForm = () => {
    setClickedOnSave(true);
    const payload = { ...getValues(), basicAdId };
    if (adId) {
      dispatch(
        updateBasicAdSponsorshipAsync.request({
          form: { ...payload, mediaType: payload.mediaType.value },
          basicAdId: query.cid,
          adSetId: query.ssid,
          adId: query.sid,
          dirtyFields: { ...dirtyFields, ...sponsorshipCreate.dirtyFields },
          localTags,
        })
      );
    } else
      dispatch(
        createBasicAdSponsorshipAsync.request({
          form: { ...payload, mediaType: payload.mediaType.value },
          basicAdId: query.cid,
          adSetId: query.ssid,
          adIndex: parseInt(query.newIndex) - 1,
          dirtyFields: { ...dirtyFields, ...sponsorshipCreate.dirtyFields },
          localTags,
        })
      );
    dispatch(toggleModalByName({ name: 'audienceTagsWarning', value: false }));
  };

  // Show warning only if
  // a) If its a new ad
  // b) if its the only ad in the campaign
  // c) Brand hasn't selected any tags
  // d) Brand hasn't created any tags

  useEffect(() => () => dispatch(setStickyMenuItem(null)), [dispatch]);

  const resetVideo = useCallback(() => {
    setValue('adVideo', '');
    setValue('adVideoFile', '');
    setValue('videoLength', '');
    setValue('isVideoUploaded', false);
  }, [setValue]);

  const handleSelect = useCallback(
    (name) => (value) => {
      dispatch(setBasicAdDataArray({ name, value }));
    },
    [dispatch]
  );

  const handleCloseProgressModal = useCallback(() => {
    dispatch(resetBasicAdProgress(0));
    dispatch(setBasicAdUploadingVideo(false));
    dispatch(watchBasicAdUploadStop({ status: '' }));
  }, [dispatch]);

  const handlePreviousStep = useCallback(() => {
    push(
      generatePath(routes.BASIC_AD.SPONSORSHIP_SET_EDIT, {
        cid: query.cid,
        ssid: query.ssid,
      }) + fragments.BUDGET
    );
  }, [query.ssid, query.cid, push]);

  const handleAdActivation = useCallback(
    (e) => {
      const checked = e.target.checked;
      dispatch(toggleBasicAdActivateSponsorship(checked));
      dispatch(
        checked
          ? activateBasicAdSponsorshipFromCreationAsync.request({
              campaignId: query.cid,
              adSetId: query.ssid,
              adId: sponsorshipCreate.id || query.sid,
            })
          : deactivateBasicAdSponsorshipFromCreationAsync.request({
              campaignId: query.cid,
              adSetId: query.ssid,
              adId: sponsorshipCreate.id || query.sid,
            })
      );
    },
    [dispatch, sponsorshipCreate.id, query.cid, query.sid, query.ssid]
  );

  useBasicAdActiveForm({
    type: 'ad',
    campaignId: query.cid,
    adSetId: query.ssid,
    adId: query.sid,
    adNewIndex: query.newIndex,
    allAdSets,
  });

  const {
    moreToFetch: moreNamesToFetch,
    fetchMore: fetchMoreNames,
    setKeyword: setNamesKeyword,
  } = useInfiniteScroll(fetchUniqueBasicSponsorshipNamesAsync.request, previousSponsorshipNames);

  const handleCloseVideoNoticeModal = useCallback(() => {
    setVideoNotice('');
    dispatch(toggleModalByName({ name: 'videoLengthNotification', value: false }));
  }, [dispatch, setVideoNotice]);

  const handleVideoDurationAndPricing = useCallback(
    (adVideoFile, adVideo) => {
      if (adVideoFile.name?.length > 0) {
        const durationPromise = getVideoDurationPromise(adVideoFile, adVideo);

        durationPromise().then((duration) => {
          if (duration < 5) {
            resetVideo();
            setVideoNotice(
              `The selected video is ${Math.floor(
                duration
              )} seconds long. Please choose a video with a minimum duration of 5 seconds.`
            );
            dispatch(toggleModalByName({ name: 'videoLengthNotification', value: true }));
          }
        });
      }
    },
    [dispatch, resetVideo]
  );

  return (
    <Layout>
      <DevTool control={control} />
      <form onSubmit={handleSubmit(submitForm)} onKeyDown={preventSubmitOnEnter}>
        {sponsorshipCreate.uploadingVideo && (
          <ProgressCircle
            title={`Uploading ${watch('mediaType').label}`}
            progress={sponsorshipCreate.progress}
            closeModal={handleCloseProgressModal}
          />
        )}
        {fetching ? (
          <SponsorshipPlaceholder />
        ) : (
          <ScrollToTopWrapper>
            <AdNameAndCreative
              fetchMoreSponsorshipNames={fetchMoreNames}
              moreSponsorshipNamesToFetch={moreNamesToFetch}
              setNamesKeyword={setNamesKeyword}
              brand={brand}
              modals={modals}
              sponsorshipCreate={sponsorshipCreate}
              stateStatus={stateStatus}
              toggleModalByName={toggleModalByName}
              sponsorshipSetCreate={sponsorshipSetCreate}
              previousSponsorshipNames={previousSponsorshipNames}
              setAdData={setBasicAdAdData}
              setValue={setValue}
              selectedMediaType={getValues().mediaType}
              errors={errors}
              control={control}
              register={register}
              trigger={trigger}
              handleAdActivation={handleAdActivation}
              adNameRef={adNameRef}
              creativeRef={creativeRef}
              adIsLocked={adIsLocked}
              dirtyFieldsList={dirtyFieldsList}
              handleVideoDurationAndPricing={handleVideoDurationAndPricing}
            />
            <AdCallToAction
              brand={brand}
              handleSelect={handleSelect}
              modals={modals}
              toggleModalByName={toggleModalByName}
              sponsorshipCreate={sponsorshipCreate}
              setStickyMenuItem={setStickyMenuItem}
              register={register}
              unregister={unregister}
              control={control}
              getValues={getValues}
              errors={errors}
              setValue={setValue}
              ctaRef={ctaRef}
              adIsLocked={adIsLocked}
            />
          </ScrollToTopWrapper>
        )}
        <StepButtons
          data-testid={constructTestId(SECTION, 'submit-button')}
          handlePreviousStep={handlePreviousStep}
          styling="review"
          saving={saving}
          isSubmitDisabled={!shouldSave}
        />
        {modals.videoLengthNotification.isOpen && (
          <Modal
            cancelCallback={handleCloseVideoNoticeModal}
            withSubmit
            customButtonCancelText={'Got it'}
            title={videoNotice}
          />
        )}
      </form>
    </Layout>
  );
};

export default SponsorshipForm;
