import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useRouter } from '../../../hooks/useRouter';

import { GridWrapper } from '../../../components/wrappers/GridWrapper';
import { Topbar } from '../../../components/Topbar';

import { brandInfoSelector, brandIsLoadingSelector, clearData } from '../../../store/brand';

import { eightAuth } from '../../../store/eightauth';
import { useT } from '@transifex/react';
import { clearState } from '../../../store/data';
import { clearCampaignDraft } from '../../../store/campaign/campaign';
import { clearSponsorshipSet } from '../../../store/campaign/sponsorshipSet';
import { clearSponsorship } from '../../../store/campaign/sponsorship';
import { clearSummary } from '../../../store/campaign/summary';
import { setCSVRequestId } from '../../../store/analytics';
import { removeFromLocalStorage } from '../../../utils/localStorage/localStorage';
import { clearBasicAdDraft } from '../../../store/basicAd/campaign';
import { clearBasicAdSponsorshipSet } from '../../../store/basicAd/sponsorshipSet';
import { clearBasicAdSponsorship } from '../../../store/basicAd/sponsorship';
import { clearBasicAdSummary } from '../../../store/basicAd/summary';
import { clearBasicAdState } from '../../../store/basicAdData';

const WithTopbarContainer = ({ children }) => {
  const brandInfo = useSelector(brandInfoSelector);
  const brandIsLoading = useSelector(brandIsLoadingSelector);

  const dispatch = useDispatch();

  const { location } = useRouter();

  const t = useT();

  const topbarRef = useRef();

  const handleLogout = useCallback(() => {
    dispatch(clearCampaignDraft());
    dispatch(clearSponsorshipSet());
    dispatch(clearSponsorship());
    dispatch(clearSummary());
    dispatch(clearState());
    dispatch(clearData());

    dispatch(clearBasicAdDraft());
    dispatch(clearBasicAdSponsorshipSet());
    dispatch(clearBasicAdSponsorship());
    dispatch(clearBasicAdSummary());
    dispatch(clearBasicAdState());

    dispatch(eightAuth.logoutAsync.request());
    dispatch(setCSVRequestId(''));
    removeFromLocalStorage('csvRequestId');
  }, [dispatch]);

  return (
    <GridWrapper>
      <Topbar
        location={location.pathname}
        homeName={t('Home')}
        name={brandInfo?.brandName}
        logoUri={brandInfo?.logoUri}
        logout={handleLogout}
        brandId={brandInfo?.id}
        brandEmail={brandInfo?.emailAddress}
        amount={brandInfo.balance}
        currencySymbol={brandInfo?.country?.currencySymbol}
        isInvoicePartner={brandInfo?.isInvoicePartner}
        topbarRef={topbarRef}
        accountStatus={brandInfo?.accountStatus}
        fetching={brandIsLoading}
      />
      {children}
    </GridWrapper>
  );
};

export default WithTopbarContainer;
