import { createSelector } from 'reselect';

const basicAdSummaryStateSelector = (state) => state.basicAd;

const basicAdSummaryInternalSelector = createSelector(
  basicAdSummaryStateSelector,
  (state) => state.summaryState
);

export const allBasicAdModulesActivatedSelector = createSelector(
  basicAdSummaryInternalSelector,
  (state) => state.allModulesActivated
);

export const basicAdSummaryFetchingSelector = createSelector(
  basicAdSummaryStateSelector,
  (state) => state.fetching
);

export const basicAdCampaignIsPublishingSelector = createSelector(
  basicAdSummaryStateSelector,
  (state) => state.fetching
);
