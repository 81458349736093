import { useT } from '@transifex/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, Redirect, Route, Switch } from 'react-router';
import { ToastContainer } from 'react-toastify';
import { Modal } from '../../../components/Modal';
import { AltSidebar } from '../../../components/Sidebars/AltSidebar';
import { AltPageWrapper } from '../../../components/wrappers/AltPageWrapper';
import { routes } from '../../../constants';
import { defaultbasicAdsPricingId } from '../../../constants/basicAd/data';
import { useRouter } from '../../../hooks/useRouter';
import { clearBasicAdDraft } from '../../../store/basicAd/campaign';
import {
  basicAdSponsorshipCreateSelector,
  clearBasicAdSponsorship,
} from '../../../store/basicAd/sponsorship';
import {
  basicAdSponsorshipSetCreateSelector,
  clearBasicAdSponsorshipSet,
} from '../../../store/basicAd/sponsorshipSet';
import { clearBasicAdSummary } from '../../../store/basicAd/summary';
import {
  basicAdCampaignDataFetching,
  clearBasicAdState,
  fetchBasicAdCampaignAsync,
} from '../../../store/basicAdData';
import {
  brandInfoSelector,
  charitiesSelector,
  fetchBasicAdPricingAsync,
  fetchCharitiesAsync,
  fetchInterestsAsync,
  fetchLocationsAsync,
  fetchPassionsAsync,
  fetchPixelStatsAsync,
  fetchTagsAsync,
  fetchedPassionsSelector,
  fetchedPixelStatsSelector,
  fetchedTagsSelector,
  interestsSelector,
  locationsSelector,
} from '../../../store/brand';
import { defaultModalContentSelector, modalsSelector, toggleModalByName } from '../../../store/ui';
import { removeFromLocalStorage } from '../../../utils/localStorage';
import { BasicCampaignMenu } from '../../BasicCampaignMenu';
import CampaignForm from '../CreateBasicAd/Campaign/CampaignForm/CampaignForm';
import { CampaignSummaryForm } from '../CreateBasicAd/CampaignSummary/CampaignSummaryForm';
import SponsorshipForm from '../CreateBasicAd/Sponsorship/SponsorshipForm/SponsorshipForm';
import SponsorshipSetForm from '../CreateBasicAd/SponsorshipSet/SponsorshitSetForm/SponsorshipSetForm';

const BasicAdSetUp = () => {
  const dispatch = useDispatch();
  const t = useT();

  const { query } = useRouter();
  const [campaignId] = useState(query.cid !== 'new' ? query.cid : null);

  const { id: sponsorshipSetId } = useSelector(basicAdSponsorshipSetCreateSelector);
  const { id: sponsorshipId } = useSelector(basicAdSponsorshipCreateSelector);
  const locations = useSelector(locationsSelector);
  const charities = useSelector(charitiesSelector);
  const interests = useSelector(interestsSelector);
  const tags = useSelector(fetchedTagsSelector);
  const pixelStats = useSelector(fetchedPixelStatsSelector);
  const passions = useSelector(fetchedPassionsSelector);
  const { basicAdsPricingId } = useSelector(brandInfoSelector);

  useEffect(() => {
    return () => {
      dispatch(clearBasicAdDraft());
      dispatch(clearBasicAdSponsorshipSet());
      dispatch(clearBasicAdSponsorship());
      dispatch(clearBasicAdSummary());
      dispatch(clearBasicAdState());

      removeFromLocalStorage('campaignId');
      removeFromLocalStorage('sponsorshipSetId');
      removeFromLocalStorage('sponsorshipId');
    };
  }, [dispatch]);

  useEffect(() => {
    if (!locations.length) dispatch(fetchLocationsAsync.request());
    if (!charities.length) dispatch(fetchCharitiesAsync.request());
    if (!tags.length) dispatch(fetchTagsAsync.request({ limit: 20, offset: 0, keyword: '' }));
    if (!interests.length) dispatch(fetchInterestsAsync.request());
    if (basicAdsPricingId !== defaultbasicAdsPricingId)
      dispatch(fetchBasicAdPricingAsync.request());
    if (!pixelStats.length) dispatch(fetchPixelStatsAsync.request());
    if (!passions.length) dispatch(fetchPassionsAsync.request());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (query.cid === 'new') {
      dispatch(basicAdCampaignDataFetching(false));
    }
  }, [query.cid, dispatch]);

  const handleUnmount = useCallback((e) => {
    const confirmationMessage =
      'Are you sure you want to leave this page? All your unsaved changes will be lost.';

    e.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
    return confirmationMessage;
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnmount);
    if (campaignId) dispatch(fetchBasicAdCampaignAsync.request(campaignId));
    return () => {
      window.removeEventListener('beforeunload', handleUnmount);
    };
  }, [campaignId, dispatch, handleUnmount]);

  const modals = useSelector(modalsSelector);
  const modalContent = useSelector(defaultModalContentSelector);

  const handleBlockLocation = useCallback(
    (nextLocation) => {
      if (nextLocation.pathname === '/')
        return t(
          'Are you sure you want to leave this page? All your unsaved changes will be lost.'
        );
      return true;
    },
    [t]
  );
  return (
    <>
      <AltSidebar>
        <BasicCampaignMenu />
      </AltSidebar>
      <AltPageWrapper>
        <Switch>
          <Route exact path={routes.BASIC_AD.CREATE_CAMPAIGN} component={CampaignForm} />
          <Route exact path={routes.BASIC_AD.EDIT_CAMPAIGN} component={CampaignForm} />
          <Route
            exact
            path={routes.BASIC_AD.SPONSORSHIP_SET_CREATE}
            render={(props) => <SponsorshipSetForm key={sponsorshipSetId} {...props} />}
          />
          <Route
            exact
            path={routes.BASIC_AD.SPONSORSHIP_SET_EDIT}
            render={(props) => <SponsorshipSetForm key={sponsorshipSetId} {...props} />}
          />
          <Route
            exact
            path={routes.BASIC_AD.SPONSORSHIP_SET_CREATE_MULTI}
            render={(props) => <SponsorshipSetForm key={sponsorshipSetId} {...props} />}
          />
          <Route
            exact
            path={routes.BASIC_AD.SPONSORSHIP_CREATE}
            render={(props) => <SponsorshipForm key={sponsorshipId} {...props} />}
          />
          <Route
            exact
            path={routes.BASIC_AD.SPONSORSHIP_EDIT}
            render={(props) => <SponsorshipForm key={sponsorshipId} {...props} />}
          />
          <Route
            exact
            path={routes.BASIC_AD.SPONSORSHIP_CREATE_MULTI}
            render={(props) => <SponsorshipForm key={sponsorshipId} {...props} />}
          />
          <Route path={routes.BASIC_AD.CAMPAIGN_SUMMARY} component={CampaignSummaryForm} />
          <Redirect from="*" to={routes.BASIC_AD.CREATE_CAMPAIGN} />
        </Switch>
      </AltPageWrapper>
      <ToastContainer
        enableMultiContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick
        draggable={false}
        limit={1}
        containerId="upload-success"
      />
      {modals.default.isOpen && (
        <Modal
          title={modalContent.title || t('Warning')}
          closeModal={() => dispatch(toggleModalByName({ name: 'default', value: false }))}
          customButtonText={modalContent.buttonText}
          actionCallback={modalContent.actionCallback}
          withSubmit={modalContent.withSubmit}
          customButtonCancelText={modalContent.cancelText}
        >
          {modalContent.text}
        </Modal>
      )}

      <Prompt message={handleBlockLocation} />
      <ToastContainer
        enableMultiContainer
        position="bottom-right"
        autoClose={false}
        hideProgressBar={true}
        closeOnClick={false}
        draggable={false}
        limit={1}
        containerId="unpublished-changes"
      />
    </>
  );
};

export default BasicAdSetUp;
