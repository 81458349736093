import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { routes } from '../../constants';
import { setActiveBasicAd } from '../../store/basicAd/campaign';
import { setBasicAdActiveAd } from '../../store/basicAd/sponsorship';
import { setBasicAdActiveAdSet } from '../../store/basicAd/sponsorshipSet';
import {
  basicAdCampaignDataFetchingSelector,
  basicAdCampaignSelector,
} from '../../store/basicAdData';
import { useRouter } from '../useRouter';

function useBasicAdActiveForm({
  type,
  campaignId,
  adSetId,
  adSetNewIndex,
  adId,
  adNewIndex,
  allAdSets,
}) {
  const campaign = useSelector(basicAdCampaignSelector);
  const dataFetching = useSelector(basicAdCampaignDataFetchingSelector);

  const { push } = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!dataFetching) {
      if (type === 'campaign') dispatch(setActiveBasicAd(campaign));
      else if (type === 'adSet') {
        let selectedAdSet = adSetId
          ? allAdSets.find((adSet) => adSet.id === adSetId)
          : allAdSets[parseFloat(adSetNewIndex) - 1];

        // in a case that we got a wrong ssid, automatically change to an existing one [create or edit]
        if (!selectedAdSet && adSetId && adSetId !== 'new') {
          push(generatePath(routes.BASIC_AD.CAMPAIGN_EDIT, { cid: campaignId }));
          return;
        }
        dispatch(setBasicAdActiveAdSet(selectedAdSet));
      } else if (type === 'ad') {
        // in a case that we got a wrong ssid, automatically change to an existing one [create or edit]
        const selectedAdSet = allAdSets.find((adSet) => adSet.id === adSetId);
        const selectedAd = adId
          ? selectedAdSet?.ads.find((ad) => ad.id === adId)
          : selectedAdSet.ads[parseFloat(adNewIndex) - 1];
        if (!selectedAdSet && adSetId && adSetId !== 'new') {
          push(
            generatePath(routes.BASIC_AD.CAMPAIGN_EDIT, {
              cid: campaignId,
            })
          );
          return;
        } else if (!selectedAd && adId && adId !== 'new') {
          push(
            generatePath(routes.BASIC_AD.SPONSORSHIP_SET_EDIT, {
              cid: campaignId,
              ssid: selectedAdSet.id,
            })
          );
          return;
        }

        selectedAd && dispatch(setBasicAdActiveAd(selectedAd));
      }
    }
  }, [
    adId,
    adSetId,
    allAdSets,
    campaign,
    dispatch,
    type,
    campaignId,
    push,
    dataFetching,
    adSetNewIndex,
    adNewIndex,
  ]);
}

export default useBasicAdActiveForm;
