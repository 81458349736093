import { createReducer, initialAsyncState } from 'eight.js.store-common';

import {
  activateBasicAdSponsorshipFromCreationAsync,
  checkBasicAdUploadStatusAsync,
  clearBasicAdSponsorship,
  deactivateBasicAdSponsorshipFromCreationAsync,
  fetchBasicAdUploadStatusAsync,
  resetBasicAdProgress,
  setBasicAdAdData,
  setBasicAdDataArray,
  setBasicAdUploadingVideo,
  setBasicAdLocalQuestionsTags,
  setBasicAdInternalFetching,
  setBasicAdSponsorshipIsReadOnly,
  toggleBasicAdActivateSponsorship,
  updateBasicAdSponsorshipAsync,
  uploadBasicAdVideoAsync,
  setBasicAdUploadPercentage,
  uploadBasicAdCoverAsync,
  watchBasicAdUploadStop,
  setBasicAdActiveAd,
  setBasicAdSponsorshipName,
  setBasicAdSponsorshipData,
  setBasicAdUpdatingSponsorship,
} from './actions';
import sponsorshipConstants from '../../../constants/basicAd/sponsorship-data';

const initialState = {
  ...initialAsyncState,
  sponsorshipCreate: sponsorshipConstants.initialState,
  sponsorshipEdit: {
    id: '',
  },
  error: '',
  fetching: false,
  updatingSponsorship: false,
};

export const basicAdSponsorshipReducer = createReducer('@@basicAdSponsorship', initialState, {
  [setBasicAdActiveAd]: ({ state, action }) => {
    // Remove dirtyFields and isDirty fields from object
    // Remove local tags from object
    const { dirtyFields, isDirty, index, ...sponsorship } = state.sponsorshipCreate;
    state.sponsorshipCreate = {
      ...sponsorship,
      ...action.payload,
      localTags: [],
    };
  },
  [setBasicAdUpdatingSponsorship]: ({ state, action }) => {
    state.updatingSponsorship = action.payload;
  },

  [activateBasicAdSponsorshipFromCreationAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [clearBasicAdSponsorship]: ({ state }) => {
    state.sponsorshipCreate = initialState.sponsorshipCreate;
    state.sponsorshipEdit = initialState.sponsorshipEdit;
  },

  [checkBasicAdUploadStatusAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [deactivateBasicAdSponsorshipFromCreationAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [fetchBasicAdUploadStatusAsync.success]: ({ state, action }) => {
    const { status } = action.payload;
    state.sponsorshipCreate.videoUploadStatus = status;
  },

  [fetchBasicAdUploadStatusAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [resetBasicAdProgress]: ({ state, action }) => {
    state.sponsorshipCreate.progress = action.payload;
  },

  [setBasicAdInternalFetching]: ({ state, action }) => {
    state.sponsorshipCreate.internalFetching = action.payload;
  },

  [setBasicAdAdData]: ({ state, action }) => {
    const { name, value } = action.payload;
    state.sponsorshipCreate[name] = value;
  },

  [setBasicAdDataArray]: ({ state, action }) => {
    const { name, value } = action.payload;
    state.sponsorshipCreate = {
      ...state.sponsorshipCreate,
      [name]: value,
    };
  },

  [setBasicAdLocalQuestionsTags]: ({ state, action }) => {
    state.sponsorshipCreate.localTags = action.payload;
  },

  [setBasicAdSponsorshipIsReadOnly]: ({ state, action }) => {
    state.sponsorshipCreate.isReadOnly = action.payload;
  },

  [setBasicAdUploadingVideo]: ({ state, action }) => {
    state.sponsorshipCreate.uploadingVideo = action.payload;
  },

  [toggleBasicAdActivateSponsorship]: ({ state, action }) => {
    state.sponsorshipCreate.isActive = action.payload;
  },

  [uploadBasicAdCoverAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [setBasicAdUploadPercentage]: ({ state, action }) => {
    state.sponsorshipCreate.progress = action.payload;
  },

  [updateBasicAdSponsorshipAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [uploadBasicAdVideoAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [watchBasicAdUploadStop]: ({ state, action }) => {
    const { status } = action.payload;
    state.sponsorshipCreate.videoUploadStatus = status;
  },

  [setBasicAdSponsorshipName]: ({ state, action }) => {
    state.sponsorshipCreate.name = action.payload;
  },

  [setBasicAdSponsorshipData]: ({ state, action }) => {
    const { name, value } = action.payload;
    state.sponsorshipCreate[name] = value;
  },
});
