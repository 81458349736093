import { t } from '@transifex/native';

const manageConstants = Object.freeze({
  campaigns: {
    noData: 'There are no Campaigns created yet',
    noSearchData: "Sorry, we couldn't find any campaigns",
    campaignsTableHeaders: [
      { title: 'Status' },
      { title: 'Campaign name' },
      { title: 'Delivery' },
      { title: 'Start Date' },
      { title: 'End Date' },
      { title: 'Total Budget' },
      { title: 'Amount Spent' },
      { title: 'Accepted' },
      { title: 'Completed' },
      { title: 'Clicks' },
      { title: 'CTR %' },
      { title: 'Conversion %' },
      { title: 'Frequency' },
      { title: 'Archive' },
    ],
    basicCampaignsTableHeaders: [
      { title: 'Status' },
      { title: 'Campaign name' },
      { title: 'Delivery' },
      { title: 'Start Date' },
      { title: 'End Date' },
      { title: 'Total Budget' },
      { title: 'Amount Spent' },
      { title: 'Impressions' },
      { title: 'Ad Completions' },
      { title: 'Clicks' },
      { title: 'CTR %' },
      { title: 'Conversion %' },
      { title: 'Frequency' },
      { title: 'Archive' },
    ],
    archivedCampaignsTableHeaders: [
      { title: 'Status' },
      { title: 'Campaign name' },
      { title: 'Delivery' },
      { title: 'Start Date' },
      { title: 'End Date' },
      { title: 'Total Budget' },
      { title: 'Amount Spent' },
      { title: 'Accepted' },
      { title: 'Completed' },
      { title: 'Clicks' },
      { title: 'CTR %' },
      { title: 'Converion %' },
      { title: 'Frequency' },
    ],
    archivedBasicCampaignsTableHeaders: [
      { title: 'Status' },
      { title: 'Campaign name' },
      { title: 'Delivery' },
      { title: 'Start Date' },
      { title: 'End Date' },
      { title: 'Total Budget' },
      { title: 'Amount Spent' },
      { title: 'Impressions' },
      { title: 'Ad Completions' },
      { title: 'Clicks' },
      { title: 'CTR %' },
      { title: 'Conversion %' },
      { title: 'Frequency' },
    ],
  },
  adSets: {
    noData: 'There are no Ad Sets created yet',
    noSearchData: "Sorry, we couldn't find any Ad sets",
    adSetsTableHeaders: [
      { title: 'Status' },
      { title: 'Ad Set Name' },
      { title: 'Delivery' },
      { title: 'Start Date' },
      { title: 'End Date' },
      { title: 'Budget' },
      { title: 'Amount Spent' },
      { title: 'Daily Budget' },
      { title: 'Accepted' },
      { title: 'Completed' },
      { title: 'Clicks' },
      { title: 'CTR %' },
      { title: 'Frequency' },
    ],
    basicAdSetsTableHeaders: [
      { title: 'Status' },
      { title: 'Ad Set Name' },
      { title: 'Delivery' },
      { title: 'Start Date' },
      { title: 'End Date' },
      { title: 'Budget' },
      { title: 'Amount Spent' },
      { title: 'Daily Budget' },
      { title: 'Impressions' },
      { title: 'Ad Completions' },
      { title: 'Clicks' },
      { title: 'CTR %' },
      { title: 'Conversion %' },
      { title: 'Frequency' },
    ],
  },
  ads: {
    noData: 'There are no Ads created yet',
    noSearchData: "Sorry, we couldn't find any Ads",
    adsTableHeaders: [
      { title: 'Status' },
      { title: 'Ad name' },
      { title: 'Delivery' },
      { title: 'Creative' },
      { title: 'Questions' },
      { title: 'CTA Tile' },
      { title: 'CTA Link' },
      { title: 'Accepted' },
      { title: 'Completed' },
      { title: 'Clicks' },
      { title: 'CTR %' },
      { title: 'Conversion %' },
      { title: 'Frequency' },
    ],
    basicAdsTableHeaders: [
      { title: 'Status' },
      { title: 'Ad name' },
      { title: 'Delivery' },
      { title: 'Creative' },
      { title: 'CTA Link' },
      { title: 'Impressions' },
      { title: 'Ad Completions' },
      { title: 'Clicks' },
      { title: 'CTR %' },
      { title: 'Conversion %' },
      { title: 'Frequency' },
    ],
  },
  itemsPerPage: [
    { name: '20 results', label: t('20 results per page'), value: 20 },
    { name: '30 results', label: t('30 results per page'), value: 30 },
    { name: '40 results', label: t('40 results per page'), value: 40 },
  ],
  statusItems: [
    {
      label: t('Active / Inactive'),
      value: 'activeInactive',
    },
    {
      label: t('Archived'),
      value: 'archived',
    },
  ],
});

export default manageConstants;
