import { createReducer, initialAsyncState } from 'eight.js.store-common';
import {
  activateBasicAdFromCreationAsync,
  clearBasicAdDraft,
  setBasicAdData,
  setBasicAdFetching,
  setBasicAdEditId,
  setBasicAdIsReadOnly,
  toggleActivateBasicAd,
  updateBasicAdAsync,
  deactivateBasicAdFromCreationAsync,
  archiveBasicAdAsync,
  unarchiveBasicAdAsync,
  setActiveBasicAd,
  setBasicAdName,
} from './actions';
import basicAdConstants from '../../../constants/basicAd/basicAd-data';

const initialState = {
  ...initialAsyncState,
  campaignCreate: basicAdConstants.initialState,
  campaignEdit: {
    id: '',
  },
  fetching: false,
  error: '',
};

export const basicAdReducer = createReducer('@@basicAd', initialState, {
  [setActiveBasicAd]: ({ state, action }) => {
    state.campaignCreate = { ...state.campaignCreate, ...action.payload };
  },

  [activateBasicAdFromCreationAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [clearBasicAdDraft]: ({ state }) => {
    state.campaignCreate = initialState.campaignCreate;
    state.campaignEdit = initialState.campaignEdit;
  },

  [deactivateBasicAdFromCreationAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [setBasicAdData]: ({ state, action }) => {
    const { name, value } = action.payload;
    state.campaignCreate[name] = value;
  },

  [updateBasicAdAsync.failure]: ({ state, action }) => {
    state.campaignEdit.error = action.payload;
  },

  [setBasicAdFetching]: ({ state, action }) => {
    state.fetching = action.payload;
  },

  [setBasicAdEditId]: ({ state, action }) => {
    state.campaignEdit.id = action.payload;
  },

  [setBasicAdIsReadOnly]: ({ state, action }) => {
    state.campaignCreate.isReadOnly = action.payload;
  },

  [toggleActivateBasicAd]: ({ state, action }) => {
    state.campaignCreate.isActive = action.payload;
  },

  [archiveBasicAdAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [unarchiveBasicAdAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [setBasicAdName]: ({ state, action }) => {
    state.campaignCreate.name = action.payload;
  },
});
