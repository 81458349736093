import { createAsyncAction, createAction } from 'eight.js.store-common';

// ASYNC

export const activateCampaignAsync = createAsyncAction('@@manageBasicAds/ACTIVATE_CAMPAIGN');

export const activateSponsorshipSetAsync = createAsyncAction(
  '@@manageBasicAds/ACTIVATE_SPONSORSHIP_SET'
);

export const activateSponsorshipAsync = createAsyncAction('@@manageBasicAds/ACTIVATE_SPONSORSHIP');

export const dismissNotificationAsync = createAsyncAction('@@manageBasicAds/DISMISS_NOTIFICATION');

export const deactivateSponsorshipAsync = createAsyncAction(
  '@@manageBasicAds/DEACTIVATE_SPONSORSHIP'
);

export const deactivateSponsorshipSetAsync = createAsyncAction(
  '@@manageBasicAds/DEACTIVATE_SPONSORSHIP_SET'
);
export const deactivateCampaignAsync = createAsyncAction('@@manageBasicAds/DEACTIVATE_CAMPAIGN');

export const fetchCampaignsAsync = createAsyncAction('@@manageBasicAds/FETCH_CAMPAIGNS_LIST');

export const fetchSponsorshipSetsAsync = createAsyncAction(
  '@@manageBasicAds/FETCH_SPONSORSHIP_SETS_LIST'
);

export const fetchSponsorshipsAsync = createAsyncAction('@@manageBasicAds/FETCH_SPONSORSHIPS_LIST');

export const fetchNotificationsAsync = createAsyncAction('@@manageBasicAds/FETCH_NOTIFICATIONS');

// SYNC

export const clearReceivedNotifications = createAction(
  '@@manageBasicAds/CLEAR_RECEIVED_NOTIFICATIONS'
);

export const fetchAllUnits = createAction('@@manageBasicAds/FETCH_ALL_UNITS');

export const removeNotificationFromArray = createAction(
  '@@manageBasicAds/REMOVE_NOTIFICATION_FROM_ARRAY'
);

export const setCampaignIsActive = createAction('@@manageBasicAds/SET_CAMPAIGN_IS_ACTIVE');

export const setCampaignIsArchive = createAction('@@manageBasicAds/SET_CAMPAIGN_IS_ARCHIVE');

export const setCampaignIsUnarchive = createAction('@@manageBasicAds/SET_CAMPAIGN_IS_UNARCHIVE');

export const setCampaignDeliveryStatus = createAction(
  '@@manageBasicAds/SET_CAMPAIGN_DELIVERY_STATUS'
);

export const setSponsorshipSetDeliveryStatus = createAction(
  '@@manageBasicAds/SET_SPONSORSHIP_SET_DELIVERY_STATUS'
);
export const setSponsorshipSetsDeliveryStatus = createAction(
  '@@manageBasicAds/SET_SPONSORSHIP_SETS_DELIVERY_STATUS'
);

export const setIsArchive = createAction('@@manageBasicAds/SET_IS_ARCHIVE');

export const setIsSearch = createAction('@@manageBasicAds/SET_IS_SEARCH');

export const setSponsorshipSetIsActive = createAction(
  '@@manageBasicAds/SET_SPONSORSHIP_SET_IS_ACTIVE'
);

export const setSponsorshipSetsAreActive = createAction(
  '@@manageBasicAds/SET_SPONSORSHIP_SETS_ARE_ACTIVE'
);

export const setSponsorshipIsActive = createAction('@@manageBasicAds/SET_SPONSORSHIP_IS_ACTIVE');

export const setSponsorshipsAreActive = createAction(
  '@@manageBasicAds/SET_SPONSORSHIPS_ARE_ACTIVE'
);

export const setSponsorshipDeliveryStatus = createAction(
  '@@manageBasicAds/SET_SPONSORSHIP_DELIVERY_STATUS'
);
export const setSponsorshipsDeliveryStatus = createAction(
  '@@manageBasicAds/SET_SPONSORSHIPS_DELIVERY_STATUS'
);

export const setManageIsFetching = createAction('@@manageBasicAds/SET_IS_FETCHING');

export const setCampaignsAreFetching = createAction('@@manageBasicAds/SET_CAMPAIGNS_ARE_FETCHING');

export const setAdSetsAreFetching = createAction('@@manageBasicAds/SET_AD_SETS_ARE_FETCHING');

export const setAdsAreFetching = createAction('@@manageBasicAds/SET_ADS_ARE_FETCHING');

export const setDataArray = createAction('@@manageBasicAds/SET_DATA_ARRAY');
