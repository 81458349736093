import config from '../../../config';

export class BasicAdSponsorshipSetService {
  constructor(fetchService) {
    this.fetchService = fetchService;
  }

  calculateDailyBudget = async ({ campaignId, startDate, endDate, budget }) => {
    const res = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v1/basic-ads/campaign/${campaignId}/ad-sets/daily-budget`,
      params: { startDate, endDate, budget: budget.replace(/,/g, '') },
      method: 'GET',
    });

    if (res.status !== 200 && res.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = res.status || 500;
      throw error;
    }

    return res.data.dailyBudget;
  };

  activateBasicAdSponsorshipSet = async ({ campaignId, adSetId }) => {
    const res = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v1/basic-ads/campaign/${campaignId}/ad-set/${adSetId}/activate`,
      method: 'PUT',
    });

    if (res.status !== 200 && res.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = res.status || 500;
      throw error;
    }

    return res.data;
  };

  deactivateBasicAdSponsorshipSet = async ({ campaignId, adSetId }) => {
    const res = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v1/basic-ads/campaign/${campaignId}/ad-set/${adSetId}/deactivate`,
      method: 'PUT',
    });

    if (res.status !== 200 && res.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = res.status || 500;
      throw error;
    }

    return res.data;
  };

  createBasicAdSponsorshipSet = async ({
    basicAdCampaignId,
    name,
    isEightPixelEnabled,
    pixelConversionEvent,
    includeLocations,
    ageRanges,
    genders,
    eightPixelAudiences,
    deviceType,
    startDate,
    endDate,
    totalBudget,
    interests,
    passions,
    frequencyCap,
    timezoneId,
    timezoneInfo,
  }) => {
    const additionalOtions = isEightPixelEnabled && { pixelConversionEvent };

    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v1/basic-ads/campaign/${basicAdCampaignId}/ad-set`,
      method: 'POST',
      bodyType: 'json',
      body: {
        name,
        isEightPixelEnabled,
        pixelConversionEvent,
        includeLocations,
        ageRanges,
        genders,
        eightPixelAudiences,
        deviceType,
        startDate,
        endDate,
        totalBudget,
        interests,
        passions,
        frequencyCap,
        timezoneId,
        timezoneInfo,
        ...additionalOtions,
      },
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }

    return response.data;
  };

  updateBasicAdSponsorshipSet = async ({
    basicAdCampaignId,
    adsetId,
    name,
    isEightPixelEnabled,
    pixelConversionEvent,
    includeLocations,
    ageRanges,
    genders,
    eightPixelAudiences,
    deviceType,
    startDate,
    endDate,
    totalBudget,
    interests,
    passions,
    frequencyCap,
    timezoneInfo,
    timezoneId,
  }) => {
    const additionalOtions = isEightPixelEnabled && { pixelConversionEvent };

    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v1/basic-ads/campaign/${basicAdCampaignId}/ad-set/${adsetId}`,
      method: 'PUT',
      bodyType: 'json',
      body: {
        name,
        ageRanges,
        genders,
        isEightPixelEnabled,
        includeLocations,
        interests,
        passions,
        eightPixelAudiences,
        startDate,
        endDate,
        timezoneId,
        timezoneInfo,
        totalBudget,
        deviceType,
        frequencyCap,
        ...additionalOtions,
      },
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }

    return response.data;
  };
}
