import { call, put, select, takeLatest } from 'redux-saga/effects';

import { container } from '../../../container';

import {
  activateAllBasicAdAsync,
  activateBasicAdCampaignFromBilling,
  publishBasicAdCampaignAsync,
  setAllBasicAdModulesActivated,
  setBasicAdIsPublishing,
  updateBasicAdBrandInfoFromCampaignSummaryAsync,
} from './actions';

import { setBasicAdData, setBasicAdIsReadOnly, toggleActivateBasicAd } from '../campaign';

import {
  setBasicAdSponsorshipSetData,
  setBasicAdSponsorshipSetIsReadOnly,
  toggleBasicAdActivateSponsorshipSet,
} from '../sponsorshipSet';

import {
  basicAdSponsorshipCreateSelector,
  setBasicAdAdData,
  setBasicAdSponsorshipIsReadOnly,
  toggleBasicAdActivateSponsorship,
} from '../sponsorship';

import { setModalContent, setModalText, toggleModalByName } from '../../ui';

import { updateBrandInfoFromBillingAsync } from '../../brand';

import { t } from '@transifex/native';
import { toast } from 'react-toastify';
import { textsConstants } from '../../../constants';
import { setBasicAdCampaignIsPublished } from '../../basicAdData';
import { campaignSelector, setUnpublishedDifferences } from '../../data';

const basicAdSponsorshipService = container.get('BasicAdSponsorshipService');

function* activateAllBasicAdModulesSaga() {
  try {
    const { id: campaignId } = yield select(campaignSelector);
    const { moderationStatus } = yield select(basicAdSponsorshipCreateSelector);
    yield call(basicAdSponsorshipService.publishBasicAdCampaign, {
      campaignId,
    });

    yield put(activateAllBasicAdAsync.success());

    if (moderationStatus !== 'In Review' || moderationStatus !== 'Approved') {
      yield put(
        setModalContent({
          name: 'summary',
          content: {
            title: 'Success',
            text: t(textsConstants.publishSuccess),
          },
        })
      );
      yield put(toggleModalByName({ name: 'summary', value: true }));
      yield put(setBasicAdAdData({ name: 'deliveryStatus', value: 'In review' }));
      yield put(setBasicAdAdData({ name: 'moderationStatus', value: 'In review' }));
    }

    yield put(toggleActivateBasicAd(true));
    yield put(setBasicAdIsReadOnly(true));
    yield put(setBasicAdData({ name: 'deliveryStatus', value: 'In review' }));
    yield put(setBasicAdData({ name: 'moderationStatus', value: 'In review' }));

    yield put(toggleBasicAdActivateSponsorshipSet(true));
    yield put(setBasicAdSponsorshipSetIsReadOnly(true));
    yield put(setBasicAdSponsorshipSetData({ name: 'deliveryStatus', value: 'In review' }));
    yield put(setBasicAdSponsorshipSetData({ name: 'moderationStatus', value: 'In review' }));

    yield put(toggleBasicAdActivateSponsorship(true));
    yield put(setBasicAdSponsorshipIsReadOnly(true));

    yield put(setAllBasicAdModulesActivated(true));
  } catch (error) {
    yield put(
      setModalText({
        name: 'errors',
        text: `- ${error?.response?.data?.message}`,
      })
    );
    yield put(toggleModalByName({ name: 'errors', value: true }));
    yield put(activateAllBasicAdAsync.failure(error));
    yield put(setAllBasicAdModulesActivated(false));
  }
}

function* publishBasicAdCampaignSaga({ payload }) {
  try {
    yield put(setBasicAdIsPublishing(true));

    // const { id: campaignId } = yield select(campaignSelector);
    const campaignId = payload;

    yield call(basicAdSponsorshipService.publishBasicAdCampaign, {
      campaignId,
    });

    yield put(publishBasicAdCampaignAsync.success());
    yield put(setBasicAdIsPublishing(false));

    yield put(
      setModalContent({
        name: 'success',
        title: 'Success',
        text: t(textsConstants.publishSuccess),
        withoutCancel: true,
      })
    );

    yield put(setUnpublishedDifferences([]));
    yield put(setBasicAdCampaignIsPublished());
    toast.dismiss();

    yield put(toggleModalByName({ name: 'success', value: true }));
  } catch (error) {
    yield put(setBasicAdIsPublishing(false));
    if (error?.response?.data.code === 100) {
      yield put(
        setModalContent({
          name: 'summary',
          content: {
            title: 'Info',
            text: `There are no new changes on your Ad sets or Ads that need to be published.`,
            withoutCancel: true,
          },
        })
      );
      yield put(toggleModalByName({ name: 'summary', value: true }));
    }
    if (error?.response?.data.code === 60) {
      yield put(
        setModalContent({
          name: 'summary',
          content: {
            title: 'Error',
            text: `Your available balance is not enough to deliver the minimum number of ads.`,
            withoutCancel: true,
          },
        })
      );
      yield put(toggleModalByName({ name: 'summary', value: true }));
    }
    if (error?.response?.data.code === 110) {
      yield put(
        setModalContent({
          name: 'summary',
          content: {
            title: 'Error',
            text: error?.response?.data.message,
            withoutCancel: true,
          },
        })
      );
      yield put(toggleModalByName({ name: 'summary', value: true }));
    }

    yield put(publishBasicAdCampaignAsync.failure(error));
  }
}

function* updateBasicAdBrandInfoFromCampaignSummarySaga({ payload }) {
  try {
    yield put(updateBrandInfoFromBillingAsync.request({ ...payload, isCampainActivation: true }));
  } catch {
    yield put(updateBasicAdBrandInfoFromCampaignSummaryAsync.failure());
  }
}

export function* basicAdSummaryActionWatcher() {
  yield takeLatest(publishBasicAdCampaignAsync.request.type, publishBasicAdCampaignSaga);
  yield takeLatest(activateBasicAdCampaignFromBilling, activateAllBasicAdModulesSaga);
  yield takeLatest(
    updateBasicAdBrandInfoFromCampaignSummaryAsync.request.type,
    updateBasicAdBrandInfoFromCampaignSummarySaga
  );
}
