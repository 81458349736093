import { push } from 'connected-react-router';
import { call, delay, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { container } from '../../../container';

import {
  activateBasicAdSponsorshipFromCreationAsync,
  checkBasicAdUploadStatusAsync,
  createBasicAdSponsorshipAsync,
  deactivateBasicAdSponsorshipFromCreationAsync,
  fetchBasicAdUploadStatusAsync,
  resetBasicAdProgress,
  setBasicAdInternalFetching,
  setBasicAdUpdatingSponsorship,
  setBasicAdUploadingVideo,
  updateBasicAdSponsorshipAsync,
  uploadBasicAdMedia,
  uploadBasicAdVideoAsync,
  uploadBasicAdVideoStart,
  watchBasicAdUploadStop,
} from './actions';

import { basicAdSponsorshipCreateSelector } from './selectors';

import { t } from '@transifex/native';
import { generatePath } from 'react-router';
import { toast } from 'react-toastify';
import UnpublishedChangesToastContent from '../../../components/UnpublishChangesToastContent/UnpublishedChangesToastContent';
import { routes } from '../../../constants';
import { processDifferences, processMediaType } from '../../../utils/receivedData';
import { setBasicAdAdIsDirty } from '../../basicAdData';
import { setUnpublishedDifferences, unpublishedChangesSelector } from '../../data';
import { toggleSavingToast, toggleUploadingToast } from '../../ui';

const basicAdSponsorshipService = container.get('BasicAdSponsorshipService');

function* createBasicAdSponsorshipSaga({ payload }) {
  const differences = yield select(unpublishedChangesSelector);
  try {
    const {
      name,
      adVideo,
      adVideoFile,
      adImageFile,
      contentType,
      videoLength,
      ctaLink,
      thirdPartyTracking,
      ctaButtonText,
      ctaCoverFile,
      mediaType,
      ctaCover,
      isVideoUploaded,
      imageDimensions,
      caption,
    } = payload.form;
    const { localTags, adSetId, adIndex } = payload;

    let additionalData = {};
    yield put(setBasicAdUpdatingSponsorship(true));
    yield put(toggleSavingToast(true));

    if (adVideoFile?.name && !isVideoUploaded) {
      additionalData = {
        ...additionalData,
        adVideoPreSignedUrlRequest: {
          originalFileName: adVideoFile?.name,
          videoLength,
        },
      };
    }

    const response = yield call(basicAdSponsorshipService.createBasicAdSponsorship, {
      campaignId: payload.basicAdId,
      adSetId: payload.adSetId,
      adId: payload.adId,
      name,
      ctaUrl: ctaLink,
      ctaButtonText: ctaButtonText ?? 'Check it out!',
      mediaType,
      contentType,
      mediaFileName: adVideoFile?.name ?? adImageFile,
      mediaHeight: mediaType === 'image' ? parseInt(imageDimensions.height) : null,
      mediaWidth: mediaType === 'image' ? parseInt(imageDimensions.width) : null,
      videoLength: mediaType === 'video' ? videoLength : null,
      thirdPartyTrackingScripts: thirdPartyTracking,
      caption,
      ctaCover: {
        media: ctaCoverFile,
        mediaType: ctaCoverFile.type,
        fileUrl: ctaCover,
      },
      ctaCoverFileName: ctaCoverFile.name,
      additionalData,
    });

    if (response.unpublishedDifferences) {
      const processedNewDifferences = processDifferences([response.unpublishedDifferences]);
      const filteredPrevDifferences = differences.filter((diff) => diff.id !== response.id);
      yield put(
        setUnpublishedDifferences([...filteredPrevDifferences, ...processedNewDifferences])
      );
      toast(<UnpublishedChangesToastContent />, {
        containerId: 'unpublished-changes',
        toastId: 'unpublished-toast',
        closeButton: false,
      });
    } else if (differences.length) {
      yield put(setUnpublishedDifferences(differences.filter((diff) => diff.id !== response.id)));
    }

    yield put(setBasicAdUpdatingSponsorship(false));

    if ((adVideoFile?.name && !isVideoUploaded) || adImageFile) {
      yield put(
        uploadBasicAdVideoStart({
          campaignId: payload.basicAdId,
          sponsorshipSetId: payload.adSetId,
          sponsorshipId: response.id,
          adFile: adVideoFile ?? adImageFile,
          adVideo,
          videoLength,
          presignedUrl: response.presignedUrl,
          videoKey: response.key,
          mediaType,
        })
      );
    } else {
      yield put(
        push(
          generatePath(routes.BASIC_AD.CAMPAIGN_SUMMARY, {
            cid: payload.basicAdId,
          })
        )
      );
    }

    yield put(toggleSavingToast(false));

    // localTags property triggers brand's store "updateSponsorshipAsync" reducer
    // its purpose is to update localTags locally whenever we save an ad
    const adToStore = {
      form: {
        ...payload.form,
        mediaType: processMediaType(payload.form.mediaType),
        adVideo: URL.createObjectURL(adVideoFile),
        videoLength,
        adVideoFile: {},
        ctaCover: ctaCover || response.ctaImagePreSignedUrlDetail?.imageUri,
        thirdPartyTrackingScripts: thirdPartyTracking ?? [],
      },
      adIndex,
      isDirty: false,
      sponsorshipSetId: adSetId,
      sponsorshipId: response.id,
      localTags,
    };

    yield put(createBasicAdSponsorshipAsync.success(adToStore));

    // yield put(
    //   setBasicAdAdIsDirty({
    //     isDirty: false,
    //     form: {
    //       ...payload.form,
    //       id: response.id,
    //       mediaType: processMediaType(payload.form.mediaType),
    //       adVideo,
    //       videoLength,
    //       ctaCover: ctaCover || response.ctaImagePreSignedUrlDetail?.imageUri,
    //       thirdPartyTrackingScripts: thirdPartyTracking ?? [],
    //     },
    //     adSetId: payload.adSetId,
    //     adId: response.id,
    //     dirtyFields: [],
    //   })
    // );
  } catch (error) {
    const { sponsorshipId, sponsorshipSetId } = payload;

    yield put(createBasicAdSponsorshipAsync.failure(error));
    yield put(setBasicAdUpdatingSponsorship(false));

    yield put(
      setBasicAdAdIsDirty({
        isDirty: true,
        form: payload.form,
        adSetId: sponsorshipSetId,
        adId: sponsorshipId,
        dirtyFields: payload.dirtyFields,
      })
    );
    yield put(setBasicAdInternalFetching(false));
    yield put(toggleSavingToast(false));
  }
}

function* updateBasicAdSponsorshipSaga({ payload }) {
  yield put(fetchBasicAdUploadStatusAsync.success({ status: 'complete' }));
  yield put(watchBasicAdUploadStop({ status: 'complete' }));
  const differences = yield select(unpublishedChangesSelector);
  try {
    const {
      name,
      adVideo,
      adVideoFile,
      videoLength,
      contentType,
      ctaLink,
      mediaType,
      thirdPartyTracking,
      ctaButtonText,
      ctaCoverFile,
      ctaCover,
      adImageFile,
      imageDimensions,
      isVideoUploaded,
      caption,
    } = payload.form;
    let additionalData = {};

    const { adSetId, adId } = payload;

    yield put(setBasicAdUpdatingSponsorship(true));
    yield put(toggleSavingToast(true));

    if (adVideoFile?.name && !isVideoUploaded) {
      additionalData = {
        ...additionalData,
        adVideoPreSignedUrlRequest: {
          originalFileName: adVideoFile?.name,
          videoLength,
        },
      };
    }
    let response;
    try {
      response = yield call(basicAdSponsorshipService.updateBasicAdSponsorship, {
        campaignId: payload.basicAdId,
        adSetId: payload.adSetId,
        adId: payload.adId,
        name,
        ctaUrl: ctaLink,
        ctaButtonText: ctaButtonText ?? 'Check it out!',
        mediaType,
        contentType,
        mediaFileName: adVideoFile?.name ?? adImageFile,
        mediaHeight:
          mediaType === 'image' && imageDimensions?.height
            ? parseInt(imageDimensions.height)
            : null,
        mediaWidth:
          mediaType === 'image' && imageDimensions?.width ? parseInt(imageDimensions.width) : null,
        videoLength: mediaType === 'video' ? videoLength : null,
        thirdPartyTrackingScripts: thirdPartyTracking,
        ctaCover: {
          media: ctaCoverFile,
          mediaType: ctaCoverFile.type,
          fileUrl: ctaCover,
        },
        ctaCoverFileName: ctaCoverFile.name,
        caption,
        additionalData,
      });
    } catch (error) {
      console.error(error);
    }

    // if (response.unpublishedDifferences) {
    //   const processedNewDifferences = processDifferences([response.unpublishedDifferences]);
    //   const filteredPrevDifferences = differences.filter((diff) => diff.id !== adId);
    //   yield put(
    //     setUnpublishedDifferences([...filteredPrevDifferences, ...processedNewDifferences])
    //   );
    //   toast(<UnpublishedChangesToastContent />, {
    //     containerId: 'unpublished-changes',
    //     toastId: 'unpublished-toast',
    //     closeButton: false,
    //   });
    // } else if (differences.length) {
    //   yield put(setUnpublishedDifferences(differences.filter((diff) => diff.id !== adId)));
    // }

    yield put(setBasicAdUpdatingSponsorship(false));

    if ((adVideoFile?.name && !isVideoUploaded) || adImageFile) {
      yield put(
        uploadBasicAdVideoStart({
          campaignId: payload.basicAdId,
          sponsorshipSetId: payload.adSetId,
          sponsorshipId: payload.adId,
          adFile: adVideoFile ?? adImageFile,
          adVideo,
          videoLength,
          presignedUrl: response.preSignedUrl,
          videoKey: response.key,
          mediaType,
        })
      );
    } else {
      yield put(
        push(
          generatePath(routes.BASIC_AD.CAMPAIGN_SUMMARY, {
            cid: payload.basicAdId,
          })
        )
      );
    }

    yield put(toggleSavingToast(false));
    // localTags property triggers brand's store "updateSponsorshipAsync" reducer
    // its purpose is to update localTags locally whenever we save an ad
    const adToStore = {
      form: {
        ...payload.form,
        mediaType: processMediaType(payload.form.mediaType),
        adVideo:
          adVideoFile && Object.keys(adVideoFile).length
            ? URL.createObjectURL(adVideoFile)
            : adVideo,
        videoLength,
        adVideoFile: undefined,
        ctaCover: ctaCover || response?.ctaImagePreSignedUrlDetail?.imageUri || '',
        thirdPartyTrackingScripts: thirdPartyTracking ?? [],
      },
      isDirty: false,
      sponsorshipSetId: adSetId,
      sponsorshipId: payload.adId,
    };

    yield put(updateBasicAdSponsorshipAsync.success(adToStore));

    // yield put(
    //   setBasicAdAdIsDirty({
    //     isDirty: false,
    //     form: {
    //       ...payload.form,
    //       mediaType: processMediaType(payload.form.mediaType),
    //       adVideo,
    //       videoLength,
    //       ctaCover: ctaCover || response.ctaImagePreSignedUrlDetail?.imageUri,
    //       thirdPartyTrackingScripts: thirdPartyTracking ?? [],
    //     },
    //     adSetId: payload.adSetId,
    //     adId: payload.form.id,
    //     dirtyFields: [],
    //   })
    // );
  } catch (error) {
    const { sponsorshipId, sponsorshipSetId } = payload;

    yield put(updateBasicAdSponsorshipAsync.failure(error));
    yield put(setBasicAdUpdatingSponsorship(false));

    yield put(
      setBasicAdAdIsDirty({
        isDirty: true,
        form: payload.form,
        adSetId: sponsorshipSetId,
        adId: sponsorshipId,
        dirtyFields: payload.dirtyFields,
      })
    );
    yield put(setBasicAdInternalFetching(false));
    yield put(toggleSavingToast(false));
  }
}

function* uploadBasicAdMediaSaga({ payload }) {
  yield put(uploadBasicAdVideoStart({ ...payload }));
}

function* uploadBasicAdVideoSaga({ payload }) {
  try {
    const {
      adFile,
      adVideo,
      videoLength,
      campaignId,
      sponsorshipSetId,
      sponsorshipId,
      presignedUrl,
      videoKey,
      mediaType,
    } = payload;

    if (adFile.name) {
      yield put(setBasicAdUploadingVideo(true));

      yield call(basicAdSponsorshipService.uploadBasicAdVideo, {
        campaignId,
        sponsorshipSetId,
        sponsorshipId,
        media: adFile,
        mediaType: adFile.type,
        adVideo,
        videoLength,
        presignedUrl,
      });

      if (videoKey) {
        yield put(setBasicAdUploadingVideo(false));
        yield put(resetBasicAdProgress(0));
        yield put(
          uploadBasicAdVideoAsync.success({ adSetId: sponsorshipSetId, adId: sponsorshipId })
        );

        yield put(
          push(
            generatePath(routes.BASIC_AD.CAMPAIGN_SUMMARY, {
              cid: campaignId,
            })
          )
        );

        yield call(watchBasicAdUploadStatus, {
          campaignId,
          sponsorshipSetId,
          sponsorshipId,
          mediaType,
        });
      }
    }
  } catch (error) {
    yield put(uploadBasicAdVideoAsync.failure(error));
    yield put(setBasicAdUploadingVideo(false));
    yield put(resetBasicAdProgress(0));
  }
}

function* checkBasicAdUploadStatusSaga() {
  try {
    const { videoKey } = yield select(basicAdSponsorshipCreateSelector);
    const encodedVideoKey = encodeURIComponent(videoKey);
    yield call(watchBasicAdUploadStatus, encodedVideoKey);
  } catch (error) {
    yield put(checkBasicAdUploadStatusAsync.failure(error));
  }
}

function* watchBasicAdUploadStatus(payload) {
  let videoStatus = '';

  yield put(toggleUploadingToast(true));

  while (videoStatus !== 'complete') {
    yield put(fetchBasicAdUploadStatusAsync.request(payload));

    const { success } = yield race({
      success: take(fetchBasicAdUploadStatusAsync.success.type),
      failure: take(fetchBasicAdUploadStatusAsync.failure.type),
    });

    if (success) {
      videoStatus = success.payload.status;
    }

    yield delay(1000);
  }
}

function* fetchBasicAdUploadStatusSaga({ payload }) {
  const isVideo = payload.mediaType === 'video';
  try {
    const { status, imageProcessed } = yield call(
      isVideo
        ? basicAdSponsorshipService.getBasicAdVodInfoStatus
        : basicAdSponsorshipService.getBasicAdImageInfoStatus,
      payload
    );

    if (isVideo) {
      if (status === 'Ingest') {
        yield put(toggleUploadingToast(true));
        yield put(fetchBasicAdUploadStatusAsync.success({ status: 'processing' }));
      }

      if (status === 'Complete') {
        yield put(fetchBasicAdUploadStatusAsync.success({ status: 'complete' }));
        yield put(watchBasicAdUploadStop({ status: 'complete' }));
        yield put(toggleUploadingToast(false));
        toast.success('Video processing completed successfully!', {
          containerId: 'upload-success',
        });
      }
    } else {
      if (!imageProcessed) {
        yield put(toggleUploadingToast(true));
        yield put(fetchBasicAdUploadStatusAsync.success({ status: 'processing' }));
      } else {
        yield put(fetchBasicAdUploadStatusAsync.success({ status: 'complete' }));
        yield put(watchBasicAdUploadStop({ status: 'complete' }));
        yield put(toggleUploadingToast(false));
        toast.success('Image processing completed successfully!', {
          containerId: 'upload-success',
        });
      }
    }
  } catch (error) {
    if (error.response?.status === 404) {
      yield put(watchBasicAdUploadStop({ status: 'processing' }));
      yield put(fetchBasicAdUploadStatusAsync.failure(error));
    } else {
      yield put(fetchBasicAdUploadStatusAsync.failure(error));
      yield put(watchBasicAdUploadStop({ status: 'failed' }));
      toast.error(t('Video/Image processing failed!'), {
        containerId: 'upload-success',
      });
    }
  }
}

function* activateBasicAdSponsorshipSaga({ payload }) {
  try {
    const { campaignId, adSetId, adId } = payload;

    yield call(basicAdSponsorshipService.activateBasicAdSponsorship, {
      campaignId,
      adSetId,
      adId,
    });

    yield put(
      activateBasicAdSponsorshipFromCreationAsync.success({
        campaignId,
        adSetId,
        adId,
      })
    );
  } catch (error) {
    yield put(activateBasicAdSponsorshipFromCreationAsync.failure(error));
  }
}

function* deactivateBasicAdSponsorshipSaga({ payload }) {
  try {
    const { campaignId, adSetId, adId } = payload;

    yield call(basicAdSponsorshipService.deactivateBasicAdSponsorship, {
      campaignId,
      adSetId,
      adId,
    });

    yield put(
      deactivateBasicAdSponsorshipFromCreationAsync.success({
        campaignId,
        adSetId,
        adId,
      })
    );
  } catch (error) {
    yield put(activateBasicAdSponsorshipFromCreationAsync.failure(error));
  }
}

export function* sponsorshipBasicAdActionWatcher() {
  yield takeLatest(
    activateBasicAdSponsorshipFromCreationAsync.request.type,
    activateBasicAdSponsorshipSaga
  );
  yield takeLatest(checkBasicAdUploadStatusAsync.request.type, checkBasicAdUploadStatusSaga);
  yield takeLatest(
    deactivateBasicAdSponsorshipFromCreationAsync.request.type,
    deactivateBasicAdSponsorshipSaga
  );
  yield takeLatest(fetchBasicAdUploadStatusAsync.request.type, fetchBasicAdUploadStatusSaga);
  yield takeLatest(updateBasicAdSponsorshipAsync.request.type, updateBasicAdSponsorshipSaga);
  yield takeLatest(createBasicAdSponsorshipAsync.request.type, createBasicAdSponsorshipSaga);
  // yield takeLatest(uploadBasicAdCoverStart, uploadBasicAdCoverSaga);
  yield takeEvery(uploadBasicAdVideoStart, uploadBasicAdVideoSaga);
  yield takeLatest(uploadBasicAdMedia, uploadBasicAdMediaSaga);
}
