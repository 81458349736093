import React from 'react';
import { FormGroup } from '../../../../../FormElements/FormGroup';
import { Controller } from 'react-hook-form';
import { Radio } from '../../../../../FormElements/Radio';
import { constructTestId } from '../../../../../../utils/test-ids';
import { CREATE_CAMPAIGN_PAGE } from '../../../../../../constants/test-ids/create-campaing-sections/create-campaign-sections';
import { useT } from '@transifex/react';
import s from '../RegularCampaign.module.scss';
import { setLabelPricePerMessage } from '../../../../../../utils/budget';

const SECTION = CREATE_CAMPAIGN_PAGE.TIMING_AND_BUDGET;

const PremiumCpm = ({
  control,
  videoAdPricingCheckboxes,
  handleChangeIncentive,
  adSetIsLocked,
}) => {
  const t = useT();

  return (
    <FormGroup title={t('Video Length')}>
      <div className={s['video__checkboxes-wrap']}>
        <Controller
          control={control}
          name="incentive"
          render={({ field: { onChange, value } }) =>
            videoAdPricingCheckboxes.map((item) => (
              <div className={s['video__checkbox']} key={item.videoMaxTimeInSeconds}>
                <Radio
                  testId={constructTestId(SECTION, `video-length-radio-${item.label}`)}
                  name={item.videoMaxTimeInSeconds}
                  value={item.videoMaxTimeInSeconds}
                  label={`${item.videoMinTimeInSeconds} - ${
                    item.videoMaxTimeInSeconds
                  } sec (${setLabelPricePerMessage(item, videoAdPricingCheckboxes)})`}
                  checked={value?.maxVideoLength ?? value?.videoMaxTimeInSeconds}
                  onChange={(e) => {
                    handleChangeIncentive(e, onChange);
                  }}
                  disabled={adSetIsLocked}
                />
              </div>
            ))
          }
        />
      </div>
    </FormGroup>
  );
};

export default PremiumCpm;
