import { T, useT } from '@transifex/react';
import React from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { sponsorshipConstants } from '../../../../../constants';
import { CREATE_CAMPAIGN_PAGE } from '../../../../../constants/test-ids/create-campaing-sections/create-campaign-sections';
import { constructTestId } from '../../../../../utils/test-ids';
import { FileSpecifications } from '../../../../common/FileSpecifications';
import { UploadZone } from '../../../../common/UploadZone';
import { FormGroup } from '../../../../FormElements/FormGroup';
import s from '../AdNameAndCreative.module.scss';

const SECTION = CREATE_CAMPAIGN_PAGE.AD_NAME_AND_CREATIVE;

const VideoMedia = ({
  sponsorshipCreate,
  control,
  errors,
  handleChangeVideoAd,
  creativeRef,
  register,
  adIsLocked,
  watchVideoLength,
}) => {
  const t = useT();
  const watchAdVideoFile = useWatch({ control, name: 'adVideoFile' });

  return (
    <div className={s['sponsorship__inner-video']} id="creative" ref={creativeRef}>
      <FormGroup title={t('Upload Video Ad')} required>
        <div className={s['sponsorship__inner-video__subtitle']}>
          <T
            _str="This is the brand video that will communicate directly with real people… {recommendedLength} {canvaInfo}"
            recommendedLength={
              <span className={s['strong']}>
                {t('We run videos ranging from 5 seconds to 2 minutes in length')}
              </span>
            }
            canvaInfo={
              <span>
                If you need to create a video file first, please use{' '}
                <a href={sponsorshipConstants.canvaUrl} target="_blank" rel="noopener noreferrer">
                  canva
                </a>{' '}
                link here.
              </span>
            }
          />
        </div>

        <div className={s['video-and-specs']}>
          <div className={s['video-wrapper']}>
            <input type="hidden" name="videoLength" value={watchVideoLength} {...register} />

            <Controller
              control={control}
              name="adVideo"
              render={({ field: { value, ref } }) => {
                return (
                  <UploadZone
                    inputRef={ref}
                    data-testid={constructTestId(SECTION, 'video-ad-upload')}
                    styleType="portrait"
                    name="adVideo"
                    control={control}
                    type="video"
                    error={errors.adVideo}
                    handleChange={handleChangeVideoAd}
                    contentType="video/mp4, video/quicktime"
                    fileUrl={value}
                    videoWidth={sponsorshipCreate?.videoWidth}
                    videoHeight={sponsorshipCreate?.videoHeight}
                    hasFile={watchAdVideoFile?.name}
                    // disabled={adIsLocked && brand.role !== 'WeAre8'}
                    disabled={adIsLocked}
                  />
                );
              }}
            />
          </div>
          <FileSpecifications fileSpecs={sponsorshipConstants.videoAdFileSpecs} />
        </div>
      </FormGroup>
    </div>
  );
};

export default VideoMedia;
