import React, { useMemo } from 'react';
import { budgetParser } from '../../../../../../utils/budget';
import DeliveryInfo from '../DeliveryInfo';
import BudgetAllocation from '../BudgetAllocation/BudgetAllocation';

const PremiumDeliveryInfoAndAllocation = ({
  budget,
  isBudgetValid,
  brandCountryCode,
  incentive,
  selectedCharityName,
}) => {
  const budgetData = useMemo(() => {
    const finalBudget = +budgetParser(budget);

    if (incentive) {
      const { pricePerMessage, netCashReward, weAre8Fee, charityDonation } = incentive;

      let guarantied, payments, donations, distributions, total;

      if (isBudgetValid) {
        guarantied = Math.round(finalBudget / pricePerMessage);
        payments = Math.round(guarantied * netCashReward);
        donations = Math.round(guarantied * charityDonation);
        distributions = Math.round(guarantied * weAre8Fee);
        total = finalBudget;
      }

      return {
        guarantied,
        payments,
        donations,
        distributions,
        total,
      };
    } else return {};
  }, [isBudgetValid, budget, incentive]);

  return (
    <>
      <DeliveryInfo budget={budget} pricePerMessage={incentive.pricePerMessage} />
      <BudgetAllocation
        hasValidBudget={budget && isBudgetValid}
        brandCountryCode={brandCountryCode}
        budgetData={budgetData}
        pricing={{ ...incentive, userReward: incentive.netCashReward }}
        selectedCharityName={selectedCharityName}
      />
    </>
  );
};

export default PremiumDeliveryInfoAndAllocation;
