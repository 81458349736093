import React, { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { T, useT } from '@transifex/react';

import { useScroll } from '../../../../hooks/useScroll';

import { NewAudience } from './NewAudience';
// import { RetargetAudience } from './RetargetAudience';

import { SectionTitle } from '../../../common/Titles/SectionTitle';
import { SectionSubtitle } from '../../../common/Titles/SectionSubtitle';
// import { TabButton } from '../../../common/TabButton';

import { FormGroup } from '../../../FormElements/FormGroup';

import { routes, sponsorshipSetConstants } from '../../../../constants';

import s from './NameAndAudience.module.scss';

import { constructTestId } from '../../../../utils/test-ids/';
import { CREATE_CAMPAIGN_PAGE } from '../../../../constants/test-ids/create-campaing-sections/create-campaign-sections';
import { SuggestionsTextInput } from '../../../FormElements/SuggestionsTextInput';
import { Link } from 'react-router-dom';
import Switch from '../../../FormElements/Switch/Switch';
import { TextArea } from '../../../FormElements/TextArea';
import { pageLoadSnippet, purchaseEvent, subscriptionEvent } from '../../../../constants/pixel';
import { Copy } from '../../../common/Copy';
import Radio from '../../../FormElements/Radio/Radio';
import { Controller, useWatch } from 'react-hook-form';
import { setSponsorshipSetName } from '../../../../store/campaign/sponsorshipSet';
import { SvgIcon } from '../../../common/SvgIcon';
import { DevTool } from '@hookform/devtools';
import useIdentifyCampaignType from '../../../../hooks/useIdentifyCampaignType/useIdentifyCampaignType';
import { setBasicAdSponsorshipSetName } from '../../../../store/basicAd/sponsorshipSet';

const SECTION = CREATE_CAMPAIGN_PAGE.NAME_AND_AUDIENCE;

const NameAndAudience = ({
  brandInfo,
  sponsorshipSetDraft,
  previousSponsorshipSetsNames,
  objective,
  register,
  errors,
  trigger,
  watch,
  control,
  setValue,
  handleAdSetActivation,
  regionsAndCities,
  clearErrors,
  adSetIsLocked,
  dirtyFieldsList,
  fetchMoreSponsorshipSetNames,
  moreSponsorshipSetNamesToFetch,
  setNamesKeyword,
  fetchInitialTags,
  fetchMoreTags,
  moreTagsToFetch,
  setTagsKeyword,
}) => {
  const t = useT();
  const dispatch = useDispatch();

  const { ageData, genderData, osData, pixelAudiencesData } = sponsorshipSetConstants;

  const watchIsEightPixelEnabled = useWatch({ control, name: 'isEightPixelEnabled' });
  const watchPixelConversionEvent = useWatch({ control, name: 'pixelConversionEvent' });

  const adSetNameRef = useRef(null);
  const audienceRef = useRef(null);
  const pixelRef = useRef(null);

  useScroll([adSetNameRef], 0.85);
  useScroll([audienceRef], 0.2, '-100px');
  useScroll([pixelRef], 0.1);

  const insertBrandId = useCallback(
    (string) => {
      return string.replace('INSERT_BRAND_ID', brandInfo.id);
    },
    [brandInfo.id]
  );

  const { isBasicAd } = useIdentifyCampaignType();

  return (
    <div className={s['sponsor-set']}>
      <DevTool control={control} />
      <div>
        {sponsorshipSetDraft.isDirty && (
          <div className={s['sponsor-set__unsaved']}>
            <div className={s['sponsor-set__unsaved-message']}>
              <SvgIcon name="warning" />
              <span>{t('You have unsaved changes')}</span>
            </div>
            <ul>{dirtyFieldsList}</ul>
          </div>
        )}
        <SectionTitle>
          <h3>{t('Ad Set')}</h3>
          <Switch
            disabled={!sponsorshipSetDraft.isCreated}
            menuSwitch
            onChange={handleAdSetActivation}
            name="adSetStatus"
            checked={sponsorshipSetDraft.isActive}
            id={sponsorshipSetDraft.id}
          />
        </SectionTitle>
        <SectionSubtitle>
          {t(
            'You will define your audience targeting, campaign budget, and timing at the ad set level'
          )}
        </SectionSubtitle>
      </div>
      <div className={s['sponsor-set__inner']} ref={adSetNameRef} id="adset-name">
        <FormGroup title={t('Ad Set Name')} subtitle={t('Enter a name to identify this Ad Set')}>
          <SuggestionsTextInput
            type="text"
            id="ad-set-name"
            data-testid={constructTestId(SECTION, 'ad-set-name-input')}
            name="name"
            label={t('AD SET NAME')}
            register={register}
            error={errors.name}
            data={previousSponsorshipSetsNames}
            hasMoreData={moreSponsorshipSetNamesToFetch}
            fetchMoreData={fetchMoreSponsorshipSetNames}
            watchValue={watch('name')}
            onChange={(name, value) => {
              setValue(name, value);
              setNamesKeyword(value);
            }}
            trigger={trigger}
            reduxAction={(value) =>
              dispatch(
                isBasicAd ? setBasicAdSponsorshipSetName(value) : setSponsorshipSetName(value)
              )
            }
            disabled={adSetIsLocked}
          />
        </FormGroup>
        <div className={s['sponsor-set__inner-audience__wrapper']} ref={audienceRef} id="audience">
          <FormGroup
            title={t('Target Audience')}
            subtitle={t(
              'Define the people you want to reach across the People’s Platform, using unique citizen-provided data points across app and premium publishers'
            )}
          />
          {/*// FIXME: Replace with commented code on later releases:*/}
          <div className={s['audience']}>
            <NewAudience
              fetchInitialTags={fetchInitialTags}
              fetchMoreTags={fetchMoreTags}
              moreTagsToFetch={moreTagsToFetch}
              setTagsKeyword={setTagsKeyword}
              ageData={ageData}
              pixelAudiencesData={pixelAudiencesData}
              brandInfo={brandInfo}
              control={control}
              genderData={genderData}
              osData={osData}
              sponsorshipSetDraft={sponsorshipSetDraft}
              errors={errors}
              watch={watch}
              regionsAndCities={regionsAndCities}
              register={register}
              setValue={setValue}
              clearErrors={clearErrors}
              trigger={trigger}
              adSetIsLocked={adSetIsLocked}
            />
          </div>

          <div className={s['sponsor-set__inner-pixel__wrapper']} id="8pixel" ref={pixelRef}>
            <FormGroup
              title={`${t('Setup your')} 8Pixel `}
              smalltitle={t(
                '(Must be enabled to work and must have conversions or traffic as objective)'
              )}
              subtitle={
                <T
                  _str="Enable this toggle to view conversion metrics in the reporting section. Below you can find the {pixel} code you need to implement in your website. You can find more detailed implementation instructions in the 8Pixel {link}."
                  pixel="8Pixel"
                  link={
                    <Link
                      to={routes.SETTINGS_PIXEL_SETUP}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={s['pixel-doc-link']}
                    >
                      <T _str="setup page" />
                    </Link>
                  }
                />
              }
            >
              <div className={s['switch']}>
                <Switch
                  menuSwitch
                  name="isEightPixelEnabled"
                  register={register}
                  disabled={adSetIsLocked}
                  id="isEightPixelEnabled"
                />
              </div>
              {watchIsEightPixelEnabled && (
                <div className={s['pixel-section']}>
                  <TextArea
                    label={t('BASE CODE')}
                    formValue={insertBrandId(pageLoadSnippet)}
                    styling="multirows"
                    rows={10}
                    data-testid={constructTestId(SECTION, 'pixel-base-code')}
                    readOnly
                  />
                  <Copy textToCopy={insertBrandId(pageLoadSnippet)} />
                  {objective === 'conversions' && (
                    <>
                      <div className={s['title']} style={{ marginTop: '1rem' }}>
                        {t('CONVERSION EVENT')}
                      </div>
                      <Controller
                        control={control}
                        name="pixelConversionEvent"
                        render={({ field: { value, name, onChange } }) => (
                          <div className={s['radios']}>
                            <Radio
                              name={name}
                              value="purchase"
                              label={t('Purchase')}
                              checked={value}
                              onChange={onChange}
                              disabled={adSetIsLocked}
                            />
                            <Radio
                              name={name}
                              value="subscription"
                              label={t('Subscription')}
                              checked={value}
                              onChange={onChange}
                              disabled={adSetIsLocked}
                            />
                          </div>
                        )}
                      />
                      <div className={s['pixel-section__inner']}>
                        <TextArea
                          label={t('ΕVENT CODE')}
                          formValue={
                            watchPixelConversionEvent === 'purchase'
                              ? purchaseEvent
                              : subscriptionEvent
                          }
                          styling="multirows"
                          rows={1}
                          data-testid={constructTestId(SECTION, 'pixel-event-code')}
                          readOnly
                        />
                        <Copy
                          textToCopy={
                            watchPixelConversionEvent === 'purchase'
                              ? purchaseEvent
                              : subscriptionEvent
                          }
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
            </FormGroup>
          </div>

          {/*<div id='audience' className={s['audience']}>*/}
          {/*  <div className={s['tab-section']}>*/}
          {/*    {*/}
          {/*      brandInfo.role === 'WeAre8' && tabItems.map(tab => (*/}
          {/*        <div key={tab.id} className={s['button-wrapper']}>*/}
          {/*          <TabButton*/}
          {/*            title={tab.title}*/}
          {/*            isActive={activeTab === tab.id}*/}
          {/*            handleSelectTab={() => handleSelectTab(tab.id)}*/}
          {/*          />*/}
          {/*        </div>*/}
          {/*      ))*/}
          {/*    }*/}
          {/*  </div>*/}
          {/*  {*/}
          {/*    brandInfo.role === 'WeAre8'*/}
          {/*      ? tabItems.map((tab, index) => (*/}
          {/*        <div*/}
          {/*          className={s['tab-content']}*/}
          {/*          key={index}*/}
          {/*        >*/}
          {/*          {*/}
          {/*            activeTab === tab.id*/}
          {/*              ? tab.content*/}
          {/*              : ''*/}
          {/*          }*/}
          {/*        </div>*/}
          {/*      ))*/}
          {/*      : <NewAudience*/}
          {/*        age={age}*/}
          {/*        ageData={ageData}*/}
          {/*        brandInfo={brandInfo}*/}
          {/*        control={control}*/}
          {/*        handleSelect={handleSelect}*/}
          {/*        handleCheck={handleCheck}*/}
          {/*        gender={gender}*/}
          {/*        genderData={genderData}*/}
          {/*        osData={osData}*/}
          {/*        sponsorshipSetDraft={sponsorshipSetDraft}*/}
          {/*        errors={errors}*/}
          {/*      />*/}
          {/*  }*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};

export default NameAndAudience;
