import { createAsyncAction, createAction } from 'eight.js.store-common';

export const clearData = createAction('@@brand/CLEAR_DATA');

export const fetchBrandAsync = createAsyncAction('@@brand/FETCH_BRAND');

export const fetchAccountBalanceAsync = createAsyncAction('@@brand/FETCH_ACCOUNT_BALANCE');

export const fetchBrandStop = createAction('@@brand/FETCH_BRAND_STOP');

export const fetchCountriesAsync = createAsyncAction('@@brand/FETCH_COUNTRIES');

export const fetchCharitiesAsync = createAsyncAction('@@brand/FETCH_CHARITIES');

export const fetchInterestsAsync = createAsyncAction('@@brand/FETCH_INTERESTS');

export const fetchPassionsAsync = createAsyncAction('@@brand/FETCH_PASSIONS');

export const fetchPixelStatsAsync = createAsyncAction('@@brand/FETCH_PIXEL_STATS');

export const fetchTagsAsync = createAsyncAction('@@brand/FETCH_TAGS');

export const fetchBrandTimezone = createAsyncAction('@@brand/FETCH_BRAND_TIMEZONE');

export const fetchBasicAdPricingAsync = createAsyncAction('@@brand/FETCH_RATECARD');

export const fetchIncentivesAsync = createAsyncAction('@@brand/FETCH_INCENTIVES');

export const fetchTimezones = createAsyncAction('@@brand/FETCH_TIMEZONES');

export const resetDataToDefault = createAction('@@brand/RESET_DATA_TO_DEFAULT');

export const setBrandData = createAction('@@brand/SET_BRAND_DATA');

export const setCardData = createAction('@@brand/SET_CARD_DATA');

export const addPaymentCard = createAction('@@brand/ADD_PAYMENT_CARD');

export const sendPaymentCardAsync = createAsyncAction('@@brand/SEND_PAYMENT_CARD');

export const editPaymentCard = createAction('@@brand/EDIT_PAYMENT_CARD');

export const setBrandLogo = createAction('@@brand/SET_BRAND_LOGO');

export const setBrandDataArray = createAction('@@brand/SET_BRAND_DATA_ARRAY');

export const setDefaultCountry = createAction('@@brand/SET_DEFAULT_COUNTRY');

export const setStripeError = createAction('@@brand/SET_STRIPE_ERROR');

export const updateAccountInfo = createAsyncAction('@@brand/UPDATE_ACCOUNT_INFO');

export const getCardSetupIntentKeyAsync = createAsyncAction('@@brand/GET_CARD_SETUP_INTENT_KEY');

export const getPaymentMethodsAsync = createAsyncAction('@@brand/GET_PAYMENT_METHODS');

export const removePaymentMethodAsync = createAsyncAction('@@brand/REMOVE_PAYMENT_METHOD');

export const makeMethodPrimaryAsync = createAsyncAction('@@brand/MAKE_METHOD_PRIMARY');

export const chargePaymentMethodAsync = createAsyncAction('@@brand/CHARGE_PAYMENT_METHOD');

export const getBacsCheckoutSessionIdAsync = createAsyncAction(
  '@@brand/GET_BACS_CHECKOUT_SESSION _ID'
);

export const setStripeChargeError = createAction('@@brand/SET_STRIPE_CHARGE_ERROR');

export const getBrandBillsAsync = createAsyncAction('@@brand/GET_BRAND_BILLS_TRANSACTIONS');

export const setDataArray = createAction('@@brand/SET_DATA_ARRAY');

export const setBrandBillDetails = createAction('@@brand/SET_BRAND_BILLS_DETAILS');

export const getBrandTransactionsAsync = createAsyncAction('@@brand/GET_BRAND_TRANSACTIONS');

export const getHistoryTransactionsAsync = createAsyncAction('@@brand/GET_HISTORY_TRANSACTIONS');

export const setDefaultBrandInfo = createAction('@@brand/SET_DEFAULT_BRAND_INFO');

export const toggleIsBrandInfoSaved = createAction('@@brand/TOGGLE_IS_BRAND_INFO_SAVED');

export const updateBrandInfoFromBillingAsync = createAsyncAction(
  '@@brand/UPDATE_BRAND_INFO_FROM_BILLING'
);

export const getVatAndFeeAsync = createAsyncAction('@@brand/GET_VAT_AND_FEE');

export const setDefaultVatAndFee = createAction('@@brand/SET_DEFAULT_VAT_AND_FEE');

export const setLastStripeCharge = createAction('@@brand/SET_LAST_STRIPE_CHARGE');

export const setInvoicePartnerRequestData = createAction(
  '@@brand/SET_INVOICE_PARTNER_REQUEST_DATA'
);

export const setDefaultInvoicePartnerRequestData = createAction(
  '@@brand/SET_DEFAULT_INVOICE_PARTNER_REQUEST_DATA'
);

export const setInvoicePartnerRequestAsync = createAsyncAction(
  '@@brand/SET_INVOICE_PARTNER_REQUEST'
);

export const setDefaultTablesData = createAction('@@brand/SET_DEFAULT_TABLES_DATA');

export const setBrandInfo = createAction('@@brand/SET_BRAND_INFO');

export const setBrandInvoicePaid = createAction('@@brand/SET_BRAND_INVOICE_PAID');

export const updateBrandBills = createAction('@@brand/UPDATE_BRAND_BILLS');

export const toggleSpinnerForThirdPartyLibs = createAction(
  '@@brand/TOGGLE_SPINNER_FOR_THIRD_PARTY_LIBS'
);

export const sendVerificationCodeAsync = createAsyncAction('@@auth/SEND_VERIFICATION_CODE');

export const sendResetPasswordEmailAsync = createAsyncAction('@@auth/SEND_RESET_PASSWORD_EMAIL');

export const resetPasswordAsync = createAsyncAction('@@auth/RESET_PASSWORD');

export const fetchLocationsAsync = createAsyncAction('@@brand/FETCH_LOCATIONS');

export const fetchCampaignNamesAsync = createAsyncAction('@@brand/FETCH_CAMPAIGN_NAMES');
export const fetchBasicCampaignNamesAsync = createAsyncAction('@@brand/FETCH_BASIC_CAMPAIGN_NAMES');

export const fetchSponsorshipSetNamesAsync = createAsyncAction(
  '@@brand/FETCH_SPONSORSHIP_SET_NAMES'
);
export const fetchBasicSponsorshipSetNamesAsync = createAsyncAction(
  '@@brand/FETCH_BASIC_SPONSORSHIP_SET_NAMES'
);

export const fetchSponsorshipNamesAsync = createAsyncAction('@@brand/FETCH_SPONSORSHIP_NAMES');

export const fetchUniqueCampaignNamesAsync = createAsyncAction(
  '@@brand/FETCH_UNIQUE_CAMPAIGN_NAMES'
);

export const fetchUniqueBasicCampaignNamesAsync = createAsyncAction(
  '@@brand/FETCH_UNIQUE_BASIC_CAMPAIGN_NAMES'
);

export const fetchUniqueSponsorshipSetNamesAsync = createAsyncAction(
  '@@brand/FETCH_UNIQUE_SPONSORSHIP_SET_NAMES'
);

export const fetchUniqueBasicSponsorshipSetNamesAsync = createAsyncAction(
  '@@brand/FETCH_UNIQUE_BASIC_SPONSORSHIP_SET_NAMES'
);

export const fetchUniqueSponsorshipNamesAsync = createAsyncAction(
  '@@brand/FETCH_UNIQUE_SPONSORSHIP_NAMES'
);

export const fetchUniqueBasicSponsorshipNamesAsync = createAsyncAction(
  '@@brand/FETCH_UNIQUE_BASIC_SPONSORSHIP_NAMES'
);

export const setBrandIsLoading = createAction('@@brand/BRAND_IS_LOADING');

export const getBrandsLastChargeAsync = createAsyncAction('@@brand/GET_BRANDS_LAST_CHARGE');

export const setBrandCountryInfo = createAction('@@brand/SET_BRAND_COUNTRY_INFO');

export const fetchTestPixelEventsAsync = createAsyncAction('@@brand/FETCH_TEST_PIXEL_EVENTS');

export const setPixelTestEvents = createAction('@@brand/SET_PIXEL_TEST_EVENTS');

export const setIsPixelTestInProgress = createAction('@@brand/SET_IS_PIXEL_TEST_IN_PROGRESS');
