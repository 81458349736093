import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { routes } from '../../constants';
import { basicAdAdSetsSelector } from '../../store/basicAdData';

function useActiveMenuItem({ type, adSetId, adId, newItemIndex }) {
  const [activeMenuItem, setActiveMenuItem] = useState(false);
  const allAdSets = useSelector(basicAdAdSetsSelector);

  const match = useRouteMatch(
    type === 'adSet' ? routes.BASIC_AD.SPONSORSHIP_SET_EDIT : routes.BASIC_AD.SPONSORSHIP_EDIT
  );
  const activeAdSetId = useMemo(() => match?.params.ssid, [match]);
  const activeAdId = useMemo(() => match?.params.sid, [match]);

  const newUnitsMatch = useRouteMatch(
    type === 'adSet'
      ? routes.BASIC_AD.SPONSORSHIP_SET_CREATE_MULTI
      : routes.BASIC_AD.SPONSORSHIP_CREATE_MULTI
  );
  const newIndex = useMemo(() => newUnitsMatch?.params.newIndex, [newUnitsMatch]);

  useEffect(() => {
    if (type === 'adSet') {
      setActiveMenuItem(
        adSetId !== 'new'
          ? activeAdSetId === adSetId || allAdSets.length === 1
          : newIndex == newItemIndex
      );
    } else if (type === 'ad') {
      const selectedAdSet = allAdSets.find((adSet) => adSet.id === adSetId);
      setActiveMenuItem(
        adId !== 'new'
          ? activeAdId === adId || selectedAdSet?.ads.length === 1
          : newIndex == newItemIndex
      );
    }
  }, [activeAdSetId, activeAdId, adSetId, adId, type, allAdSets, newIndex, newItemIndex]);
  return { activeMenuItem };
}

export default useActiveMenuItem;
