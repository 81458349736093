import * as yup from 'yup';
import { getVideoDuration, isValidDoubleClickTrackingScript, isValidImageTag } from './helpers';
import { t } from '@transifex/native';
import url from 'valid-url';
import {
  isValidAdFormCtaTracking,
  isValidDoubleVerifyTrackingScript,
} from '../../../../../../utils/validate/validate';

yup.addMethod(yup.array, 'unique', function (field, message) {
  return this.test('unique', message, function (array) {
    const uniqueData = Array.from(new Set(array.map((row) => row[field]?.toLowerCase())));
    const isUnique = array.length === uniqueData.length;
    if (isUnique) {
      return true;
    }
    const index = array.findIndex((row, i) => row[field]?.toLowerCase() !== uniqueData[i]);
    if (array[index][field] === '') {
      return true;
    }
    return this.createError({
      path: `${this.path}.${index}.${field}`,
      message,
    });
  });
});

export const validationSchema = yup.object().shape({
  adVideo: yup
    .string()
    .required('This field is required')
    .test('is-valid-media', 'Video must be at least 5 seconds long', async function (value) {
      const { mediaType, adVideoFile } = this.parent;
      if (!adVideoFile || (Object.keys(adVideoFile).length === 0 && value)) return true;

      if (mediaType.value === 'video') {
        const duration = await getVideoDuration(value);
        return duration >= 5;
      }
      return true;
    }),
  caption: yup.string().max(280, t('Maximum caption length should not exceed 280 characters')),
  name: yup
    .string()
    .min(3, t('Minimum length should be 3 chars'))
    .max(100, t('Maximum length should not exceed 100 chars'))
    .required(t('Ad Name is a required field')),
  ctaLink: yup
    .string()
    .test('if-valid-link', t('Link should be valid and have https:// protocol'), (value) => {
      if (value) {
        return url.isHttpsUri(value);
      }
      return false;
    }),
  ctaButtonText: yup.string().required(t('CTA button text is a required field')),
  thirdPartyTracking: yup
    .array()
    .of(
      yup.object().shape({
        provider: yup.string().required(t('3rd party provider is required')),
        script: yup.string().when('provider', (provider, schema) => {
          switch (provider) {
            case 'doubleClick': {
              return schema.test(
                'is-valid-double-click-script',
                t('Script structure is incorrect'),
                (value) => (value ? isValidDoubleClickTrackingScript(value) : false)
              );
            }

            case 'doubleVerify': {
              return schema.test(
                'is-valid-double-verify-script',
                t('Script structure is incorrect'),
                (value) => (value ? isValidDoubleVerifyTrackingScript(value) : false)
              );
            }

            case 'adFormCtaTracking': {
              return schema.test(
                'is-valid-ad-form-cta-tracking',
                t('Brand website is incorrect'),
                (value) => (value ? isValidAdFormCtaTracking(value) : false)
              );
            }
            case 'adFormCompletionsTracking': {
              return schema.test(
                'is-valid-img-tag',
                t('Image tag structure is incorrect'),
                (value) => (value ? isValidImageTag(value) : false)
              );
            }
            default:
              return schema;
          }
        }),
      })
    )
    .test(
      'unique',
      t('Third party tracking option cannot be used twice for the same Ad'),
      (items) => {
        if (!items) return true;
        const arrayWithNames = items.map((item) => item?.provider);
        const uniqueData = [...new Set(arrayWithNames)];
        const isUnique = items.length === uniqueData.length;
        if (isUnique) return true;
        return false;
      }
    ),
});
