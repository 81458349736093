import { createSelector } from 'reselect';

const basicAdCreateStateSelector = (state) => state.basicAd;

export const basicAdCreateSelector = createSelector(
  basicAdCreateStateSelector,
  (state) => state.campaignCreate
);

export const basicAdEditSelector = createSelector(
  basicAdCreateStateSelector,
  (state) => state.campaignEdit
);

export const basicAdFetchingSelector = createSelector(
  basicAdCreateStateSelector,
  (state) => state.fetching
);

export const basicAdIdSelector = createSelector(basicAdEditSelector, (state) => state.id);
