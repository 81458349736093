import { t } from '@transifex/native';

const textsConstants = Object.freeze({
  publishSuccess: t(`
  Thank you for your submission.
   We will review it within the next  24-48h. Upon approval, it will go live according to the schedule.`),
  basicAdPublishSuccess: t(`
  Thank you for your submission.`),
});

export default textsConstants;
