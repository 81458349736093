import { createReducer, initialAsyncState } from 'eight.js.store-common';
import { nanoid } from 'nanoid';
import { addTextUtcStringField } from '../../utils/date/timezoneHelpers';
import { signupAsync } from '../auth';
import { updateSponsorshipAsync } from '../campaign/sponsorship';

import {
  addPaymentCard,
  clearData,
  editPaymentCard,
  fetchAccountBalanceAsync,
  fetchBrandAsync,
  fetchCountriesAsync,
  resetDataToDefault,
  setBrandData,
  setBrandDataArray,
  setBrandLogo,
  setCardData,
  setStripeError,
  sendPaymentCardAsync,
  updateAccountInfo,
  getCardSetupIntentKeyAsync,
  getPaymentMethodsAsync,
  getBacsCheckoutSessionIdAsync,
  setStripeChargeError,
  getBrandBillsAsync,
  setDataArray,
  setBrandBillDetails,
  getBrandTransactionsAsync,
  setDefaultBrandInfo,
  toggleIsBrandInfoSaved,
  getVatAndFeeAsync,
  setDefaultVatAndFee,
  setInvoicePartnerRequestData,
  sendResetPasswordEmailAsync,
  setDefaultInvoicePartnerRequestData,
  setDefaultTablesData,
  setBrandInfo,
  setBrandInvoicePaid,
  updateBrandBills,
  toggleSpinnerForThirdPartyLibs,
  fetchBrandTimezone,
  fetchTimezones,
  fetchIncentivesAsync,
  fetchLocationsAsync,
  fetchSponsorshipSetNamesAsync,
  fetchCampaignNamesAsync,
  fetchSponsorshipNamesAsync,
  fetchCharitiesAsync,
  fetchTagsAsync,
  setBrandIsLoading,
  getBrandsLastChargeAsync,
  setBrandCountryInfo,
  fetchInterestsAsync,
  fetchPixelStatsAsync,
  fetchPassionsAsync,
  fetchUniqueCampaignNamesAsync,
  fetchUniqueSponsorshipSetNamesAsync,
  fetchUniqueSponsorshipNamesAsync,
  fetchTestPixelEventsAsync,
  setPixelTestEvents,
  setIsPixelTestInProgress,
  fetchBasicAdPricingAsync,
  fetchBasicCampaignNamesAsync,
  fetchBasicSponsorshipSetNamesAsync,
  fetchUniqueBasicCampaignNamesAsync,
  fetchUniqueBasicSponsorshipSetNamesAsync,
  fetchUniqueBasicSponsorshipNamesAsync,
} from './actions';

const initialState = {
  ...initialAsyncState,
  brandInfo: {
    fetchedCountries: [],
    fetchedCharities: [],
    fetchedInterests: [],
    fetchedPassions: [],
    fetchedTags: [],
    fetchedLocations: [],
    fetchedPixelStats: {
      didNotTakeActionCount: 0,
      tookActionCount: 0,
    },
    fetchedCampaigns: [],
    fetchedTimezones: [],
    previousCampaignNames: [],
    previousBasicCampaignNames: [],
    previousSponsorshipSetsNames: [],
    previousSponsorshipNames: [],
    previousUniqueCampaignNames: [],
    previousUniqueBasicCampaignNames: [],
    previousUniqueSponsorshipSetsNames: [],
    previousUniqueBasicSponsorshipSetsNames: [],
    previousUniqueSponsorshipNames: [],
    previousUniqueBasicSponsorshipNames: [],
    brandTimezone: '',
    id: '',
    emailAddress: '',
    billingEmail: '',
    legalName: '',
    brandType: '',
    accountType: '',
    role: '',
    isInvoicePartner: false,
    balance: 0,
    brandName: '',
    addressLineFirst: '',
    addressLineSecond: '',
    city: '',
    postCode: '',
    brandCountryCode: '',
    countryCode: '',
    timezoneId: '',
    currencyCode: '',
    bcP47LanguageTag: '',
    accountStatus: '',
    vatReceiptUri: '',
    country: '',
    website: '',
    samiPricingId: '',
    basicAdsPricingId: '',
    // Legacy timezone
    // Not removed, due to multiple use cases within app
    // MUST remove in future!
    timeZone: {
      label: 'UTC+01:00',
      name: 'UTC+01:00',
      value: 'UTC+01:00',
    },
    timezone: {
      id: 'd942b92d-709f-4bcb-8206-ab03ad9cb760',
      utcString: 'Europe/London',
      value: 'GMT Standard Time',
      abbr: 'GMT',
      offset: 0,
      isDst: false,
      text: '(UTC) Edinburgh, London',
    },
    processingLogoUri: '',
    processingLogoUriFile: {},
    logoUri: '',
    logoFile: {},
    password: '',
    newPassword: '',
    confirmPassword: '',
    notifications: {
      label: 'All new updates',
      value: 'All new updates',
      id: 'All new updates',
    },
    funds: '',
    paymentMethods: [],
    selectedCard: {
      isNew: true,
      cardNumber: '',
      internalId: nanoid(),
      type: 'visa',
      isPrimary: false,
      lastFourDigits: '',
      expiration: '',
    },
    cardSetupIntentKey: '',
    bacsDirectDebitData: '',
    signupCode: '',
    incentive: {
      pricePerMessage: 0.34,
      netCashReward: 0.2,
      weAre8Fee: 0.12,
      charityDonation: 0.02,
    },
    defaultVideoAdPricingMaxLength: 20,
    videoAdPricingData: [],
    stripeFormErrors: {
      cardNumber: null,
      cardCvc: null,
      cardExpiry: null,
      general: null,
    },
    stripeChargeErrors: {},
    phone: {
      regionCode: '',
      number: '',
    },
    brandIdFromAdmin: '',
  },
  brandBills: {
    data: [],
    pages: 0,
    totalBrandBills: 0,
  },
  brandBillDetails: [],
  brandTransactions: {
    data: [],
    pages: 0,
    totalBrandTransactions: 0,
  },
  itemsPerPage: { name: '10 results', label: '10 results', value: 10 },
  isBrandInfoSaved: false,
  error: '',
  fetching: false,
  vatAndFee: {
    stripeFee: '00.00',
    subTotal: '00.00',
    total: '00.00',
    vatAmount: '00.00',
    paymentProcessingTime: '',
  },
  lastStripeCharge: {
    total: null,
    paymentMethod: '',
    date: '',
  },
  invoicePartnerRequest: {
    estimatedMonthlySpend: '',
    contactName: '',
    contactNumber: '',
    contactEmail: '',
  },
  brandIsLoading: false,
  pixelTestEvents: [],
  isPixelTestInProgress: false,
};

export const brandReducer = createReducer('@@brand', initialState, {
  [signupAsync.success]: ({ state, action }) => {
    state.brandInfo = { ...state.brandInfo, ...action.payload };
  },

  [sendResetPasswordEmailAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },
  [toggleSpinnerForThirdPartyLibs]: ({ state, action }) => {
    state.fetching = action.payload;
  },
  [updateBrandBills]: ({ state, action }) => {
    state.brandBills.data = action.payload;
  },
  [setDefaultTablesData]: ({ state }) => {
    state.brandBills = initialState.brandBills;
    state.brandBillDetails = initialState.brandBillDetails;
    state.brandTransactions = initialState.brandTransactions;
  },
  [setDefaultInvoicePartnerRequestData]: ({ state }) => {
    state.invoicePartnerRequest = initialState.invoicePartnerRequest;
  },
  [setInvoicePartnerRequestData]: ({ state, action }) => {
    const { name, value } = action.payload;
    state.invoicePartnerRequest[name] = value;
  },
  [getBrandsLastChargeAsync.success]: ({ state, action }) => {
    state.lastStripeCharge = action.payload;
  },
  [getBrandTransactionsAsync.success]: ({ state, action }) => {
    state.brandTransactions = action.payload;
  },
  [setBrandBillDetails]: ({ state, action }) => {
    state.brandBillDetails = action.payload;
  },
  [setDataArray]: ({ state, action }) => {
    const { name, value } = action.payload;
    state[name] = value;
  },
  [getBrandBillsAsync.success]: ({ state, action }) => {
    state.brandBills = action.payload;
  },
  [setDefaultVatAndFee]: ({ state }) => {
    state.vatAndFee = initialState.vatAndFee;
  },

  [getVatAndFeeAsync.failure]: ({ state }) => {
    state.vatAndFee = initialState.vatAndFee;
  },
  [getVatAndFeeAsync.success]: ({ state, action }) => {
    state.vatAndFee = action.payload;
  },
  [toggleIsBrandInfoSaved]: ({ state, action }) => {
    state.isBrandInfoSaved = action.payload;
  },

  [setDefaultBrandInfo]: ({ state }) => {
    state.brandInfo.billingEmail = initialState.brandInfo.billingEmail;
    state.brandInfo.legalName = initialState.brandInfo.legalName;
    state.brandInfo.brandType = initialState.brandInfo.brandType;
    state.brandInfo.addressLineFirst = initialState.brandInfo.addressLineFirst;
    state.brandInfo.addressLineSecond = initialState.brandInfo.addressLineSecond;
    state.brandInfo.city = initialState.brandInfo.city;
    state.brandInfo.postCode = initialState.brandInfo.postCode;
    state.brandInfo.brandCountryCode = initialState.brandInfo.brandCountryCode;
  },
  [getPaymentMethodsAsync.failure]: ({ state }) => {
    state.brandInfo.paymentMethods = [];
  },
  [getPaymentMethodsAsync.success]: ({ state, action }) => {
    state.brandInfo.paymentMethods = action.payload;
  },
  [getCardSetupIntentKeyAsync.success]: ({ state, action }) => {
    state.brandInfo.cardSetupIntentKey = action.payload;
  },
  [getBacsCheckoutSessionIdAsync.success]: ({ state, action }) => {
    state.brandInfo.bacsDirectDebitData = action.payload;
  },
  [setStripeChargeError]: ({ state, action }) => {
    state.brandInfo.stripeChargeErrors = action.payload;
  },

  [setStripeError]: ({ state, action }) => {
    const { name, value } = action.payload;
    state.brandInfo.stripeFormErrors = {
      ...state.brandInfo.stripeFormErrors,
      [name]: value,
    };
  },

  [addPaymentCard]: ({ state, action }) => {
    const { newCard } = action.payload;

    state.brandInfo.paymentCards = [
      ...state.brandInfo.paymentCards,
      newCard,
      // action.payload,
    ];
  },

  [editPaymentCard]: ({ state, action }) => {
    const { internalId, card } = action.payload;
    const cardIndex = state.brandInfo.paymentCards.findIndex(
      (current) => current.internalId === internalId
    );

    state.brandInfo.paymentCards[cardIndex] = card;
  },

  [clearData]: ({ state }) => {
    state.brandInfo = initialState.brandInfo;
  },

  [fetchBrandAsync.success]: ({ state, action }) => {
    state.brandInfo = {
      ...state.brandInfo,
      ...action.payload,
    };
  },

  [sendPaymentCardAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [fetchAccountBalanceAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [fetchAccountBalanceAsync.success]: ({ state, action }) => {
    state.brandInfo.balance = action.payload;
  },

  [fetchCountriesAsync.success]: ({ state, action }) => {
    state.brandInfo.fetchedCountries = action.payload;
  },

  [fetchCountriesAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [fetchCharitiesAsync.success]: ({ state, action }) => {
    state.brandInfo.fetchedCharities = action.payload;
  },

  [fetchCharitiesAsync.failure]: ({ state, action }) => {
    state.brandInfo.error = action.payload;
  },

  [fetchInterestsAsync.success]: ({ state, action }) => {
    state.brandInfo.fetchedInterests = action.payload;
  },

  [fetchInterestsAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [fetchPassionsAsync.success]: ({ state, action }) => {
    state.brandInfo.fetchedPassions = action.payload;
  },

  [fetchPassionsAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },
  [fetchTagsAsync.success]: ({ state, action }) => {
    const clear = action.payload.clear;
    const response = action.payload.tags;
    state.brandInfo.fetchedTags = clear
      ? action.payload.tags
      : [...state.brandInfo.fetchedTags, ...response];
  },

  [fetchTagsAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [fetchPixelStatsAsync.success]: ({ state, action }) => {
    state.brandInfo.fetchedPixelStats = action.payload;
  },

  [fetchTimezones.success]: ({ state, action }) => {
    state.brandInfo.fetchedTimezones = addTextUtcStringField(action.payload);
  },

  [fetchTimezones.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [fetchBrandTimezone.success]: ({ state, action }) => {
    state.brandInfo.brandTimezone = addTextUtcStringField(action.payload);
  },

  [fetchBrandTimezone.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [fetchIncentivesAsync.success]: ({ state, action }) => {
    state.brandInfo.videoAdPricingData = action.payload;
    state.brandInfo.incentive = action.payload.find(
      (incentive) =>
        incentive.videoMaxTimeInSeconds === state.brandInfo.defaultVideoAdPricingMaxLength
    );
  },

  [fetchIncentivesAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [fetchBasicAdPricingAsync.success]: ({ state, action }) => {
    state.brandInfo.basicAdsPricing = action.payload;
  },

  [fetchBasicAdPricingAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [resetDataToDefault]: ({ state, action }) => {
    const { name } = action.payload;
    state.brandInfo[name] = initialState.brandInfo[name];
  },

  [setBrandData]: ({ state, action }) => {
    const { name, value } = action.payload;

    state.brandInfo[name] = value;
  },

  [setCardData]: ({ state, action }) => {
    const { name, value } = action.payload;
    state.brandInfo.selectedCard = {
      ...state.brandInfo.selectedCard,
      [name]: value,
    };
  },

  [setBrandDataArray]: ({ state, action }) => {
    const { name, value } = action.payload;

    state.brandInfo = {
      ...state.brandInfo,
      [name]: value,
    };
  },

  [setBrandLogo]: ({ state, action }) => {
    const { logoFile, logoUri } = action.payload;

    state.brandInfo.logoFile = logoFile;
    state.brandInfo.logoUri = logoUri;
  },

  [setBrandCountryInfo]: ({ state, action }) => {
    state.brandInfo = {
      ...state.brandInfo,
      country: action.payload,
    };
  },

  [updateAccountInfo.success]: ({ state, action }) => {
    state.brandInfo = {
      ...state.brandInfo,
      ...action.payload,
    };
  },

  [updateAccountInfo.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [setBrandInfo]: ({ state, action }) => {
    state.brandInfo = {
      ...state.brandInfo,
      ...action.payload,
    };
  },

  [setBrandInvoicePaid]: ({ state, action }) => {
    state.brandBills = {
      ...state.brandBills,
      data: state.brandBills.data.filter((bill) => {
        if (bill.invoiceId === action.payload) {
          return {
            ...bill,
            billStatus: 'paid',
          };
        }

        return bill;
      }),
    };
  },

  [updateSponsorshipAsync.success]: ({ state, action }) => {
    state.brandInfo.fetchedTags = action.payload.localTags;
  },

  [fetchLocationsAsync.success]: ({ state, action }) => {
    state.brandInfo.fetchedLocations = action.payload;
  },

  [fetchCampaignNamesAsync.success]: ({ state, action }) => {
    state.brandInfo.previousCampaignNames = action.payload;
  },
  [fetchBasicCampaignNamesAsync.success]: ({ state, action }) => {
    state.brandInfo.previousBasicCampaignNames = action.payload;
  },

  [fetchSponsorshipSetNamesAsync.success]: ({ state, action }) => {
    state.brandInfo.previousSponsorshipSetsNames = action.payload;
  },
  [fetchBasicSponsorshipSetNamesAsync.success]: ({ state, action }) => {
    state.brandInfo.previousBasicSponsorshipSetsNames = action.payload;
  },

  [fetchSponsorshipNamesAsync.success]: ({ state, action }) => {
    state.brandInfo.previousSponsorshipNames = action.payload;
  },

  [fetchUniqueCampaignNamesAsync.success]: ({ state, action }) => {
    const clear = action.payload.clear;
    const response = action.payload.response;
    state.brandInfo.previousUniqueCampaignNames = clear
      ? response.data
      : [...state.brandInfo.previousUniqueCampaignNames, ...response.data];
  },

  [fetchUniqueBasicCampaignNamesAsync.success]: ({ state, action }) => {
    const clear = action.payload.clear;
    const response = action.payload.response;
    state.brandInfo.previousUniqueBasicCampaignNames = clear
      ? response.data
      : [...state.brandInfo.previousUniqueBasicCampaignNames, ...response.data];
  },

  [fetchUniqueSponsorshipSetNamesAsync.success]: ({ state, action }) => {
    const clear = action.payload.clear;
    const response = action.payload.response;
    state.brandInfo.previousUniqueSponsorshipSetsNames = clear
      ? response.data
      : [...state.brandInfo.previousUniqueSponsorshipSetsNames, ...response.data];
  },

  [fetchUniqueBasicSponsorshipSetNamesAsync.success]: ({ state, action }) => {
    const clear = action.payload.clear;
    const response = action.payload.response;
    state.brandInfo.previousUniqueBasicSponsorshipSetsNames = clear
      ? response.data
      : [...state.brandInfo.previousUniqueBasicSponsorshipSetsNames, ...response.data];
  },

  [fetchUniqueSponsorshipNamesAsync.success]: ({ state, action }) => {
    const clear = action.payload.clear;
    const response = action.payload.response;
    state.brandInfo.previousUniqueSponsorshipNames = clear
      ? response.data
      : [...state.brandInfo.previousUniqueSponsorshipNames, ...response.data];
  },

  [fetchUniqueBasicSponsorshipNamesAsync.success]: ({ state, action }) => {
    const clear = action.payload.clear;
    const response = action.payload.response;
    state.brandInfo.previousUniqueBasicSponsorshipNames = clear
      ? response.data
      : [...state.brandInfo.previousUniqueBasicSponsorshipNames, ...response.data];
  },

  [setBrandIsLoading]: ({ state, action }) => {
    state.brandIsLoading = action.payload;
  },

  [fetchTestPixelEventsAsync.success]: ({ state, action }) => {
    state.pixelTestEvents = action.payload;
  },

  [setPixelTestEvents]: ({ state, action }) => {
    state.pixelTestEvents = action.payload;
  },

  [setIsPixelTestInProgress]: ({ state, action }) => {
    state.isPixelTestInProgress = action.payload;
  },
});
