import React, { useMemo } from 'react';

import { T, useT } from '@transifex/react';

import { FormGroup } from '../../../../FormElements/FormGroup';
import { Checkbox } from '../../../../FormElements/Checkbox';
import { CustomSelect } from '../../../../FormElements/Selects/CustomSelect';

import s from './NewAudience.module.scss';

import { constructTestId } from '../../../../../utils/test-ids/';
import { CREATE_CAMPAIGN_PAGE } from '../../../../../constants/test-ids/create-campaing-sections/create-campaign-sections';
import { Controller, useWatch } from 'react-hook-form';
import { multipleCheckboxesChangeHandler } from '../../../../../utils/form/formHelpers';
import Switch from '../../../../FormElements/Switch/Switch';
import { DropdownSelect } from '../../../../FormElements/Selects/DropdownSelect';
import { MakeItBlurry } from '../../../../common/MakeItBlurry';
import FeatureFlag from '../../../../common/FeatureFlag/FeatureFlag';
import { Link } from 'react-router-dom';
import { routes } from '../../../../../constants';
import { SectionSubtitle } from '../../../../common/Titles/SectionSubtitle';
import SuggestionsTextInput from '../../../../FormElements/SuggestionsTextInput/SuggestionsTextInput';
import { TextInput } from '../../../../FormElements/TextInput';
import useIdentifyCampaignType from '../../../../../hooks/useIdentifyCampaignType/useIdentifyCampaignType';

const SECTION = CREATE_CAMPAIGN_PAGE.NAME_AND_AUDIENCE;

const NewAudience = ({
  ageData,
  pixelAudiencesData,
  brandInfo,
  control,
  errors,
  genderData,
  sponsorshipSetDraft,
  watch,
  regionsAndCities,
  register,
  setValue,
  clearErrors,
  osData,
  fetchInitialTags,
  fetchMoreTags,
  moreTagsToFetch,
  setTagsKeyword,
  adSetIsLocked,
}) => {
  const t = useT();

  const watchLocationsStatus = useWatch({ control, name: 'locationsStatus' });
  const watchInterests = useWatch({ control, name: 'interests' });
  const watchPassions = useWatch({ control, name: 'passions' });
  const watchExcludeTags = useWatch({ control, name: 'excludeTags' });
  const watchIncludeTags = useWatch({ control, name: 'includeTags' });
  const watchOs = useWatch({ control, name: 'deviceType' });
  const watchFrequencyCap = useWatch({ control, name: 'frequencyCap' });
  const watchFrequencyCapStatus = useWatch({ control, name: 'frequencyCapStatus' });
  const { isBasicAd } = useIdentifyCampaignType();

  const defaultCountryValue = useMemo(
    () => ({ label: brandInfo?.country?.name, value: brandInfo?.country?.name }),
    [brandInfo?.country?.name]
  );

  // Filter Audience Tags
  // const customAudiencesTags = brandInfo.fetchedTags.filter(
  //   (item) => !(watchIncludeTags.includes(item) || watchExcludeTags.includes(item))
  // );

  const noPreviousConversionEvents = useMemo(
    () =>
      brandInfo.fetchedPixelStats.tookActionCount === 0 &&
      brandInfo.fetchedPixelStats.didNotTakeActionCount === 0,
    [brandInfo.fetchedPixelStats.didNotTakeActionCount, brandInfo.fetchedPixelStats.tookActionCount]
  );

  return (
    <div className={s['new-audience']}>
      <div className={s['gender']}>
        <FormGroup
          title={t('Gender')}
          subtitle={t(
            `Select 'All' for all genders (Purple color indicates your gender targeting).`
          )}
        >
          <div className={s['gender__wrap']}>
            <Controller
              control={control}
              name="gender"
              render={({ field: { onChange, value, name, ref } }) =>
                genderData.map((item) => (
                  <div className={s['gender-ceil']} key={item.value}>
                    <Checkbox
                      data-testid={constructTestId(SECTION, `gender-checkbox-${item}`)}
                      onChange={(e) => {
                        multipleCheckboxesChangeHandler(
                          name,
                          e.target.value,
                          e.target.checked,
                          value,
                          genderData,
                          onChange
                        );
                      }}
                      name={item.name}
                      value={item.value}
                      label={t(item.label)}
                      checked={value?.includes(item.value)}
                      inputRef={ref}
                    />
                  </div>
                ))
              }
            />
          </div>
          {errors.gender && <span className={s['age__error']}>{errors.gender.message}</span>}
        </FormGroup>
      </div>
      <div className={s['age']}>
        <FormGroup title={t('Age')} subtitle={t('Select all that apply')}>
          <div className={s['age__wrap']}>
            <Controller
              control={control}
              name="age"
              render={({ field: { onChange, value, name, ref } }) =>
                ageData.map((item) => (
                  <div className={s['age-ceil']} key={item.value}>
                    <Checkbox
                      data-testid={constructTestId(SECTION, `age-checkbox-${item}`)}
                      onChange={(e) => {
                        multipleCheckboxesChangeHandler(
                          name,
                          e.target.value,
                          e.target.checked,
                          value,
                          ageData,
                          onChange
                        );
                      }}
                      name={item.name}
                      value={item.value}
                      label={t(item.label)}
                      checked={value?.includes(item.value)}
                      inputRef={ref}
                    />
                  </div>
                ))
              }
            />
            {errors.age && <span className={s['age__error']}>{errors.age.message}</span>}
          </div>
        </FormGroup>
      </div>
      <div className={s['locations']}>
        <FormGroup title={t('Location')}>
          <div className={s['locations-switch']}>
            <Switch
              menuSwitch
              register={register}
              name="locationsStatus"
              id="locationsStatus"
              onChange={(e) => {
                const checked = e.target.checked;
                if (!checked) {
                  setValue('locations', []);
                  clearErrors(['locations', 'locationsStatus']);
                } else {
                  setValue('locations', regionsAndCities);
                }
              }}
            />
          </div>
          <div className={s['locations-subtitle']}>
            {t(
              'Location targeting is currently limited to your country. The country indicated below will be targeted for this campaign. Leave as default if you wish to target all regions in your country. '
            )}
            <T
              _str="{pleaseNote} any removal of region will limit your campaign reach."
              pleaseNote={
                <span className={s['locations-subtitle__accented']}>{t('Please note')}:</span>
              }
            />
          </div>
          <div className={s['locations-targeted']}>{brandInfo?.country?.name}</div>

          <Controller
            control={control}
            name={'locations'}
            render={({ field: { onChange, value, ref } }) => (
              <DropdownSelect
                inputRef={ref}
                options={regionsAndCities}
                error={errors.locations}
                value={watchLocationsStatus ? value : defaultCountryValue}
                setValue={onChange}
                locationsAreActive={watch('locationsStatus')}
              />
            )}
          />
        </FormGroup>
      </div>

      <div className={s['interests']}>
        <FormGroup
          title={t('Interest Targeting (Optional)')}
          subtitle={t(
            'Select interests that apply to your audience targeting. Select all that apply or leave as none to reach wider audience.'
          )}
        >
          <div className={s['interests-switch']}>
            <Switch
              menuSwitch
              register={register}
              name="interestsStatus"
              id="interestsStatus"
              onChange={(e) => {
                const checked = e.target.checked;
                if (!checked) {
                  setValue('interests', []);
                  clearErrors(['interests', 'interestsStatus']);
                } else {
                  setValue('interests', brandInfo.fetchedInterests);
                }
              }}
            />
          </div>
          <CustomSelect
            inputId={constructTestId(SECTION, 'interests-select')}
            defaultValue={sponsorshipSetDraft.interests}
            options={brandInfo.fetchedInterests}
            isMulti={true}
            placeholder="None"
            control={control}
            name="interests"
            error={errors.interests}
            watchValue={watchInterests}
            disabled={!watch('interestsStatus')}
            selectAll
            closeOnSelect={false}
            interestsOptionsStyle
          />
        </FormGroup>
      </div>

      <div className={s['passions']}>
        <FormGroup
          title={t('Passion Targeting (Optional)')}
          subtitle={t(
            'Select passions that apply to your audience targeting. Select all that apply or leave as none to reach wider audience.'
          )}
        >
          <div className={s['passions-switch']}>
            <Switch
              menuSwitch
              register={register}
              name="passionsStatus"
              id="passionsStatus"
              onChange={(e) => {
                const checked = e.target.checked;
                if (!checked) {
                  setValue('passions', []);
                  clearErrors(['passions', 'passionsStatus']);
                } else {
                  setValue('passions', brandInfo.fetchedPassions);
                }
              }}
            />
          </div>
          <CustomSelect
            inputId={constructTestId(SECTION, 'passions-select')}
            defaultValue={sponsorshipSetDraft.passions}
            options={brandInfo.fetchedPassions}
            isMulti={true}
            placeholder="None"
            control={control}
            name="passions"
            error={errors.passions}
            watchValue={watchPassions}
            disabled={!watch('passionsStatus')}
            selectAll
            closeOnSelect={false}
            interestsOptionsStyle
          />
        </FormGroup>
      </div>

      <div className={s['os']}>
        <FormGroup
          title={t('Device OS Targeting')}
          subtitle={t(
            `Select specific Operating Systems (ie: iOS or Android) to target. Leave set to 'All' if you don’t have a preference`
          )}
        >
          <CustomSelect
            inputId={constructTestId(SECTION, 'os-targeting-select')}
            options={osData}
            // handleSelect={handleSelect('os')}
            placeholder="All"
            defaultValue={sponsorshipSetDraft.deviceType}
            control={control}
            name="deviceType"
            error={errors.os}
            watchValue={watchOs}
          />
        </FormGroup>
      </div>

      <div className={s['frequency-cap']}>
        <FormGroup
          title={t('Frequency Cap (Optional)')}
          subtitle={t(
            'Specify the maximum number of times each user can watch the same ad within the ad set. This frequency cap applies across all ads within the ad set for the entire duration of the campaign.'
          )}
        >
          <div className={s['frequency-cap-switch']}>
            <Switch
              menuSwitch
              register={register}
              name="frequencyCapStatus"
              id="frequencyCapStatus"
              disabled={adSetIsLocked}
              onChange={(e) => {
                const checked = e.target.checked;
                if (!checked) {
                  setValue('frequencyCap', '');
                  clearErrors(['frequencyCap', 'frequencyCapStatus']);
                } else {
                  setValue('frequencyCap', 1);
                }
              }}
            />
          </div>
          <TextInput
            data-testid={constructTestId(SECTION, 'frequency-cap-input')}
            type="text"
            id="frequencyCap"
            name="frequencyCap"
            label={t('Frequency Cap')}
            disabled={adSetIsLocked || !watchFrequencyCapStatus}
            onChange={(value) => {
              const onlyPositiveWholeNumbers = /^\d*[1-9]\d*$/;
              const isAllowed = onlyPositiveWholeNumbers.test(value);
              if (isAllowed) setValue('frequencyCap', value);
              else setValue('frequencyCap', value.length > 0 ? value.slice(0, -1) : value);
            }}
            watchValue={watchFrequencyCap}
            register={register}
            error={errors.frequencyCap}
          />
        </FormGroup>
      </div>
      {!isBasicAd && (
        <>
          <div className={s['custom-audiences']}>
            <SectionSubtitle className="italic">
              <T
                _str="Please note - only select {eitherText} include or exclude if using custom audience tags"
                eitherText={<strong>{t('either')}</strong>}
              />
            </SectionSubtitle>
            <FormGroup title={t('Include Custom Audiences')} customClass="audiences">
              <div className={s['custom-audiences__subtitle']}>
                <T
                  _str="Include Custom Audiences will {onlyText} target individuals that are a part of the custom audiences you select below. {noteText}: Custom audiences will appear here only if you have already created them in previous campaigns. You can create new custom audiences when you create questions for your campaign."
                  onlyText={<strong>{t('only')}</strong>}
                  noteText={<strong>{t('Note')}</strong>}
                />
              </div>
              <SuggestionsTextInput
                isMulti
                isSelect
                name="includeTags"
                onChange={(_, event) => event.action === 'clear' && fetchInitialTags('')}
                onInputChange={setTagsKeyword}
                hasMoreData={moreTagsToFetch}
                fetchMoreData={fetchMoreTags}
                data={brandInfo.fetchedTags}
                placeholder="None"
                control={control}
                watchValue={watchIncludeTags}
              />
            </FormGroup>
          </div>
          <div className={s['custom-audiences']}>
            <FormGroup title={t('Exclude Custom Audiences')}>
              <div className={s['custom-audiences__subtitle']}>
                <T
                  _str="Exclude Custom Audiences {willNotText} target individuals that are a part of the custom audiences you select below. {noteText}: Custom audiences will appear here only if you have already created them in previous campaigns. You can create new custom audiences when you create questions for your campaign."
                  willNotText={<strong>{t('will not')}</strong>}
                  noteText={<strong>{t('Note')}</strong>}
                />
              </div>
              <SuggestionsTextInput
                isMulti
                isSelect
                name="excludeTags"
                onChange={(_, event) => event.action === 'clear' && fetchInitialTags('')}
                onInputChange={setTagsKeyword}
                hasMoreData={moreTagsToFetch}
                fetchMoreData={fetchMoreTags}
                data={brandInfo.fetchedTags}
                placeholder="None"
                control={control}
                watchValue={watchExcludeTags}
              />
            </FormGroup>
          </div>
        </>
      )}
      <FeatureFlag name="WA8-2058">
        <MakeItBlurry
          text={t('Pixel Audiences Targeting is not enabled because there is no data yet')}
          active={noPreviousConversionEvents}
        >
          <div className={s['pixelAudiences']}>
            <FormGroup
              title={t('Pixel Audiences Targeting')}
              subtitle={
                <T
                  _str={`‘Took action’ are users that made a conversion in the last 365 days in any of your past campaigns. ‘Did not take action’ are users that clicked on the CTA button & visited your website in the last 365 days but did not make any conversion. More information on the {pixel} {link}.`}
                  pixel="8Pixel"
                  link={
                    <Link
                      to={routes.SETTINGS_PIXEL}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={s['pixel-doc-link']}
                    >
                      <T _str="here" />
                    </Link>
                  }
                />
              }
            >
              <div className={s['pixelAudiences-switch']}>
                <Switch
                  disabled={noPreviousConversionEvents}
                  menuSwitch
                  register={register}
                  name="eightPixelAudiencesStatus"
                  id="eightPixelAudiencesStatus"
                  onChange={(e) => {
                    const checked = e.target.checked;
                    if (!checked) {
                      setValue('eightPixelAudiences', []);
                      clearErrors(['eightPixelAudiences', 'eightPixelAudiencesStatus']);
                    } else {
                      setValue('eightPixelAudiences', [pixelAudiencesData[0].value]);
                    }
                  }}
                />
              </div>

              <div className={s['pixelAudiences__wrap']}>
                <Controller
                  control={control}
                  name="eightPixelAudiences"
                  render={({ field: { onChange, value, name, ref } }) =>
                    pixelAudiencesData.map((item) => (
                      <div className={s['pixelAudience-ceil']} key={item.value}>
                        <Checkbox
                          data-testid={constructTestId(SECTION, `pixelAudience-checkbox-${item}`)}
                          onChange={(e) => {
                            multipleCheckboxesChangeHandler(
                              name,
                              e.target.value,
                              e.target.checked,
                              value,
                              pixelAudiencesData,
                              onChange
                            );
                          }}
                          name={item.value}
                          value={item.value}
                          label={`${t(item.label)} (${
                            item.value === 'took_action'
                              ? brandInfo.fetchedPixelStats.tookActionCount
                              : brandInfo.fetchedPixelStats.didNotTakeActionCount
                          })`}
                          checked={value?.includes(item.value)}
                          inputRef={ref}
                          disabled={
                            !watch('eightPixelAudiencesStatus') || noPreviousConversionEvents
                          }
                        />
                      </div>
                    ))
                  }
                />
                {errors.eightPixelAudiences && (
                  <span className={s['pixelAudiences__error']}>
                    {errors.eightPixelAudiences.message}
                  </span>
                )}
              </div>
            </FormGroup>
          </div>
        </MakeItBlurry>
      </FeatureFlag>
    </div>
  );
};

export default NewAudience;
