import { all } from 'redux-saga/effects';
import { authActionWatcher } from './auth';
import { analyticsActionWatcher } from './analytics';
import { brandActionWatcher } from './brand';
import { createCampaignActionWatcher } from './campaign/campaign';
import { sponsorshipSetActionWatcher } from './campaign/sponsorshipSet';
import { sponsorshipActionWatcher } from './campaign/sponsorship';
import { manageActionWatcher } from './manage';
import { summaryActionWatcher } from './campaign/summary';
import { uiActionWatcher } from './ui';
import { eightAuth } from './eightauth';
import { campaignDataActionWatcher } from './data';
import { createBasicAdActionWatcher } from './basicAd/campaign/sagas';
import { basicAdSponsorshipSetActionWatcher } from './basicAd/sponsorshipSet/sagas';
import { basicAdCampaignDataActionWatcher } from './basicAdData';
import { sponsorshipBasicAdActionWatcher } from './basicAd/sponsorship';
import { manageBasicAdsActionWatcher } from './manageBasicAds';
import { basicAdSummaryActionWatcher } from './basicAd/summary';

export function* rootSaga() {
  yield all([
    analyticsActionWatcher(),
    authActionWatcher(),
    brandActionWatcher(),
    createCampaignActionWatcher(),
    sponsorshipSetActionWatcher(),
    sponsorshipActionWatcher(),
    summaryActionWatcher(),
    manageActionWatcher(),
    manageBasicAdsActionWatcher(),
    uiActionWatcher(),
    campaignDataActionWatcher(),
    createBasicAdActionWatcher(),
    basicAdSponsorshipSetActionWatcher(),
    sponsorshipBasicAdActionWatcher(),
    basicAdCampaignDataActionWatcher(),
    basicAdSummaryActionWatcher(),
    eightAuth.authActionWatcher(),
  ]);
}
