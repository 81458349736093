import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useUnmount } from 'react-use';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import { useT } from '@transifex/react';

import { ManageContent } from '../../components/PagesContent/ManageContent';
import { QuestionsModalContent } from '../../components/ModalsContent/QuestionsModalContent';
import { ApproveRejectNotificationMessage } from '../../components/NotificationsContent/RejectMessage';
import { BuyingSystemNotification } from '../../components/NotificationsContent/BuyingSystemNotification';
import { RejectionDetails } from '../../components/ModalsContent/RejectionDetails';
import { NotificationsCloseButton } from '../../components/NotificationsContent/NotificationsCloseButton';
import { Modal } from '../../components/Modal';
import { Spinner } from '../../components/Spinner';

import routes from '../../constants/routes';

import {
  activateCampaignAsync,
  activateSponsorshipAsync,
  activateSponsorshipSetAsync,
  clearReceivedNotifications,
  dismissNotificationAsync,
  deactivateCampaignAsync,
  deactivateSponsorshipAsync,
  deactivateSponsorshipSetAsync,
  fetchAllUnits,
  fetchNotificationsAsync,
  manageFetchingSelector,
  receivedCampaignsSelector,
  receivedSponsorshipSetsSelector,
  receivedSponsorshipsSelector,
  receivedNotificationsSelector,
  setDataArray,
  selectedNotificationSelector,
  isArchiveSelector,
  isSearchSelector,
  setIsSearch,
  ManageCampaignNamesSelector,
  ManageAdSetNamesSelector,
  campaignsAreFetchingSelector,
  adSetsAreFetchingSelector,
  adsAreFetchingSelector,
} from '../../store/manage';

import {
  defaultModalContentSelector,
  modalsSelector,
  setModalContent,
  setModalQuestionsContent,
  toggleModalByName,
} from '../../store/ui';

import { brandInfoSelector } from '../../store/brand';
import {
  duplicateCampaignAsync,
  archiveCampaignAsync,
  unarchiveCampaignAsync,
} from '../../store/campaign/campaign';
import { useRouter } from '../../hooks/useRouter';
import qs from 'query-string';
import { useForm, useWatch } from 'react-hook-form';
import { manageConstants } from '../../constants';

const ManagePremiumAds = () => {
  const t = useT();
  const dispatch = useDispatch();

  const campaigns = useSelector(receivedCampaignsSelector);
  const brandInfo = useSelector(brandInfoSelector);
  const fetching = useSelector(manageFetchingSelector);
  const campaignsFetching = useSelector(campaignsAreFetchingSelector);
  const adSetsFetching = useSelector(adSetsAreFetchingSelector);
  const adsFetching = useSelector(adsAreFetchingSelector);
  const notifications = useSelector(receivedNotificationsSelector);
  const sponsorshipSets = useSelector(receivedSponsorshipSetsSelector);
  const sponsorships = useSelector(receivedSponsorshipsSelector);
  const modals = useSelector(modalsSelector);
  const modalContent = useSelector(defaultModalContentSelector);
  const selectedNotification = useSelector(selectedNotificationSelector);
  const isArchive = useSelector(isArchiveSelector);
  const isSearch = useSelector(isSearchSelector);
  const campaignNames = useSelector(ManageCampaignNamesSelector);
  const adSetNames = useSelector(ManageAdSetNamesSelector);

  const { history, query } = useRouter();

  const { register, handleSubmit, setValue, control, reset, getValues } = useForm({
    defaultValues: {
      limit: {
        name: `${query.limit || 20} ${t('results')}`,
        label: `${query.limit || 20} ${t('results per page')}`,
        value: query.limit || 20,
      },
      offset: query.offset || 0,
      search: query.search || '',
      status:
        (query.archived === 'true'
          ? manageConstants.statusItems[1]
          : manageConstants.statusItems[0]) ||
        (isArchive ? manageConstants.statusItems[1] : manageConstants.statusItems[0]),
      campaigns: query.campaignId || '',
      adsets: query.adSetId || '',
      tab: query.tab ? Number(query.tab) : 0,
    },
  });

  const watchStatus = useWatch({ control, name: 'status' });
  const watchCampaigns = useWatch({ control, name: 'campaigns' });
  const watchAdSets = useWatch({ control, name: 'adsets' });
  const watchSearch = useWatch({ control, name: 'search' });
  const watchLimit = useWatch({ control, name: 'limit' });
  const watchOffset = useWatch({ control, name: 'offset' });
  const watchTab = useWatch({ control, name: 'tab' });

  const options = useMemo(() => {
    return {
      limit: watchLimit.value || watchLimit,
      offset: watchOffset,
      archived: watchStatus.value === 'archived',
      search: watchSearch,
      tab: watchTab,
      campaignId: watchCampaigns.value,
      adSetId: watchAdSets.value,
    };
  }, [
    watchLimit,
    watchOffset,
    watchStatus.value,
    watchSearch,
    watchTab,
    watchCampaigns.value,
    watchAdSets.value,
  ]);

  useEffect(() => {
    history.push({ pathname: routes.MANAGE_PREMIUM_ADS, search: qs.stringify(options) });
  }, [options, history]);

  useEffect(() => {
    if (brandInfo.id) {
      dispatch(fetchAllUnits({ ...options, initialCall: true }));
      // dispatch(fetchCampaignNamesAsync.request({ limit: 20, offset: 0, keyword: '' }));
      // dispatch(fetchSponsorshipSetNamesAsync.request({ limit: 20, offset: 0, keyword: '' }));
      dispatch(fetchNotificationsAsync.request());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandInfo.id, dispatch]);

  useUnmount(() => {
    dispatch(clearReceivedNotifications());
  });

  const handleToastClose = useCallback(
    (notificationId, campaignId) => {
      dispatch(
        dismissNotificationAsync.request({
          notificationId: notificationId,
          campaignId: campaignId,
        })
      );
    },
    [dispatch]
  );

  const handleOpenNotificationModal = useCallback(
    (notificationId) => {
      const notification = notifications.filter(
        (notification) => notification.id === notificationId
      )[0];

      dispatch(setDataArray({ name: 'selectedNotification', value: notification }));
      dispatch(toggleModalByName({ name: 'notification', value: true }));
    },
    [notifications, dispatch]
  );

  const displayNotification = useCallback(
    (notification) => {
      if (!notification.campaignId) {
        let parentClass, bodyClass;

        switch (notification.color) {
          case 'yellow':
            parentClass = 'Toastify__toast--info-attention-notification';
            bodyClass = 'Toastify__toast--info-attention-notification--body';
            break;
          case 'purple':
            parentClass = 'Toastify__toast--info-notification';
            bodyClass = 'Toastify__toast--info-notification--body';
            break;
          case 'green':
            parentClass = 'Toastify__toast--info-success-notification';
            bodyClass = 'Toastify__toast--info-success-notification--body';
            break;
          default:
            parentClass = 'Toastify__toast--info-error-notification';
            bodyClass = 'Toastify__toast--info-error-notification--body';
            break;
        }

        toast.info(<BuyingSystemNotification notification={notification} />, {
          toastId: notification.id,
          className: parentClass,
          bodyClassName: bodyClass,
          closeButton: (
            <NotificationsCloseButton
              dismiss={() => handleToastClose(notification.id, notification.campaignId)}
            />
          ),
        });
      }

      if (notification.campaignId) {
        switch (notification.color) {
          case 'purple':
            toast.error(
              <ApproveRejectNotificationMessage
                notification={notification}
                clickOnLink={() => handleOpenNotificationModal(notification.id)}
              />,
              {
                toastId: notification.id,
                className: 'Toastify__toast--error-notifications',
                bodyClassName: 'Toastify__toast--error-notifications--body',
                closeButton: (
                  <NotificationsCloseButton
                    dismiss={() => handleToastClose(notification.id, notification.campaignId)}
                  />
                ),
              }
            );
            break;
          case 'green':
            toast.success(<ApproveRejectNotificationMessage notification={notification} />, {
              toastId: notification.id,
              className: 'Toastify__toast--success-notifications',
              bodyClassName: 'Toastify__toast--success-notifications--body',
              closeButton: (
                <NotificationsCloseButton
                  dismiss={() => handleToastClose(notification.id, notification.campaignId)}
                />
              ),
            });
            break;
          case 'yellow':
            toast.info(<ApproveRejectNotificationMessage notification={notification} />, {
              toastId: notification.id,
              className: 'Toastify__toast--success-notifications',
              bodyClassName: 'Toastify__toast--success-notifications--body',
              closeButton: (
                <NotificationsCloseButton
                  dismiss={() => handleToastClose(notification.id, notification.campaignId)}
                />
              ),
            });
            break;
          default:
            break;
        }
      }
    },
    [handleToastClose, handleOpenNotificationModal]
  );

  useEffect(() => {
    if (notifications.length > 0) {
      notifications.map((notification, index) => index <= 1 && displayNotification(notification));
    }
  }, [notifications, displayNotification]);

  const handleCloseNotificationModal = useCallback(() => {
    dispatch(toggleModalByName({ name: 'notification', value: false }));
    dispatch(setDataArray({ name: 'selectedNotification', value: {} }));
  }, [dispatch]);

  const handleRejectionReasonClick = useCallback(
    (unit) => (e) => {
      const { id } = e.target;
      let content;

      switch (unit) {
        case 'campaign':
          const targetedCampaign = campaigns.data.filter((campaign) => campaign.id === id)[0];

          content = {
            reason: targetedCampaign.moderationReason,
            details: targetedCampaign.moderationDetails,
          };
          break;

        case 'adset':
          const targetedAdSet = sponsorshipSets.data.filter(
            (adset) => adset.sponsorshipSetId === id
          )[0];

          content = {
            reason: targetedAdSet.moderationReason,
            details: targetedAdSet.moderationDetails,
          };
          break;

        case 'ad':
          const targetedAd = sponsorships.data.filter((ad) => ad.sponsorshipId === id)[0];

          content = {
            reason: targetedAd.moderationReason,
            details: targetedAd.moderationDetails,
          };
          break;

        default:
          break;
      }

      dispatch(setDataArray({ name: 'selectedNotification', value: content }));
      dispatch(toggleModalByName({ name: 'notification', value: true }));
    },
    [campaigns, sponsorshipSets, sponsorships, dispatch]
  );

  const handlePerformanceClick = useCallback(
    (e) => {
      const { id } = e.target;
      const { sponsorshipId } = campaigns.data.find((campaign) => campaign.id === id);

      if (sponsorshipId) {
        dispatch(push(`${routes.REPORTING}/${sponsorshipId}${routes.REPORTING_OVERVIEW}`));
      }
    },
    [campaigns, dispatch]
  );

  const handleQuestionsClick = useCallback(
    (e) => {
      const { id } = e.target;
      const targetSponsorship = sponsorships.data.filter((ad) => ad.sponsorshipId === id)[0];

      if (targetSponsorship?.questions?.length) {
        dispatch(setModalQuestionsContent({ content: targetSponsorship.questions }));
        dispatch(toggleModalByName({ name: 'questions', value: true }));
      }
    },
    [sponsorships, dispatch]
  );

  const campaignActivationActions = useCallback(
    (id, sponsorshipSetIds, sponsorshipIds, isActive, moderationStatus, archiveNext) => {
      isActive
        ? dispatch(
            deactivateCampaignAsync.request({
              id,
              sponsorshipSetIds,
              sponsorshipIds,
              archiveNext,
            })
          )
        : dispatch(activateCampaignAsync.request({ id, sponsorshipSetIds, sponsorshipIds }));

      dispatch(toggleModalByName({ name: 'default', value: false }));
    },
    [dispatch]
  );

  const adSetActivationActions = useCallback(
    (campaignId, sponsorshipSetId, sponsorshipIds, isActive, moderationStatus) => {
      isActive
        ? dispatch(
            deactivateSponsorshipSetAsync.request({
              campaignId,
              sponsorshipSetId,
              sponsorshipIds,
            })
          )
        : dispatch(
            activateSponsorshipSetAsync.request({
              campaignId,
              sponsorshipSetId,
              sponsorshipIds,
            })
          );
      dispatch(toggleModalByName({ name: 'default', value: false }));
    },
    [dispatch]
  );

  const adActivationActions = useCallback(
    (campaignId, sponsorshipSetId, sponsorshipId, isActive, moderationStatus) => {
      isActive
        ? dispatch(
            deactivateSponsorshipAsync.request({
              campaignId,
              sponsorshipSetId,
              sponsorshipId,
            })
          )
        : dispatch(
            activateSponsorshipAsync.request({
              campaignId,
              sponsorshipSetId,
              sponsorshipId,
            })
          );
      dispatch(toggleModalByName({ name: 'default', value: false }));
    },
    [dispatch]
  );

  const handleUnitActivation = useCallback(
    (unitName, unitData, iD, archiveNext) => (e) => {
      let { id } = e.target;
      id = id || iD;
      let unit, isActive, moderationStatus, actionCallback, modalContentText, buttonText;

      if (isArchive) {
        const content = {
          title: t('Warning'),
          text: t(
            'You have to unarchive your campaign first, in order to activate it. Do you want to unarchive now?'
          ),
          buttonText: t('Yes'),
          actionCallback: () => {
            const content = {
              title: t('Warning'),
              text: t('You are about to unarchive a campaign. Are you sure?'),

              buttonText: t('Unarchive'),
              dispatchActionCallback: unarchiveCampaignAsync.request({ id, activateNext: true }),
            };
            dispatch(setModalContent({ name: 'default', content: content }));
            dispatch(toggleModalByName({ name: 'default', value: true }));
          },
        };
        dispatch(setModalContent({ name: 'default', content: content }));
        dispatch(toggleModalByName({ name: 'default', value: true }));
        return;
      }

      switch (unitName) {
        case 'campaign':
          unit = unitData.data.filter((unit) => unit.id === id)[0];
          isActive = unit.isActive;
          actionCallback = () =>
            campaignActivationActions(
              unit.id,
              unit.sponsorshipSetIds,
              unit.sponsorshipIds,
              isActive,
              moderationStatus,
              archiveNext
            );
          break;

        case 'AdSet':
          unit = unitData.data.filter((unit) => unit.sponsorshipSetId === id)[0];
          isActive = unit.isActive;

          actionCallback = () =>
            adSetActivationActions(
              unit.campaignId,
              unit.sponsorshipSetId,
              unit.sponsorshipIds,
              isActive,
              moderationStatus,
              archiveNext
            );
          break;

        case 'Ad':
          unit = unitData.data.filter((unit) => unit.sponsorshipId === id)[0];
          isActive = unit.isActive;
          actionCallback = () =>
            adActivationActions(
              unit.campaignId,
              unit.sponsorshipSetId,
              unit.sponsorshipId,
              isActive,
              moderationStatus,
              archiveNext
            );
          break;

        default:
          break;
      }

      modalContentText = t(
        `You are about to turn {isActive} the selected {unitName}. {extraMessage} Are you sure?`,
        {
          isActive: isActive ? 'off' : 'on',
          unitName,
          extraMessage:
            unitName !== 'Ad' && isActive
              ? t(`All of it's active ads will be also turned on off.`)
              : '',
        }
      );
      buttonText = isActive ? t('Turn off') : t('Turn on');

      const modalContent = {
        title: t('Warning'),
        text: modalContentText,
        buttonText,
        actionCallback,
      };

      dispatch(setModalContent({ name: 'default', content: modalContent }));
      dispatch(toggleModalByName({ name: 'default', value: true }));
    },
    [isArchive, t, dispatch, campaignActivationActions, adSetActivationActions, adActivationActions]
  );

  const handleSearch = useCallback(
    (form) => {
      const search = form?.search;
      const filteredSearchValue = encodeURIComponent(search);
      if (filteredSearchValue !== '') {
        dispatch(setIsSearch(true));
        setValue('campaigns', '');
        setValue('adsets', '');
      } else dispatch(setIsSearch(false));

      const archived = form.status?.value === 'archived';
      const campaignId = getValues('campaigns')?.value;
      const adSetId = getValues('adsets')?.value;
      const limit = form.limit.value;
      const offset = form.offset.value;

      dispatch(
        fetchAllUnits({ search: filteredSearchValue, archived, campaignId, adSetId, limit, offset })
      );
    },
    [dispatch, getValues, setValue]
  );

  const handleArchive = useCallback(
    (id) => {
      const selected = campaigns.data.filter((campaign) => campaign.id === id)[0];

      if (selected.isActive) {
        const content = {
          title: t('Warning'),
          text: t(
            'You have to deactivate your campaign first, in order to archive it. Do you want to deactivate and archive it now?'
          ),
          buttonText: t('Yes'),
          actionCallback: handleUnitActivation('campaign', campaigns, selected.id, true),
        };
        dispatch(setModalContent({ name: 'default', content: content }));
        dispatch(toggleModalByName({ name: 'default', value: true }));
      } else {
        dispatch(archiveCampaignAsync.request({ id }));
      }
    },
    [campaigns, dispatch, handleUnitActivation, t]
  );

  const handleUnarchive = useCallback(
    (id) => {
      const content = {
        title: t('Warning'),
        text: t('You are about to unarchive a campaign. Are you sure?'),

        buttonText: t('Unarchive'),
        dispatchActionCallback: unarchiveCampaignAsync.request({ id }),
      };
      dispatch(setModalContent({ name: 'default', content: content }));
      dispatch(toggleModalByName({ name: 'default', value: true }));
    },
    [dispatch, t]
  );

  return (
    <>
      {fetching && <Spinner />}
      <h1 style={{ marginTop: '1rem' }}>{t('Premium Ads')}</h1>
      <ManageContent
        campaigns={campaigns}
        brandInfo={brandInfo}
        sponsorshipSets={sponsorshipSets}
        sponsorships={sponsorships}
        handleSearch={handleSearch}
        isArchive={isArchive}
        isSearch={isSearch}
        handleArchive={handleArchive}
        handleUnarchive={handleUnarchive}
        handleCampaignActivation={handleUnitActivation('campaign', campaigns)}
        handleRejectionReasonClick={handleRejectionReasonClick}
        handlePerformanceClick={handlePerformanceClick}
        handleSponsorshipSetActivation={handleUnitActivation('AdSet', sponsorshipSets)}
        handleSponsorshipActivation={handleUnitActivation('Ad', sponsorships)}
        handleQuestionsClick={handleQuestionsClick}
        campaignNames={campaignNames}
        adSetNames={adSetNames}
        duplicateCampaign={(value) => dispatch(duplicateCampaignAsync.request(value))}
        register={register}
        setValue={setValue}
        control={control}
        reset={reset}
        getValues={getValues}
        options={options}
        handleSubmit={handleSubmit}
        watchCampaigns={watchCampaigns}
        watchAdSets={watchAdSets}
        watchStatus={watchStatus}
        watchSearch={watchSearch}
        watchLimit={watchLimit}
        campaignsFetching={campaignsFetching}
        adSetsFetching={adSetsFetching}
        adsFetching={adsFetching}
        watchTab={watchTab}
      />

      {modals.default.isOpen && (
        <Modal
          title={modalContent.title || t('Warning')}
          closeModal={() => dispatch(toggleModalByName({ name: 'default', value: false }))}
          customButtonText={modalContent.buttonText}
          actionCallback={modalContent.actionCallback}
          dispatchActionCallback={() => dispatch(modalContent.dispatchActionCallback)}
          customButtonCancelText={modalContent.cancelText}
          withSubmit={modalContent.withSubmit}
        >
          {modalContent.text}
        </Modal>
      )}
      {modals.unitOpeningWarning.isOpen && (
        <Modal
          withSubmit
          cancelCallback={() =>
            dispatch(toggleModalByName({ name: 'unitOpeningWarning', value: false }))
          }
          customButtonCancelText={t('Got it!')}
          title={t('You need to activate at least one ad to run the campaign. ')}
        ></Modal>
      )}
      {modals.questions.isOpen && (
        <Modal
          closeModal={() => dispatch(toggleModalByName({ name: 'questions', value: false }))}
          noButtons
          withCloseIcon
        >
          <QuestionsModalContent questions={modals.questions.items} />
        </Modal>
      )}
      {modals.notification.isOpen && (
        <Modal closeModal={handleCloseNotificationModal} noButtons withCloseIcon notification>
          <RejectionDetails
            reason={selectedNotification?.reason}
            details={selectedNotification?.details}
          />
        </Modal>
      )}
    </>
  );
};

export default ManagePremiumAds;
