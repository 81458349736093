import { call, put, takeLatest, select, delay, take, race, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { container } from '../../../container';
import { campaignIdSelector } from '../campaign';

import { sponsorshipSetEditIdSelector } from '../sponsorshipSet';

import {
  activateSponsorshipFromCreationAsync,
  checkUploadStatusAsync,
  deactivateSponsorshipFromCreationAsync,
  fetchUploadStatusAsync,
  resetProgress,
  setUploadingVideo,
  setInternalFetching,
  updateSponsorshipAsync,
  uploadMedia,
  uploadCoverAsync,
  uploadVideoAsync,
  uploadCoverStart,
  uploadVideoStart,
  watchUploadStop,
  setUpdatingSponsorship,
} from './actions';

import { sponsorshipCreateSelector, sponsorshipIdSelector } from './selectors';

import { toggleSavingToast, toggleUploadingToast } from '../../ui';
import { generatePath } from 'react-router';
import { routes } from '../../../constants';
import { toast } from 'react-toastify';
import {
  campaignSelector,
  setAdIsDirty,
  setUnpublishedDifferences,
  unpublishedChangesSelector,
} from '../../data';
import { postProcessQuestions } from '../../../utils/submitData/submitDataHelpers';
import UnpublishedChangesToastContent from '../../../components/UnpublishChangesToastContent/UnpublishedChangesToastContent';
import { processDifferences } from '../../../utils/receivedData';
import { t } from '@transifex/native';

const sponsorshipService = container.get('SponsorshipService');

function* updateSponsorshipSaga({ payload }) {
  const differences = yield select(unpublishedChangesSelector);
  try {
    const {
      name,
      adVideo,
      adVideoFile,
      videoLength,
      questions,
      ctaLink,
      ctaDiscount,
      thirdPartyTracking,
      ctaButtonText,
      ctaCoverFile,
      ctaCover,
      isVideoUploaded,
    } = payload.form;
    const { sponsorshipId, campaignId, sponsorshipSetId, localTags } = payload;
    let additionalData = {};

    yield put(setUpdatingSponsorship(true));
    yield put(toggleSavingToast(true));

    if (adVideoFile?.name && !isVideoUploaded) {
      additionalData = {
        ...additionalData,
        adVideoPreSignedUrlRequest: {
          originalFileName: adVideoFile?.name,
          videoLength,
        },
      };
    }

    const response = yield call(sponsorshipService.updateSponsorship, {
      sponsorshipId,
      campaignId,
      sponsorshipSetId,
      name,
      thirdPartyTrackingScripts: thirdPartyTracking,
      questionTypes: postProcessQuestions(questions),
      CTAUpdateRequest: {
        webLink: ctaLink,
        shortInstruction: '',
        discountCode: ctaDiscount,
        clickTrackingUrl: '',
        caption: ctaButtonText,
      },
      ctaCover: {
        media: ctaCoverFile,
        mediaType: ctaCoverFile.type,
        fileUrl: ctaCover,
      },
      ctaCoverFileName: ctaCoverFile.name,
      additionalData,
    });

    yield put(setUpdatingSponsorship(false));

    if (response.unpublishedDifferences) {
      const processedNewDifferences = processDifferences([response.unpublishedDifferences]);
      const filteredPrevDifferences = differences.filter((diff) => diff.id !== sponsorshipId);
      yield put(
        setUnpublishedDifferences([...filteredPrevDifferences, ...processedNewDifferences])
      );
      toast(<UnpublishedChangesToastContent />, {
        containerId: 'unpublished-changes',
        toastId: 'unpublished-toast',
        closeButton: false,
      });
    } else if (differences.length) {
      yield put(setUnpublishedDifferences(differences.filter((diff) => diff.id !== sponsorshipId)));
    }

    if (adVideoFile?.name && !isVideoUploaded) {
      yield put(
        uploadVideoStart({
          campaignId,
          sponsorshipSetId,
          sponsorshipId,
          adVideoFile,
          adVideo,
          videoLength,
        })
      );
    } else {
      yield put(
        push(
          generatePath(routes.CAMPAIGN_SUMMARY, {
            cid: campaignId,
          })
        )
      );
    }

    yield put(
      setAdIsDirty({
        isDirty: false,
        form: payload.form,
        adSetId: sponsorshipSetId,
        adId: sponsorshipId,
        dirtyFields: [],
      })
    );

    // localTags property triggers brand's store "updateSponsorshipAsync" reducer
    // its purpose is to update localTags locally whenever we save an ad
    const adToStore = {
      form: {
        ...payload.form,
        adVideo,
        videoLength,
        ctaCover: ctaCover || response.ctaImagePreSignedUrlDetail?.imageUri,
        thirdPartyTrackingScripts: thirdPartyTracking ?? [],
      },
      isDirty: false,
      sponsorshipId,
      campaignId,
      sponsorshipSetId,
      localTags,
    };

    yield put(updateSponsorshipAsync.success(adToStore));

    yield put(toggleSavingToast(false));
  } catch (error) {
    console.log(error);
    const { sponsorshipId, sponsorshipSetId } = payload;

    yield put(updateSponsorshipAsync.failure(error));
    yield put(
      setAdIsDirty({
        isDirty: true,
        form: payload.form,
        adSetId: sponsorshipSetId,
        adId: sponsorshipId,
        dirtyFields: payload.dirtyFields,
      })
    );
    yield put(setInternalFetching(false));
    yield put(toggleSavingToast(false));
  }
}

function* uploadMediaSaga({ payload }) {
  yield put(uploadVideoStart({ ...payload }));
  // yield put(uploadCoverStart());
}

function* uploadCoverSaga() {
  try {
    const { adCoverFile, adCover } = yield select(sponsorshipCreateSelector);
    const campaignId = yield select(campaignIdSelector);
    const sponsorshipSetId = yield select(sponsorshipSetEditIdSelector);
    const sponsorshipId = yield select(sponsorshipIdSelector);

    if (adCoverFile.name) {
      yield call(sponsorshipService.uploadCoverTile, {
        campaignId,
        sponsorshipSetId,
        sponsorshipId,
        media: adCoverFile,
        mediaType: adCoverFile.type,
        adCover,
      });

      yield put(uploadCoverAsync.success());
      yield put(uploadVideoStart());
    } else {
      yield put(uploadVideoStart());
    }
  } catch (error) {
    yield put(uploadCoverAsync.failure(error));
  }
}

function* uploadVideoSaga({ payload }) {
  try {
    const { adVideoFile, adVideo, videoLength, campaignId, sponsorshipSetId, sponsorshipId } =
      payload;

    if (adVideoFile.name) {
      yield put(setUploadingVideo(true));

      const { videoKey } = yield call(sponsorshipService.uploadVideo, {
        campaignId,
        sponsorshipSetId,
        sponsorshipId,
        media: adVideoFile,
        mediaType: adVideoFile.type,
        adVideo,
        videoLength,
      });

      const encodedVideoKey = encodeURIComponent(videoKey);
      if (videoKey) {
        yield put(setUploadingVideo(false));
        yield put(resetProgress(0));
        yield put(uploadVideoAsync.success({ adSetId: sponsorshipSetId, adId: sponsorshipId }));

        yield put(
          push(
            generatePath(routes.CAMPAIGN_SUMMARY, {
              cid: campaignId,
            })
          )
        );

        yield call(watchUploadStatus, encodedVideoKey);
      }
    }
  } catch (error) {
    yield put(uploadVideoAsync.failure(error));
    yield put(setUploadingVideo(false));
    yield put(resetProgress(0));
  }
}

function* checkUploadStatusSaga() {
  try {
    const { videoKey } = yield select(sponsorshipCreateSelector);
    const encodedVideoKey = encodeURIComponent(videoKey);
    yield call(watchUploadStatus, encodedVideoKey);
  } catch (error) {
    yield put(checkUploadStatusAsync.failure(error));
  }
}

function* watchUploadStatus(videoKey) {
  let videoStatus = '';

  yield put(toggleUploadingToast(true));

  while (videoStatus !== 'complete') {
    yield put(fetchUploadStatusAsync.request({ videoKey }));

    const { success } = yield race({
      success: take(fetchUploadStatusAsync.success.type),
      failure: take(fetchUploadStatusAsync.failure.type),
    });

    if (success) {
      videoStatus = success.payload.status;
    }

    yield delay(1000);
  }
}

function* fetchUploadStatusSaga({ payload }) {
  try {
    const { status } = yield call(sponsorshipService.getVodInfoStatus, payload.videoKey);

    if (status === 'ingest') {
      yield put(toggleUploadingToast(true));
      yield put(fetchUploadStatusAsync.success({ status: 'processing' }));
    }

    if (status === 'complete') {
      yield put(fetchUploadStatusAsync.success({ status }));
      yield put(watchUploadStop({ status }));
      yield put(toggleUploadingToast(false));
      toast.success('Video processing completed successfully!', {
        containerId: 'upload-success',
      });
    }
  } catch (error) {
    if (error.response?.status === 404) {
      yield put(watchUploadStop({ status: 'processing' }));
      yield put(fetchUploadStatusAsync.failure(error));
    } else {
      yield put(fetchUploadStatusAsync.failure(error));
      yield put(watchUploadStop({ status: 'failed' }));
      toast.error(t('Video processing failed!'), {
        containerId: 'upload-success',
      });
    }
  }
}

function* activateSponsorshipSaga({ payload }) {
  try {
    const campaign = yield select(campaignSelector);
    const campaignId = campaign.id;
    const sponsorshipSetId = payload.adSetId;
    const sponsorshipId = payload.adId;

    yield call(sponsorshipService.activateSponsorship, {
      campaignId,
      sponsorshipSetId,
      sponsorshipId,
    });

    yield put(
      activateSponsorshipFromCreationAsync.success({
        adSetId: sponsorshipSetId,
        adId: sponsorshipId,
      })
    );
  } catch (error) {
    yield put(activateSponsorshipFromCreationAsync.failure(error));
  }
}

function* deactivateSponsorshipSaga({ payload }) {
  try {
    const campaign = yield select(campaignSelector);
    const campaignId = campaign.id;
    const sponsorshipSetId = payload.adSetId;
    const sponsorshipId = payload.adId;

    yield call(sponsorshipService.deactivateSponsorship, {
      campaignId,
      sponsorshipSetId,
      sponsorshipId,
    });

    yield put(
      deactivateSponsorshipFromCreationAsync.success({
        adSetId: sponsorshipSetId,
        adId: sponsorshipId,
      })
    );
  } catch (error) {
    yield put(activateSponsorshipFromCreationAsync.failure(error));
  }
}

export function* sponsorshipActionWatcher() {
  yield takeLatest(activateSponsorshipFromCreationAsync.request.type, activateSponsorshipSaga);
  yield takeLatest(checkUploadStatusAsync.request.type, checkUploadStatusSaga);
  yield takeLatest(deactivateSponsorshipFromCreationAsync.request.type, deactivateSponsorshipSaga);
  yield takeLatest(fetchUploadStatusAsync.request.type, fetchUploadStatusSaga);
  yield takeLatest(updateSponsorshipAsync.request.type, updateSponsorshipSaga);
  yield takeLatest(uploadCoverStart, uploadCoverSaga);
  yield takeEvery(uploadVideoStart, uploadVideoSaga);
  yield takeLatest(uploadMedia, uploadMediaSaga);
}
