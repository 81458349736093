import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { routes } from '../../constants';
import { setActiveCampaign } from '../../store/campaign/campaign';
import { setActiveAd } from '../../store/campaign/sponsorship';
import { setActiveAdSet } from '../../store/campaign/sponsorshipSet';
import { campaignDataFetchingSelector, campaignSelector } from '../../store/data';
import { useRouter } from '../useRouter';
import useIdentifyCampaignType from '../useIdentifyCampaignType/useIdentifyCampaignType';

function useActiveForm({ type, campaignId, adSetId, adId, allAdSets }) {
  const campaign = useSelector(campaignSelector);
  const dataFetching = useSelector(campaignDataFetchingSelector);
  const { isBasicAd } = useIdentifyCampaignType();

  const { push } = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!dataFetching) {
      if (type === 'campaign') dispatch(setActiveCampaign(campaign));
      else if (type === 'adSet') {
        let selectedAdSet = allAdSets.find((adSet) => adSet.id === adSetId);
        // in a case that we got a wrong ssid, automatically change to an existing one [create or edit]
        if (!selectedAdSet && adSetId) {
          if (isBasicAd)
            return push(generatePath(routes.BASIC_AD.CAMPAIGN_EDIT, { cid: campaignId }));

          push(generatePath(routes.CAMPAIGN_EDIT, { cid: campaignId }));
          return;
        }
        dispatch(setActiveAdSet(selectedAdSet));
      } else if (type === 'ad') {
        // in a case that we got a wrong ssid, automatically change to an existing one [create or edit]
        const selectedAdSet = allAdSets.find((adSet) => adSet.id === adSetId);
        const selectedAd = selectedAdSet?.ads.find((ad) => ad.id === adId);

        if (!selectedAdSet && adSetId) {
          if (isBasicAd)
            return push(
              generatePath(routes.BASIC_AD.CAMPAIGN_EDIT, {
                cid: campaignId,
              })
            );

          push(
            generatePath(routes.CAMPAIGN_EDIT, {
              cid: campaignId,
            })
          );
          return;
        } else if (!selectedAd && adId) {
          if (isBasicAd)
            return push(
              generatePath(routes.BASIC_AD.SPONSORSHIP_SET_EDIT, {
                cid: campaignId,
                ssid: selectedAdSet.id,
              })
            );

          push(
            generatePath(routes.SPONSORSHIP_SET_EDIT, {
              cid: campaignId,
              ssid: selectedAdSet.id,
            })
          );
          return;
        }

        selectedAd && dispatch(setActiveAd(selectedAd));
      }
    }
  }, [
    adId,
    adSetId,
    allAdSets,
    campaign,
    dispatch,
    type,
    campaignId,
    push,
    dataFetching,
    isBasicAd,
  ]);
}

export default useActiveForm;
