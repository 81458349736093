import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useT } from '@transifex/react';
import { StickyMenu } from '../../components/wrappers/StickyMenu';
import { Title } from '../../components/common/Titles/Title';
import { SummaryMenu } from '../../components/Menus/SummaryMenu';
import { MobileMenu } from '../../components/wrappers/MobileMenu';

import { campaignCreateSelector, campaignIdSelector } from '../../store/campaign/campaign';

import {
  sponsorshipSetCreateSelector,
  sponsorshipSetEditIdSelector,
} from '../../store/campaign/sponsorshipSet';

import {
  sponsorshipCreateSelector,
  sponsorshipEditIdSelector,
  updateSponsorshipSelector,
} from '../../store/campaign/sponsorship';

import { stickyMenuItemSelector } from '../../store/ui';
import { NewCampaignMenu } from '../../components/Menus/NewCampaignMenu';
import { NewSponsorshipSetMenu } from '../../components/Menus/NewSponsorshipSetMenu';
import {
  adSetsSelector,
  campaignDataFetchingSelector,
  campaignSelector,
  summaryAdSetsSelector,
  unpublishedChangesSelector,
} from '../../store/data';
import { MenuPlaceholder } from '../../components/Placeholders/MenuPlaceholder';
import { basicAdCampaignDataFetchingSelector } from '../../store/basicAdData';
import useIdentifyCampaignType from '../../hooks/useIdentifyCampaignType/useIdentifyCampaignType';

const NewCombinedCampaignMenu = () => {
  const t = useT();

  const campaignEditId = useSelector(campaignIdSelector);
  const sponsorshipSetId = useSelector(sponsorshipSetEditIdSelector);
  const sponsorshipEditId = useSelector(sponsorshipEditIdSelector);
  const sponsorshipSet = useSelector(sponsorshipSetCreateSelector);
  const sponsorship = useSelector(sponsorshipCreateSelector);
  const stickyMenuItem = useSelector(stickyMenuItemSelector);
  const adSets = useSelector(adSetsSelector);
  const summaryAdSets = useSelector(summaryAdSetsSelector);
  const campaign = useSelector(campaignSelector);
  const activeCampaign = useSelector(campaignCreateSelector);
  const activeSponsorshipSet = useSelector(sponsorshipSetCreateSelector);
  const fetching = useSelector(campaignDataFetchingSelector);
  const basicAdfetching = useSelector(basicAdCampaignDataFetchingSelector);
  const { isBasicAd } = useIdentifyCampaignType();
  const preUploadLoading = useSelector(updateSponsorshipSelector);
  const unpublishedChanges = useSelector(unpublishedChangesSelector);

  const currentCampaign = useMemo(() => {
    return {
      campaignEditId,
      sponsorshipSetId,
      sponsorshipEditId,
    };
  }, [campaignEditId, sponsorshipSetId, sponsorshipEditId]);

  const [width, setWidth] = useState(window.innerWidth);

  // Checking if sponsorship set required fields are filled out
  // const sponsorshipSetRequiredFields = useMemo(
  //   () =>
  //     sponsorshipSet.isUnite
  //       ? sponsorshipSet.startDate
  //       : sponsorshipSet.budget &&
  //         sponsorshipSet.startDate &&
  //         Object.keys(sponsorshipSet.selectedCharity) !== 0,
  //   [
  //     sponsorshipSet.budget,
  //     sponsorshipSet.startDate,
  //     sponsorshipSet.selectedCharity,
  //     sponsorshipSet.isUnite,
  //   ]
  // );

  // Checking if sponsorship required fields are filled out
  // const sponsorshipRequiredFields = useMemo(
  //   () =>
  //     sponsorshipSet.isUnite
  //       ? sponsorship.ctaCover &&
  //         sponsorship.adVideo &&
  //         sponsorship.adCover &&
  //         sponsorship.receivedQuestions
  //       : sponsorship.ctaCover && sponsorship.adVideo && sponsorship.receivedQuestions,
  //   [
  //     sponsorship.ctaCover,
  //     sponsorship.adVideo,
  //     sponsorshipSet.isUnite,
  //     sponsorship.adCover,
  //     sponsorship.receivedQuestions,
  //   ]
  // );

  const handleWindowWidth = useCallback(() => {
    setWidth(window.innerWidth);
  }, [setWidth]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowWidth);

    return () => window.removeEventListener('resize', handleWindowWidth);
  }, [handleWindowWidth]);

  const campaignMode = useMemo(
    () => (!!currentCampaign.campaignEditId ? 'edit' : 'create'),
    [currentCampaign]
  );

  // const sponsorshipSetMode = useMemo(() => {
  //   if (campaignMode === 'edit' && !currentCampaign.sponsorshipSetId) {
  //     return 'create';
  //   }

  //   if (campaignMode === 'edit' && !!currentCampaign.sponsorshipSetId) {
  //     return 'edit';
  //   }

  //   return 'disabled';
  // }, [currentCampaign, campaignMode]);

  // const sponsorshipMode = useMemo(() => {
  //   if (
  //     sponsorshipSetMode === 'edit' &&
  //     !currentCampaign.sponsorshipEditId &&
  //     sponsorshipSetRequiredFields
  //   ) {
  //     return 'create';
  //   }

  //   if (sponsorshipSetMode === 'edit' && !!currentCampaign.sponsorshipEditId) {
  //     return 'edit';
  //   }

  //   return 'disabled';
  // }, [currentCampaign, sponsorshipSetMode, sponsorshipSetRequiredFields]);

  // const summaryMode = useMemo(() => {
  //   if (
  //     sponsorshipMode === 'edit' &&
  //     !!currentCampaign.sponsorshipEditId &&
  //     sponsorshipRequiredFields
  //   ) {
  //     return 'edit';
  //   }

  //   return 'disabled';
  // }, [currentCampaign, sponsorshipMode, sponsorshipRequiredFields]);

  const isDataFetching = useMemo(
    () => (isBasicAd ? basicAdfetching : fetching),
    [basicAdfetching, fetching, isBasicAd]
  );

  return (
    <>
      <StickyMenu>
        {isDataFetching || preUploadLoading ? (
          <MenuPlaceholder />
        ) : (
          <>
            <Title>{t('Campaign Setup')}</Title>
            <NewCampaignMenu
              mode={campaignMode}
              campaignId={campaign.id}
              stickyMenuItem={stickyMenuItem}
              activeCampaign={activeCampaign}
              adSets={adSets}
              isArchived={campaign.isArchive}
            />
            {adSets.map((adSet, index) => (
              <NewSponsorshipSetMenu
                adSet={adSet}
                campaignId={campaign.id}
                key={index}
                activeSponsorshipSet={activeSponsorshipSet}
                sponsorship={sponsorship}
                sponsorshipEditId={currentCampaign?.sponsorshipEditId}
                sponsorshipSetId={currentCampaign?.sponsorshipSetId}
                sponsorshipSet={sponsorshipSet}
                stickyMenuItem={stickyMenuItem}
                isArchived={campaign.isArchive}
                unitIsApproved={
                  adSet.moderationStatus.toLowerCase() === 'approved' || !adSet.isDraft
                }
              />
            ))}
            <SummaryMenu
              campaignId={campaign.id}
              isEdit={!campaign.isDraft}
              summaryAdSets={summaryAdSets}
              unpublishedChanges={unpublishedChanges}
            />
          </>
        )}
      </StickyMenu>

      {width <= 768 && (
        <MobileMenu>
          <NewCampaignMenu
            mode={campaignMode}
            campaignId={campaign.id}
            stickyMenuItem={stickyMenuItem}
            activeCampaign={activeCampaign}
            adSets={adSets}
            isArchived={campaign.isArchived}
          />
          {adSets.map((adSet, index) => (
            <NewSponsorshipSetMenu
              adSet={adSet}
              campaignId={campaign.id}
              key={index}
              activeSponsorshipSet={activeSponsorshipSet}
              sponsorship={sponsorship}
              sponsorshipEditId={currentCampaign?.sponsorshipEditId}
              sponsorshipSetId={currentCampaign?.sponsorshipSetId}
              sponsorshipSet={sponsorshipSet}
              stickyMenuItem={stickyMenuItem}
              isArchived={campaign.isArchived}
            />
          ))}
          <SummaryMenu
            campaignId={campaign.id}
            isEdit={!campaign.isDraft}
            summaryAdSets={summaryAdSets}
            unpublishedChanges={unpublishedChanges}
          />
        </MobileMenu>
      )}
    </>
  );
};

export default NewCombinedCampaignMenu;
