import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';
import { SmallLoader } from '../../../components/SmallLoader';
import { uploadingToastSelector } from '../../../store/ui';
import { useT } from '@transifex/react';
import useIdentifyCampaignType from '../../../hooks/useIdentifyCampaignType/useIdentifyCampaignType';

const Msg = () => {
  const t = useT();
  const { isBasicAd } = useIdentifyCampaignType();
  return (
    <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
      <SmallLoader />
      <span>
        {isBasicAd ? t('Your videos/images are processing...') : t('Your videos are processing...')}
      </span>
    </div>
  );
};

const WithUploadingToastContainer = ({ children }) => {
  const uploadingToast = useSelector(uploadingToastSelector);
  useEffect(() => {
    if (uploadingToast.isOpen) {
      toast.info(<Msg />);
    }
  }, [uploadingToast.isOpen]);
  return (
    <>
      {children}
      {uploadingToast.isOpen && (
        <ToastContainer
          position="top-center"
          autoClose={false}
          closeButton={false}
          draggable={false}
          closeOnClick={false}
          limit={1}
          containerId="with-uploading"
        />
      )}
    </>
  );
};

export default WithUploadingToastContainer;
