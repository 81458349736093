import { createAction, createAsyncAction } from 'eight.js.store-common';

export const clearBasicAdState = createAction('@@basicAdData/CLEAR_STATE');
export const createNewBasicAdAdSet = createAction('@@basicAdData/CREATE_NEW_AD_SET');
export const duplicateBasicAdAdSet = createAction('@@basicAdData/DUPLICATE_AD_SET');
export const deleteBasicAdAdSet = createAction('@@basicAdData/DELETE_AD_SET');
export const createNewBasicAdAd = createAction('@@basicAdData/CREATE_NEW_AD');
export const duplicateBasicAdAd = createAction('@@basicAdData/DUPLICATE_AD');
export const deleteBasicAdAd = createAction('@@basicAdData/DELETE_AD');
export const basicAdCampaignDataFetching = createAction('@@basicAdData/CAMPAIGN_DATA_FETCHING');
export const setBasicAdFetchedNameAndLogo = createAction('@@campaign/SET_FETCHED_NAME_AND_LOGO');
export const setBasicAdCampaignIsDirty = createAction('@@basicAdData/SET_CAMPAIGN_IS_DIRTY');
export const setBasicAdAdSetIsDirty = createAction('@@basicAdData/SET_AD_SET_IS_DIRTY');
export const setBasicAdAdIsDirty = createAction('@@basicAdData/SET_AD_IS_DIRTY');
export const setUnpublishedBasicAdDifferences = createAction(
  '@@basicAdData/SET_UNPUBLISHED_DIFFERENCES'
);
export const setBasicAdCampaignIsPublished = createAction(
  '@@basicAdData/SET_CAMPAIGN_IS_PUBLISHED'
);
export const fetchBasicAdCampaignAsync = createAsyncAction('@@basicAdData/FETCH_CAMPAIGN');
export const fetchBasicAdCampaignDifferencesAsync = createAsyncAction(
  '@@basicAdData/FETCH_CAMPAIGN_DIFFERENCES'
);
