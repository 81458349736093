import { createSelector } from 'reselect';

const basicAdSponsorshipStateSelector = (state) => state.basicAdSponsorship;

export const basicAdSponsorshipCreateSelector = createSelector(
  basicAdSponsorshipStateSelector,
  (state) => state.sponsorshipCreate
);

export const basicAdSponsorshipIsActiveSelector = createSelector(
  basicAdSponsorshipCreateSelector,
  (state) => state.isActive
);

export const basicAdSponsorshipUploadStatusSelector = createSelector(
  basicAdSponsorshipCreateSelector,
  (state) => state.videoUploadStatus
);

export const basicAdSponsorshipIdSelector = createSelector(
  basicAdSponsorshipCreateSelector,
  (state) => state.id
);

export const basicAdInternalFetching = createSelector(
  basicAdSponsorshipCreateSelector,
  (state) => state.internalFetching
);

export const basicAdUpdateSponsorshipSelector = createSelector(
  basicAdSponsorshipStateSelector,
  (state) => state.updatingSponsorship
);

export const basicAdSponsorshipEditSelector = createSelector(
  basicAdSponsorshipStateSelector,
  (state) => state.sponsorshipEdit
);

export const basicAdSponsorshipEditIdSelector = createSelector(
  basicAdSponsorshipEditSelector,
  (state) => state.id
);

export const basicAdSponsorshipFetchingSelector = createSelector(
  basicAdSponsorshipStateSelector,
  (state) => state.fetching
);
