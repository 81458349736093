import React, { useMemo } from 'react';
import { generatePath } from 'react-router';

import { DisabledNavLink } from '../../common/DisabledNavLink';

import { useT } from '@transifex/react';
import { useSelector } from 'react-redux';
import basicCampaignMenu from '../../../constants/basic-campaign-menu';
import { basicAdCampaignSelector, createNewBasicAdAdSet } from '../../../store/basicAdData';
import { createNewAdSet } from '../../../store/data';
import { MenuOptions } from '../../common/MenuOptions';
import { SvgIcon } from '../../common/SvgIcon';
import s from './BasicCampaignMenuSection.module.scss';

const BasicCampaignMenuSection = ({
  campaignId,
  mode,
  stickyMenuItem,
  activeCampaign,
  adSets,
  isArchived,
}) => {
  const campaign = useSelector(basicAdCampaignSelector);
  const createNav = basicCampaignMenu.menuItems.find((menuItem) => menuItem.name === 'Campaign');
  const isCreated = useMemo(() => campaign.isCreated, [campaign.isCreated]);
  const isDisabled = useMemo(
    () => isArchived || campaign.deliveryStatus !== 'draft',
    [campaign.deliveryStatus, isArchived]
  );
  const t = useT();
  const campaignName = useMemo(() => {
    return activeCampaign.id === campaign.id ? activeCampaign.name : campaign.name;
  }, [activeCampaign.id, campaign.id, campaign.name, activeCampaign.name]);

  return (
    <div className={s['campaign-menu']}>
      <div className={s['campaign-menu__item']}>
        <DisabledNavLink
          isOpen
          isDisabled={isDisabled}
          to={isCreated ? generatePath(createNav.editPath, { cid: campaignId }) : createNav.path}
        >
          {campaign.isDirty && <SvgIcon name="important" />}
          <span>{campaignName || 'New Campaign'}</span>
        </DisabledNavLink>

        <MenuOptions
          name="Campaign"
          createFunction={createNewBasicAdAdSet}
          type="campaign"
          campaign={campaign}
          campaignId={campaignId}
          isDisabled={isDisabled}
          isOpen
        />
      </div>
      <ul className={s['campaign-submenu']}>
        {createNav.submenuItems.map((submenuItem) => (
          <li key={submenuItem.fragment} className={s['campaign-submenu__item']}>
            <DisabledNavLink
              to={
                isCreated
                  ? generatePath(submenuItem.editPath, { cid: campaignId })
                  : submenuItem.path
              }
              fragment={submenuItem.fragment}
              stickyMenuItem={stickyMenuItem}
              isOpen
              isDisabled={isDisabled}
            >
              {t(submenuItem.name)}
            </DisabledNavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BasicCampaignMenuSection;
