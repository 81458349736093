import { useT } from '@transifex/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { basicAdsPricingSelector, brandInfoSelector } from '../../../../../../store/brand';
import { FormGroup } from '../../../../../FormElements/FormGroup';
import s from './BasicCpm.module.scss';

const BasicCpm = () => {
  const t = useT();
  const prices = useSelector(basicAdsPricingSelector);
  const currencySymbol = useSelector(brandInfoSelector).country.currencySymbol;
  return (
    <FormGroup title={t('Pricing')}>
      <table className={s['table']}>
        <thead>
          <tr>
            <th>Type</th>
            <th>CPM</th>
            <th>User Reward</th>
            <th>Charity</th>
            <th>Climate</th>
            <th>WeAre8 Fee</th>
          </tr>
        </thead>
        <tbody>
          {prices?.map((price, index) => (
            <tr key={index}>
              <td>{price.mediaType}</td>
              <td>
                {currencySymbol}
                {price.cpm}
              </td>
              <td>
                {currencySymbol}
                {price.userReward}
              </td>
              <td>
                {currencySymbol}
                {price.charity}
              </td>
              <td>
                {currencySymbol}
                {price.climate}
              </td>
              <td>
                {currencySymbol}
                {price.weAre8Fee}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </FormGroup>
  );
};

export default BasicCpm;
