import React from 'react';
import useIdentifyCampaignType from '../../../../../hooks/useIdentifyCampaignType/useIdentifyCampaignType';
import ImageMedia from '../ImageMedia/ImageMedia';
import { VideoMedia } from '../VideoMedia';

const Media = ({
  creativeRef,
  handleChangeVideoAd,
  sponsorshipCreate,
  control,
  errors,
  register,
  adIsLocked,
  mediaType,
  setValue,
}) => {
  const { isBasicAd } = useIdentifyCampaignType();

  if (!isBasicAd || mediaType === 'video')
    return (
      <VideoMedia
        creativeRef={creativeRef}
        handleChangeVideoAd={handleChangeVideoAd}
        sponsorshipCreate={sponsorshipCreate}
        control={control}
        errors={errors}
        register={register}
        adIsLocked={adIsLocked}
      />
    );

  return (
    <ImageMedia
      register={register}
      handleChangeImageAd={handleChangeVideoAd}
      creativeRef={creativeRef}
      sponsorshipCreate={sponsorshipCreate}
      control={control}
      errors={errors}
      setValue={setValue}
    />
  );
};

export default Media;
