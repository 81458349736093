import * as yup from 'yup';

export const LETTERS_REGEXP = /^([^0-9]*)$/;
export const NUMBER_REGEXP = /^\d*(\.\d*)?$/;
export const CARD_NUMBERS_REGEX = /^(\d{4}\s)(\d{4}\s)(\d{4}\s)\d{4}$/gi;
export const CURRENCY_WITH_CENTS = /^[0-9]+(\.[0-9]{1,2})?$/gm;
export const DATE_REGEXP = /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/;
export const DIGITS_AND_COMAS = /^[-+]?(?:[0-9]+,)*[0-9]+(?:\.[0-9]+)?$/;
export const DIGITS_AND_COMAS_AND_EMPTY = /^$|[-+]?(?:[0-9]+,)*[0-9]+(?:\.[0-9]+)?$/;
export const EMAIL_REGEXP =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
export const PASSWORD_REGEXP =
  /(?=^.{6,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)[0-9a-zA-Z!@#$%^&*()]*$/gm;
export const EMOJIS_REGEXP = /<a?:.+?:\d{18}>|\p{Extended_Pictographic}/gu;
// eslint-disable-next-line no-control-regex
export const UNICODE_REGEXP = /[^\u0000-\u007F]+/gm;

export const summaryCampaignValidation = (campaign) => {
  const schema = {
    'Campaign Name': (value) => value.length >= 3,
    'Brand Name': (value) => value.length >= 3,
    'Brand Logo': (value) => value.length > 2,
  };

  const info = {
    'Campaign Name': campaign?.name,
    'Brand Name': campaign?.campaignBrandName,
    'Brand Logo': campaign?.campaignBrandLogoUri,
  };

  const validate = (ad, schema) =>
    Object.keys(schema)
      .filter((key) => !schema[key](ad[key]))
      .map((key) => ({
        errorName: `${key} is missing`,
      }));

  const errors = validate(info, schema);

  return {
    campaignIsValid: !errors.length,
    campaignErrors: errors,
  };
};

export const summarySponsorshipSetValidation = (adSet) => {
  // FIX ME!
  const schema = {
    'AdSet Name': (value) => value?.length > 2,
    'Start Date': (value) => value,
    Budget: (value) => value > 0,
    Charity: (value) => value?.length > 2,
  };

  const info = {
    'AdSet Name': adSet.name,
    'Start Date': adSet.startDate,
    Charity: adSet.selectedCharity.name,
    Budget: adSet.budget,
  };

  const validate = (ad, schema) =>
    Object.keys(schema)
      .filter((key) => !schema[key](ad[key]))
      .map((key) => ({
        errorName: `${key} is invalid or missing`,
      }));

  const errors = validate(info, schema);

  return {
    adSetIsValid: !errors.length,
    adSetErrors: errors,
  };
};

export const summaryUniteSponsorshipSetValidation = (adSet) => {
  const schema = {
    'AdSet Name': (value) => value?.length > 2,
    'Start Date': (value) => value?.length > 2,
  };

  const info = {
    'AdSet Name': adSet.name,
    'Start Date': adSet.startDate,
  };

  const validate = (ad, schema) =>
    Object.keys(schema)
      .filter((key) => !schema[key](ad[key]))
      .map((key) => ({
        errorName: `${key} is missing`,
      }));

  const errors = validate(info, schema);

  return {
    adSetIsValid: !errors.length,
    adSetErrors: errors,
  };
};

export const summarySponsorshipValidation = (ad) => {
  const schema = {
    'Ad Name': (value) => value?.length > 2,
    // 'Message Cover Tile': value => value?.length > 2,
    'Video Message': (value) => value?.length > 2,
    // 'SMS Message': value => value?.length > 2,
    Questions: (value) => !!value,
    'Call To Action Image': (value) => value?.length > 2,
  };

  const info = {
    'Ad Name': ad.name,
    // 'Message Cover Tile': ad.adCover,
    'Video Message': ad.adVideo,
    // 'SMS Message': ad.smsMessage,
    Questions: ad.receivedQuestions,
    'Call To Action Image': ad.ctaCover,
  };

  const validate = (ad, schema) =>
    Object.keys(schema)
      .filter((key) => !schema[key](ad[key]))
      .map((key) => ({
        errorName: `${key} is missing`,
      }));

  const errors = validate(info, schema);

  return {
    adIsValid: !errors.length,
    adErrors: errors,
  };
};

export const isSecondDateMoreThanFirst = (initialDate, comparedDate) => {
  const [startYear, startMonth, startDay] = initialDate.split('-');
  const [endYear, endMonth, endDay] = comparedDate.split('-');

  if (comparedDate) {
    if (+endYear < +startYear) {
      return false;
    }

    if (+endYear > +startYear && +endDay < +startDay) {
      return true;
    }

    if (+endYear === +startYear && +endMonth < +startMonth) {
      return false;
    }

    if (+endYear === +startYear && +endMonth > +startMonth) {
      return true;
    }

    return +endDay >= +startDay;
  } else {
    return true;
  }
};

export const isDateFormat = (value) => (value ? value.match(DATE_REGEXP) : true);

export const isValidDoubleClickTrackingScript = (value) => {
  const formattedValue = value.toLowerCase().trim();

  //check if it starts and ends with script tags
  const validScriptStart = formattedValue.startsWith(
    `<script language='javascript1.1' src="https://ad.doubleclick.net/ddm/trackimpj/`
  );
  const validScriptEnd = formattedValue.endsWith('</script>');
  if (!validScriptStart || !validScriptEnd) {
    return false;
  }

  // check if src contains specific parameters
  const includesDc_trc_aid = formattedValue.includes('dc_trk_aid=');
  if (!includesDc_trc_aid) return false;

  const includesDc_trc_cid = formattedValue.includes('dc_trk_cid=');
  if (!includesDc_trc_cid) return false;

  const includesDc_timestamp = formattedValue.includes('ord=[timestamp];');
  if (!includesDc_timestamp) return false;

  const includesDc_lat = formattedValue.includes('dc_lat=');
  if (!includesDc_lat) return false;

  const includesDc_rdid = formattedValue.includes('dc_rdid=');
  if (!includesDc_rdid) return false;

  const includesTag_for_child_directed_treatment = formattedValue.includes(
    'tag_for_child_directed_treatment='
  );
  if (!includesTag_for_child_directed_treatment) return false;

  return true;
};

export const isValidAdFormCtaTracking = (value) => {
  const [baseUrl, ...rest] = value.split(';');
  const template = rest.join(';');
  const isValidUrl = yup.string().url().isValidSync(baseUrl);
  const isValidTemplate = template === 'gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_50}';
  return isValidUrl && isValidTemplate;
};

const extractImgTag = (value) => {
  const imgTagPattern = /^<img\s+[^>]*src\s*=\s*["'][^"']+["'][^>]*>$/i;
  if (!imgTagPattern.test(value)) {
    return null;
  }
  const parser = new DOMParser();
  const doc = parser.parseFromString(value, 'text/html');
  const imgElement = doc.querySelector('img');

  return imgElement;
};

export const isValidDoubleVerifyTrackingScript = (value) => {
  const imgElement = extractImgTag(value);
  if (!imgElement) return false;
  const [httpPrefix, baseUrl] = imgElement.src.split('://');
  return baseUrl.startsWith('tps.doubleverify.com/');
};

export const isValidImageTag = (value) => {
  const imgElement = extractImgTag(value);
  return imgElement && imgElement.src;
};
